import React from "react";

const PresetsIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clip-path="url(#clip0_175_128985)">
        <path
          d="M18.3334 3H1.66675L8.33341 10.8833V16.3333L11.6667 18V10.8833L18.3334 3Z"
          stroke="currentColor"
          stroke-width="1.45833"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_175_128985">
          <rect
            width="20"
            height="20"
            fill="currentColor"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PresetsIcon;
