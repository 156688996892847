import React from "react";

const RecentActivityIcon = ({
  color,
  className,
}: {
  color: string;
  className?: string;
}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clip-path="url(#clip0_4043_4253)">
        <g clip-path="url(#clip1_4043_4253)">
          <path
            d="M9.99984 18.3333C14.6022 18.3333 18.3332 14.6023 18.3332 9.99996C18.3332 5.39759 14.6022 1.66663 9.99984 1.66663C5.39746 1.66663 1.6665 5.39759 1.6665 9.99996C1.6665 14.6023 5.39746 18.3333 9.99984 18.3333Z"
            stroke="currentColor"
            stroke-width="1.45833"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10 5V10L13.3333 11.6667"
            stroke="currentColor"
            stroke-width="1.45833"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4043_4253">
          <rect width="20" height="20" fill="currentColor" />
        </clipPath>
        <clipPath id="clip1_4043_4253">
          <rect width="20" height="20" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RecentActivityIcon;
