"use client";

import { Button } from "@components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipPortal,
  TooltipArrow,
} from "@ui";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { cn } from "@/lib/utils";

interface TooltipStep {
  selector: string;
  title: string;
  description: string;
  step: number;
  buttonText: "Continue" | "Back" | "Finish";
  position: {
    tooltipSide: "left" | "right";
    triggerPosition: string;
  };
  forwardNavigation?: string;
  backNavigation?: string;
}

const TOOLTIP_STEPS: TooltipStep[] = [
  {
    selector: "#sidebar-presets",
    title: "Preset overview",
    description:
      "You can find all your presets under the Presets tab in your store settings.",
    step: 1,
    buttonText: "Continue",
    position: {
      tooltipSide: "right",
      triggerPosition: "absolute -right-2 top-3",
    },
  },
  {
    selector: ".tracify-preset",
    title: "Tracify presets",
    description:
      "Presets created and recommended by Tracify are highlighted by this badge.",
    step: 2,
    buttonText: "Continue",
    position: {
      tooltipSide: "right",
      triggerPosition: "absolute -right-7 top-0.5",
    },
  },
  {
    selector: ".create-new-preset-button",
    title: "Create a new preset",
    description:
      "You can create new presets to set up custom attribution settings for your account",
    step: 3,
    buttonText: "Continue",
    position: {
      tooltipSide: "left",
      triggerPosition: "absolute -left-3 top-3",
    },
    forwardNavigation: "/",
  },
  {
    selector: "#attribution-preset-select",
    title: "Preset Selection",
    description: "This is where you can choose a preset for your data.",
    step: 4,
    buttonText: "Finish",
    position: {
      tooltipSide: "left",
      triggerPosition: "absolute -left-4 top-3",
    },
    backNavigation: "/settings/presets",
  },
];

export function TracifyOnboardingTooltips() {
  const router = useRouter();
  const [currentStep, setCurrentStep] = useState(0);
  const [currentElement, setCurrentElement] = useState<HTMLElement | null>(
    null
  );
  const [isOpen, setIsOpen] = useState(true);
  const [isElementReady, setIsElementReady] = useState(false);

  useEffect(() => {
    const step = TOOLTIP_STEPS[currentStep];

    // Create an observer to watch for the element
    const observer = new MutationObserver((mutations, obs) => {
      const element = document.querySelector(step.selector);
      if (element) {
        setCurrentElement(element as HTMLElement);
        setIsElementReady(true);
        obs.disconnect(); // Stop observing once we find the element
      }
    });

    // Start observing the document with the configured parameters
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Initial check in case element already exists
    const element = document.querySelector(step.selector);
    if (element) {
      setCurrentElement(element as HTMLElement);
      setIsElementReady(true);
    } else {
      setIsElementReady(false);
    }

    // Cleanup
    return () => {
      observer.disconnect();
    };
  }, [currentStep]);

  const handleNext = async () => {
    if (currentStep === TOOLTIP_STEPS.length - 1) {
      setIsOpen(false);
      return;
    }
    setIsElementReady(false);

    const currentTooltip = TOOLTIP_STEPS[currentStep];
    if (currentTooltip.forwardNavigation) {
      await router.push({
        pathname: currentTooltip.forwardNavigation,
        query: router.query,
      });
    }

    setTimeout(() => {
      setCurrentStep((prev) => prev + 1);
    }, 50);
  };

  const handleBack = async () => {
    const previousStep = TOOLTIP_STEPS[currentStep - 1];
    setIsElementReady(false);
    if (previousStep && TOOLTIP_STEPS[currentStep].backNavigation) {
      await router.push({
        pathname: TOOLTIP_STEPS[currentStep].backNavigation,
        query: router.query,
      });
    }
    setTimeout(() => {
      setCurrentStep((prev) => Math.max(0, prev - 1));
    }, 50);
  };

  const currentTooltip = TOOLTIP_STEPS[currentStep];

  if (!isOpen || !isElementReady) return null;

  return (
    <>
      <div
        className="fixed inset-0 bg-black/5 z-popover"
        onClick={(e) => e.preventDefault()}
        onMouseDown={(e) => e.preventDefault()}
      ></div>

      <Tooltip open={isElementReady} defaultOpen={false}>
        {currentElement && isElementReady ? (
          <TooltipPortal container={currentElement} forceMount>
            <TooltipTrigger
              className={`${currentTooltip.position.triggerPosition} h-6 w-6 rounded-full bg-primary-200 dark:bg-white/50 flex items-center justify-center z-popoverOnDrawer`}
            >
              <div className="rounded-full h-3 w-3 bg-primary-400 dark:bg-white" />
            </TooltipTrigger>
          </TooltipPortal>
        ) : null}
        {currentElement && isElementReady ? (
          <TooltipContent
            side={currentTooltip.position.tooltipSide}
            align="center"
            className={cn(
              "bg-primary-50 dark:bg-foreground dark:text-background border-primary-50 dark:border-foreground w-80 z-popoverOnDrawer p-4 shadow-md shadow-primary/25"
            )}
          >
            <TooltipArrow className="fill-primary-50 dark:fill-foreground size-4" />
            <p className="uppercase text-primary-200 text-xs tracking-wide font-semibold">
              Step {currentTooltip.step} of {TOOLTIP_STEPS.length}
            </p>
            <p className="mt-4 font-semibold text-lg text-gray-800">
              {currentTooltip.title}
            </p>
            <p className="text-gray-700 text-sm">
              {currentTooltip.description}
            </p>
            <div className="flex justify-between mt-4">
              {currentStep > 0 ? (
                <Button variant="ghost" size="sm" onClick={handleBack}>
                  Back
                </Button>
              ) : (
                <div />
              )}
              <Button size="sm" onClick={handleNext}>
                {currentTooltip.buttonText}
              </Button>
            </div>
          </TooltipContent>
        ) : null}
      </Tooltip>
    </>
  );
}

export default TracifyOnboardingTooltips;
