import React from "react";

const IntegrationsIcon = ({
  color,
  className,
}: {
  color: string;
  className?: string;
}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M16.6665 2.5H12.4998C12.0396 2.5 11.6665 2.8731 11.6665 3.33333V7.5C11.6665 7.96024 12.0396 8.33333 12.4998 8.33333H16.6665C17.1267 8.33333 17.4998 7.96024 17.4998 7.5V3.33333C17.4998 2.8731 17.1267 2.5 16.6665 2.5Z"
        stroke="currentColor"
        strokeWidth="1.45833"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33333 17.5V6.66668C8.33333 6.44566 8.24554 6.2337 8.08926 6.07742C7.93298 5.92114 7.72101 5.83334 7.5 5.83334H3.33333C3.11232 5.83334 2.90036 5.92114 2.74408 6.07742C2.5878 6.2337 2.5 6.44566 2.5 6.66668V16.6667C2.5 16.8877 2.5878 17.0997 2.74408 17.2559C2.90036 17.4122 3.11232 17.5 3.33333 17.5H13.3333C13.5543 17.5 13.7663 17.4122 13.9226 17.2559C14.0789 17.0997 14.1667 16.8877 14.1667 16.6667V12.5C14.1667 12.279 14.0789 12.067 13.9226 11.9108C13.7663 11.7545 13.5543 11.6667 13.3333 11.6667H2.5"
        stroke="currentColor"
        strokeWidth="1.45833"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IntegrationsIcon;
