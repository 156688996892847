import { useCallback } from "react";
import {
  attributionModelAtom,
  attributionPresetAtom,
  attributionWindowAtom,
  comparedAtom,
  comparePeriodAtom,
  coreSystemAtom,
  endTimeAtom,
  previousEndTimeAtom,
  previousStartTimeAtom,
  reportTimeAtom,
  selectedMarketingChannelsAtom,
  selectedStoreIdsAtom,
  selectedTimezoneAtom,
  startTimeAtom,
  timeRangeKeyAtom,
} from "atoms";
import { useAtom } from "jotai";
import { LocalStateType } from "./useInitializeAtoms";
import dayjs from "dayjs";
import {
  getPreviousDateRange,
  getStartDateRange,
  getTimerangeFromDates,
} from "services/DateRangeServices";
import { START_DATE_RANGE_SELECT_OPTIONS_FULL } from "constants/constants";
import useSelectedStores from "./use-selected-stores";
import { usePresets } from "@lib/api-hooks/usePresets/usePresets";

export const useInitFromLocalState = () => {
  const [startTime, setStartTime] = useAtom(startTimeAtom);
  const [endTime, setEndTime] = useAtom(endTimeAtom);
  const [attributionModel, setAttributionModel] = useAtom(attributionModelAtom);
  const [attributionWindow, setAttributionWindow] = useAtom(
    attributionWindowAtom
  );
  const { selectedOrganisationData } = useSelectedStores();
  const [coreSystem, setCoreSystem] = useAtom(coreSystemAtom);

  const [, setTimeRangeKey] = useAtom(timeRangeKeyAtom);
  const [comparePeriod, setComparePeriod] = useAtom(comparePeriodAtom);
  const [reportTime, setReportTime] = useAtom(reportTimeAtom);
  const [selectedMarketingChannels, setSelectedMarketingChannels] = useAtom(
    selectedMarketingChannelsAtom
  );
  const { data: presets, isPending } = usePresets(
    selectedOrganisationData?.id ?? "",
    {
      enabled: Boolean(selectedOrganisationData?.id),
    }
  );
  const [attributionPreset, setAttributionPreset] = useAtom(
    attributionPresetAtom
  );
  const [selectedTimezone, setSelectedTimezone] = useAtom(selectedTimezoneAtom);
  const [selectedStoreIds, setSelectedStoreIds] = useAtom(selectedStoreIdsAtom);
  const [compared, setCompared] = useAtom(comparedAtom);
  const [, setPreviousStartTime] = useAtom(previousStartTimeAtom);
  const [, setPreviousEndTime] = useAtom(previousEndTimeAtom);

  const initState = useCallback(
    (localState: LocalStateType, keys: string[]) => {
      if (typeof window !== undefined) {
        let currentDateRange = {
          startDate: dayjs(localState.startTime ?? startTime).toDate(),
          endDate: dayjs(localState.endTime ?? endTime).toDate(),
          key: "selection",
        };
        const timerange = localState.timerange;
        if (localState.timerange) {
          const timerangeFromOptions =
            START_DATE_RANGE_SELECT_OPTIONS_FULL.find(
              (el) => el.value === timerange
            )?.value;
          if (timerangeFromOptions && timerangeFromOptions !== "Custom") {
            const dateRange = getStartDateRange(timerangeFromOptions);
            if (timerangeFromOptions !== timerange) {
              setTimeRangeKey(timerangeFromOptions);
            }
            if (dateRange.startDate) {
              setStartTime(
                dayjs(dateRange.startDate)
                  .startOf("day") // set minute and second to 0 to make client-side caching easier
                  .toISOString()
              );
            }
            if (dateRange.endDate) {
              setEndTime(
                dayjs(dateRange.endDate)
                  .endOf("day") // set minute and second to 0 to make client-side caching easier
                  .toISOString()
              );
            }
            currentDateRange = dateRange;
          } else if (
            dayjs(localState.startTime).isValid() &&
            dayjs(localState.endTime).isValid() &&
            dayjs(localState.endTime).isAfter(dayjs(localState.startTime))
          ) {
            const timerangeFromDates = getTimerangeFromDates({
              startDate: startTime,
              endDate: endTime,
            });
            setTimeRangeKey(timerangeFromDates);
          } else if (
            dayjs(localState.startTime).isValid() &&
            dayjs(localState.endTime).isValid() &&
            !dayjs(localState.endTime).isAfter(dayjs(localState.startTime))
          ) {
            setTimeRangeKey("today");
            setStartTime(dayjs().startOf("day").toISOString());
            setEndTime(dayjs().endOf("day").toISOString());
          } else {
            setTimeRangeKey("Custom");
          }
        }

        if (
          coreSystem === "v2" &&
          localState.attributionPreset &&
          attributionPreset !== localState.attributionPreset
        ) {
          setAttributionPreset(localState.attributionPreset);
        } else if (coreSystem === "v2" && presets?.[0] && !attributionPreset) {
          setAttributionPreset(presets[0].presetId);
        }

        if (
          localState.attributionModel &&
          attributionModel !== localState.attributionModel
        ) {
          setAttributionModel(localState.attributionModel);
        }
        if (
          localState.attributionWindow &&
          attributionWindow !== localState.attributionWindow
        ) {
          setAttributionWindow(
            localState.attributionWindow as typeof attributionWindow
          );
        }
        if (
          localState.comparePeriod &&
          comparePeriod !== localState.comparePeriod
        ) {
          setComparePeriod(localState.comparePeriod);
        }
        if (localState.compared && compared !== localState.compared) {
          setCompared(localState.compared);
          const period = localState.comparePeriod ?? comparePeriod;
          const previousRange = getPreviousDateRange(period, currentDateRange);
          setPreviousStartTime(
            dayjs(previousRange.startDate).startOf("day").toISOString()
          );
          setPreviousEndTime(
            dayjs(previousRange.endDate).endOf("day").toISOString()
          );
        }
        if (localState.reportTime && reportTime !== localState.reportTime) {
          setReportTime(localState.reportTime);
        }
        if (
          localState.selectedMarketingChannels &&
          selectedMarketingChannels !== localState.selectedMarketingChannels
        ) {
          setSelectedMarketingChannels(localState.selectedMarketingChannels);
        }
        if (
          localState.selectedStoreIds &&
          selectedStoreIds !== localState.selectedStoreIds
        ) {
          setSelectedStoreIds(localState.selectedStoreIds);
        }
        if (
          localState.selectedTimezone &&
          selectedTimezone !== localState.selectedTimezone
        ) {
          setSelectedTimezone(localState.selectedTimezone);
        }
      }
    },
    [
      attributionModel,
      attributionPreset,
      attributionWindow,
      comparePeriod,
      compared,
      coreSystem,
      endTime,
      presets,
      reportTime,
      selectedMarketingChannels,
      selectedStoreIds,
      selectedTimezone,
      setAttributionModel,
      setAttributionPreset,
      setAttributionWindow,
      setComparePeriod,
      setCompared,
      setEndTime,
      setPreviousEndTime,
      setPreviousStartTime,
      setReportTime,
      setSelectedMarketingChannels,
      setSelectedStoreIds,
      setSelectedTimezone,
      setStartTime,
      setTimeRangeKey,
      startTime,
    ]
  );
  return initState;
};
