import React, { PropsWithChildren, ReactElement, useCallback } from "react";
import { Table } from "@tanstack/react-table";
import Pagination from "../pagination";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

export function TablePagination<T extends Record<string, unknown>>({
  table,
  disableRowsPerPage,
  tableRef,
  rowsPerPageOptions = [10, 25, 50, 100, 200],
}: PropsWithChildren<{
  table: Table<T>;
  disableRowsPerPage?: boolean;
  rowsPerPageOptions?: number[];
  tableRef: React.MutableRefObject<HTMLDivElement | null>;
}>): ReactElement | null {
  const handleChangePage = useCallback(
    (newPage: number) => {
      table.setPageIndex(Math.ceil(newPage - 1));
      tableRef?.current?.scrollTo({ top: 0, left: 0 });
    },
    [table, tableRef]
  );
  const onChangeRowsPerPage = useCallback(
    (value: string) => {
      table.setPageSize(Number(value));
    },
    [table]
  );
  return (
    <div className="flex items-center">
      {!disableRowsPerPage && (
        <div className="flex items-center">
          <p className="text-sm mr-2">Rows per Page:</p>
          <Select
            value={table.getState().pagination.pageSize.toString()}
            onValueChange={onChangeRowsPerPage}
          >
            <SelectTrigger variant="alternate" size="sm" className="w-20">
              <SelectValue placeholder="Select rows" />
            </SelectTrigger>
            <SelectContent>
              {rowsPerPageOptions.map((option) => (
                <SelectItem key={option} value={option.toString()}>
                  {option}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      )}
      {table.getPageCount() > 1 ? (
        <>
          <div className="w-4" />
          <Pagination
            //   rowsPerPageOptions={rowsPerPageOptions}
            count={Math.ceil(table.getPageCount())}
            //   rowsPerPage={pageSize}
            page={table.getState().pagination.pageIndex + 1}
            //   onChangePage={handleChangePage}
            //   onChangeRowsPerPage={onChangeRowsPerPage}
            onChange={(value) => handleChangePage(value)}
          >
            <Pagination.Next disabled={!table.getCanNextPage()}>
              <p className="px-2">Next</p>
            </Pagination.Next>
            <Pagination.Previous disabled={!table.getCanPreviousPage()}>
              <p className="px-2">Previous</p>
            </Pagination.Previous>
          </Pagination>
        </>
      ) : null}
    </div>
  );
}
