export interface Currency {
  currency: string;
  multiplier?: number;
  value: string;
  short: string;
  alwaysAddSpace: boolean;
}

export const STORAGE_KEYS = {
  STORE_CURRENCY: "tracify:store-currency",
  BLENDING_CURRENCY: "tracify:blending-currency",
  DASHBOARD_MODE: "tracify:dashboard-mode",
  SETTINGS_BEFORE_ISOLATED: "tracify:settings-before-isolated",
  ATTRIBUTION_MENU_STATE: "tracify:attribution-menu-state",
  SETTINGS_RETURNS: "tracify:settings-returns",
  HISTORICAL_CHART_METRIC: "tracify:historical-chart-metric",
  CORE_SYSTEM: "tracify:core-system",
  ONBOARDING_SHOWN_KEY: "tracify:onboarding-shown",
  HAS_CREATED_PRESET: "tracify:has-created-preset",
};

export const availableDashboardModes = [
  { value: "ecommerce", label: "E-Commerce" },
  { value: "general", label: "General" },
] as const;

export const availableHistoricalChartMetrics = [
  "spend",
  "purchaseAmount",
  "roas",
  "cac",
] as const;

export const availableCurrencies: Currency[] = [
  { currency: "Euro (EUR)", value: "€", short: "EUR", alwaysAddSpace: false },

  {
    currency: "US Dollar (USD)",
    value: "$",
    short: "USD",
    alwaysAddSpace: false,
  },
  {
    currency: "Australian Dollar (AUD)",
    value: "$",
    short: "AUD",
    alwaysAddSpace: false,
  },
  {
    currency: "Canadian Dollar (CAD)",
    value: "$",
    short: "CAD",
    alwaysAddSpace: false,
  },
  {
    currency: "Swiss Franc (CHF)",
    value: "CHF",
    short: "CHF",
    alwaysAddSpace: true,
  },
  {
    currency: "British Pound (GBP)",
    value: "£",
    short: "GBP",
    alwaysAddSpace: false,
  },
  {
    currency: "Japanese Yen (JPY)",
    value: "¥",
    short: "JPY",
    alwaysAddSpace: false,
  },
  {
    currency: "Chinese Yuan (CNY)",
    value: "¥",
    short: "CNY",
    alwaysAddSpace: false,
  },
  {
    currency: "Danish Krone (DKK)",
    value: "kr",
    short: "DKK",
    alwaysAddSpace: true,
  },
  {
    currency: "Hungarian Forint (HUF)",
    value: "Ft",
    short: "HUF",
    alwaysAddSpace: true,
  },
  {
    currency: "Indian Rupee (INR)",
    value: "₹",
    short: "INR",
    alwaysAddSpace: false,
  },
  {
    currency: "Norwegian Krone (NOK)",
    value: "kr",
    short: "NOK",
    alwaysAddSpace: true,
  },
  {
    currency: "Polish Zloty (PLN)",
    value: "zł",
    short: "PLN",
    alwaysAddSpace: false,
  },
  {
    currency: "Russian Ruble (RUB)",
    value: "₽",
    short: "RUB",
    alwaysAddSpace: false,
  },
  {
    currency: "Singapore Dollar (SGD)",
    value: "S$",
    short: "SGD",
    alwaysAddSpace: false,
  },
  {
    currency: "Swedish Krona (SEK)",
    value: "kr",
    short: "SEK",
    alwaysAddSpace: true,
  },
  {
    currency: "Turkish Lira (TRY)",
    value: "₺",
    short: "TRY",
    alwaysAddSpace: false,
  },
  {
    currency: "Czech Republic Koruna (CZK)",
    value: "Kč",
    short: "CZK",
    alwaysAddSpace: true,
  },
  {
    currency: "Icelandic Krona (ISK)",
    value: "kr",
    short: "ISK",
    alwaysAddSpace: true,
  },
  {
    currency: "New Zealand Dollar (NZD)",
    value: "NZ$",
    short: "NZD",
    alwaysAddSpace: false,
  },
  {
    currency: "Romanian Leu (RON)",
    value: "lei",
    short: "RON",
    alwaysAddSpace: true,
  },
  {
    currency: "UAE Dirham (AED)",
    value: "AED",
    short: "AED",
    alwaysAddSpace: true,
  },
];

export const RETURN_SETTINGS = {
  ORDER_DATE: "order_date",
  REFUND_DATE: "refund_date",
};
