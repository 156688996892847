import React, { useState, useEffect } from "react";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Step1Content } from "./Steps/Step1Content";
import { Step2Content } from "./Steps/Step2Content";
import { STORAGE_KEYS } from "@lib/hooks/constants";
import TracifyOnboardingTooltips from "@components/Layout/TracifyOnboardingTooltips/TracifyOnboardingTooltips";
import { useRouter } from "next/router";
import { coreSystemAtom } from "@/atoms/store";
import { useAtom } from "jotai";

interface OnboardingFlowProps {}

export function OnboardingFlow({}: OnboardingFlowProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [coreSystem, setCoreSystem] = useAtom(coreSystemAtom);
  const router = useRouter();

  useEffect(() => {
    const hasOnboardingBeenShown =
      localStorage.getItem(STORAGE_KEYS.ONBOARDING_SHOWN_KEY) === "true";

    if (!hasOnboardingBeenShown) {
      setIsOpen(true);
      localStorage.setItem(STORAGE_KEYS.ONBOARDING_SHOWN_KEY, "true");
    }
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleResetAndClose = () => {
    handleClose();
    setTimeout(() => {
      setCurrentStep(1);
    }, 200);
  };

  const handleNext = (step?: number) => {
    if (step && step > 0 && !isNaN(step)) return setCurrentStep(step);
    if (currentStep < 9) {
      setCurrentStep(currentStep + 1);
    } else {
      // Handle completion of all steps
    }
  };

  const handleGoToOnboardingTooltips = async () => {
    try {
      await router.push({ pathname: "/settings/presets", query: router.query });
      setCoreSystem("v2");
      setCurrentStep(3);
    } catch (error) {
      console.error(error);
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return <Step1Content onNext={handleNext} />;
      case 2:
        return <Step2Content onNext={handleGoToOnboardingTooltips} />;
      case 3:
        return <TracifyOnboardingTooltips />;
      default:
        return null;
    }
  };

  return currentStep > 2 ? (
    renderStepContent()
  ) : (
    <Dialog open={isOpen} onOpenChange={handleResetAndClose} modal>
      <DialogContent
        className={`max-w-[800px] overflow-y-auto max-h-[90vh]`}
        onPointerDownOutside={(e) => e.preventDefault()}
      >
        {renderStepContent()}
      </DialogContent>
    </Dialog>
  );
}

export default OnboardingFlow;
