import React from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Button } from "@components/ui/button";
import LinkWithPersistentQuery from "@components/LinkWithQuery/LinkWithQuery";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat"; // ES 2015
import { useAtom } from "jotai";
import { attributionPresetAtom, coreSystemAtom } from "@/atoms/store";
import useSelectedStores from "@lib/hooks/use-selected-stores";
import { usePresets } from "@lib/api-hooks/usePresets/usePresets";
dayjs.extend(advancedFormat);

const PresetRecalculatingNote = () => {
  const [coreSystem] = useAtom(coreSystemAtom);
  const { selectedOrganisation } = useSelectedStores();
  const { data: presets = [] } = usePresets(selectedOrganisation ?? "", {
    enabled: Boolean(selectedOrganisation),
  });
  const [attributionPreset] = useAtom(attributionPresetAtom);
  const selectedPreset = presets?.find(
    (preset) => preset.presetId === attributionPreset
  );
  const isPresetRecalculating =
    coreSystem === "v2" && selectedPreset?.status === "REPROCESSING";
  if (isPresetRecalculating) {
    return (
      <div className="w-full bg-purple-500 rounded-md px-4 py-2.5 flex gap-2 items-center">
        <InformationCircleIcon className="h-5 w-5" />
        <p>
          Your presets are currently recalculating, which means your data is
          only available up until{" "}
          {dayjs(selectedPreset?.lastSetToProcessingAt).format("MMMM Do")}.
        </p>
        <Button variant="link" asChild>
          <LinkWithPersistentQuery href="/settings/presets">
            Check preset status
          </LinkWithPersistentQuery>
        </Button>
      </div>
    );
  }

  return null;
};

export default PresetRecalculatingNote;
