import {
  AnalyticsResult,
  TimeSettingsType,
  InfluencerBaseAnalytics,
  BaseScore,
  DailyBaseAnalyticsWithOrders,
  BaseOrder,
  BaseAnalyticsWithOrders,
  NvrBaseAnalyticsWithOrders,
  NvrInfluencerAttributionDataWithOrders,
  NvrDailyBaseAnalyticsWithOrders,
  InfluencerAttributionDataWithOrders,
} from "@api/types/backendTypes";
import { kpiCalculations } from "@lib/util-functions";
import { getTimeZones } from "@vvo/tzdb";
import {
  AnalyticsOptions,
  initializeBaseScoreValue,
  mergeCooperationLinkWithAnalytics,
  joinBaseScoreValuesFromArray,
  mergeInfluencerWithAnalytics,
  checkIfBaseScoreHasData,
  mergeCooperationWithAnalytics,
  FQTransformOptions,
  getBaseScoreFromAds,
  getUnattributedAdScores,
  extractInfosFromAnalyticsResult,
  ScopeType,
  splitScopedId,
  mergeBaseScoreMap,
  addAllDataToNvrNew,
  addAllDataToNvrReturning,
  addCacDataToNvrAll,
} from "./analytics";
import dayjs from "dayjs";

const emptyOrder = {
  pageview: 0,
  productview: 0,
  checkout: 0,
  purchaseCount: 0,
  addtocart: 0,
  purchaseCartItems: 0,
  purchaseAmount: 0,
  nvr_purchaseAmount: 0,
  nvr_purchaseCount: 0,
  nvr_upv: 0,
  newCustomerRate: 0,
  newVisitorRate: 0,
} as BaseOrder;
/**
 *
 * @param {AnalyticsResult} result Data from the result provided by Tracify endpoint /analytics/results
 * @param {TimeSettingsType} timeSettings the timesettings used for creating the request
 * @param {AnalyticsOptions} options Data from the result provided by Tracify endpoint /analytics/results
 * @return {BaseAnalyticsWithOrders} returns the BaseAnalyticsWithOrders result for every day we got data
 */
export const transformInfluencerWithOrdersResult = (
  result: AnalyticsResult,
  timeSettings: TimeSettingsType,
  options: AnalyticsOptions
) => {
  let regularAnalyticsData: BaseAnalyticsWithOrders = {
    ads: new Map(),
    adsets: new Map(),
    campaigns: new Map(),
    orders: new Map(),
  } as BaseAnalyticsWithOrders;
  const regularAnalyticsWithDiscount: BaseAnalyticsWithOrders[] = [];
  if (
    result.attributions?.length === 0 ||
    result.attributions[0] === null ||
    !result.attributions[0].tc_oid_aggregated
  ) {
    // return empty result
    return regularAnalyticsData;
  }
  const timezones = getTimeZones();
  const currentTz = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localTimezone: string | undefined = timezones.find((el) => {
    return (
      el.name === currentTz &&
      el.currentTimeOffsetInMinutes / 60 === timeSettings.utcOffset
    );
  })?.name;

  const timezoneWithOffset = localTimezone
    ? localTimezone
    : timezones.find((el) => {
        return el.currentTimeOffsetInMinutes / 60 === timeSettings.utcOffset;
      })?.name ?? new Intl.DateTimeFormat().resolvedOptions().timeZone;

  const rawResult = { ...result } as AnalyticsResult;
  const tcAnalyticsData: InfluencerBaseAnalytics =
    new Map() as InfluencerBaseAnalytics;
  const orderData: Map<string, BaseOrder> = new Map();
  // let index = 0;
  for (const attribution of rawResult.attributions) {
    if (!attribution.tc_oid_aggregated) {
      return regularAnalyticsData;
    }
    for (const [clid, clResults] of Object.entries(
      attribution.tc_oid_aggregated
    )) {
      for (const [discountCode, values] of Object.entries(clResults)) {
        if (clid !== "") {
          // initialize empty linkScore
          let linkScore = initializeBaseScoreValue({
            baseScoreId: clid,
            fqId: clid,
            scope: "influencer_module",
            type: "cooperationLink",
          });

          // add attribution data from Hive to adScore
          for (const [, csidMap] of Object.entries(values)) {
            for (const [, ordersMap] of Object.entries(csidMap)) {
              for (const [orderId, interactionMap] of Object.entries(
                ordersMap
              )) {
                if (orderId !== "" && orderData.get(orderId) === undefined) {
                  orderData.set(orderId, {
                    ...emptyOrder,
                    ads: [],
                    orderId: orderId,
                  });
                }
                const order = orderId !== "" ? orderData.get(orderId) : null;
                const adOrderScore = order
                  ? {
                      ...emptyOrder,
                      fqId: clid,
                      adId: clid,
                      provider: "influencer_module",
                    }
                  : null;
                for (const [interaction, scoreMap] of Object.entries(
                  interactionMap as Object
                )) {
                  for (const [scoreName, scoreValue] of Object.entries(
                    scoreMap as Object
                  )) {
                    if (scoreName === "attribution") {
                      if (interaction === "pageview") {
                        linkScore.pageview += scoreValue;
                        if (order) {
                          order.pageview += scoreValue;
                        }
                        if (adOrderScore) {
                          adOrderScore.pageview += scoreValue;
                        }
                      } else if (interaction === "productview") {
                        linkScore.productview += scoreValue;
                        if (order) {
                          order.productview += scoreValue;
                        }
                        if (adOrderScore) {
                          adOrderScore.productview += scoreValue;
                        }
                      } else if (interaction === "checkout") {
                        linkScore.checkout += scoreValue;
                        if (order) {
                          order.checkout += scoreValue;
                        }
                        if (adOrderScore) {
                          adOrderScore.checkout += scoreValue;
                        }
                      } else if (interaction === "purchase") {
                        linkScore.purchaseCount += scoreValue;
                        if (order) {
                          order.purchaseCount += scoreValue;
                        }
                        if (adOrderScore) {
                          adOrderScore.purchaseCount += scoreValue;
                        }
                      } else if (interaction === "addtocart") {
                        linkScore.addtocart += scoreValue;
                        if (order) {
                          order.addtocart += scoreValue;
                        }
                        if (adOrderScore) {
                          adOrderScore.addtocart += scoreValue;
                        }
                      }
                    } else if (scoreName === "nitems") {
                      if (interaction === "purchase")
                        linkScore.purchaseCartItems += scoreValue;
                      if (order) {
                        order.purchaseCartItems += scoreValue;
                      }
                      if (adOrderScore) {
                        adOrderScore.purchaseCartItems += scoreValue;
                      }
                    } else if (scoreName === "amount") {
                      if (interaction === "purchase")
                        linkScore.purchaseAmount += scoreValue;
                      if (order) {
                        order.purchaseAmount += scoreValue;
                      }
                      if (adOrderScore) {
                        adOrderScore.purchaseAmount += scoreValue;
                      }
                    }
                  }
                }

                if (order) {
                  if (adOrderScore) {
                    order.ads.push(adOrderScore);
                  }
                  orderData.set(orderId, order);
                }
              }
            }
          }
          linkScore.cr = kpiCalculations.cr(linkScore);
          linkScore.aov = kpiCalculations.aov(linkScore);
          // In case this score already exists because there are attributions
          // using different discount codes, retrieve that data and merge it
          // with the current data.
          const existingScore = tcAnalyticsData?.get(clid);
          let discountCodeScore = structuredClone(linkScore);
          discountCodeScore.refId = `${discountCodeScore.refId}::${discountCode}`;
          discountCodeScore.fullyQualifiedId = `${discountCodeScore.fullyQualifiedId}::${discountCode}`;
          discountCodeScore.name = discountCode;
          discountCodeScore.type = "discountCode";

          let cooperation;

          if (options.influencerData) {
            for (const influencer of options.influencerData) {
              if (cooperation) break;

              for (const coop of influencer.cooperations) {
                if (cooperation) break;
                const isScoreInCoop =
                  coop.links.findIndex((el) => el.id === linkScore.refId) !==
                  -1;
                if (isScoreInCoop) {
                  cooperation = coop;
                }
              }
            }
          } else if (options.cooperationData) {
            for (const coop of options.cooperationData) {
              if (cooperation) break;

              const isScoreInCoop =
                coop.links.findIndex((el) => el.id === linkScore.refId) !== -1;
              if (isScoreInCoop) {
                cooperation = coop;
              }
            }
          }
          if (cooperation) {
            discountCodeScore = mergeCooperationLinkWithAnalytics(
              discountCodeScore,
              cooperation,
              {
                startTime: timeSettings.startTime,
                endTime: timeSettings.endTime,
                timezone: timezoneWithOffset,
              },
              {
                calculateNewCustomerScores: options.calculateNewCustomerScores,
                isNewUserData: options.isNewUserData,
                forceCalculateNvr: options.forceCalculateNvr,
              },
              "discountCode"
            ) as BaseScore;
          }
          if (existingScore) {
            linkScore = joinBaseScoreValuesFromArray(
              [existingScore, linkScore],
              {
                calculateNewCustomerScores: options.calculateNewCustomerScores,
                isNewUserData: options.isNewUserData,
                forceCalculateNvr: options.forceCalculateNvr,
              }
            );
          }
          linkScore.subRows.push(discountCodeScore);
          tcAnalyticsData.set(clid, linkScore);
        } else if (discountCode === "" && !options.filter?.onlyInfluencerData) {
          // only under the "" discount code we got the regular attributions for all the other channels
          const regularAttributions = [{ aggregated: values }];
          const regularResult: AnalyticsResult = {
            ...result,
            attributions: regularAttributions,
          };
          const data = transformAnalyticsResultWithOrders(
            regularResult,
            orderData,
            {
              calculateNewCustomerScores: options.calculateNewCustomerScores,
              isNewUserData: options.isNewUserData,
            }
          );
          if (data) regularAnalyticsData = data;
        } else if (!options.filter?.onlyInfluencerData) {
          // these are the old influencer attributions which also include the discount code now
          const regularAttributions = [{ aggregated: values }];
          const regularResult: AnalyticsResult = {
            ...result,
            ads: undefined, //  added here so we don't add unnecessary ad info to the result
            attributions: regularAttributions,
          };
          const transformResult = transformAnalyticsResultWithOrders(
            regularResult,
            orderData,
            {
              calculateNewCustomerScores: options.calculateNewCustomerScores,
              isNewUserData: options.isNewUserData,
            }
          );
          if (transformResult) {
            regularAnalyticsWithDiscount.push(transformResult);
          }
        }
      }
    }
  }

  const adsData = Array.from(tcAnalyticsData.values());

  // as a default we also set the cooperation link data as the campaign data
  // but normally we either want to set the campaign data mapped to the influencers or the cooperations
  // which we do, when we either have the influencer or cooperation data in the request
  let influencerCampaignData = adsData;
  let influencerAdsetData: BaseScore[] = [];
  if (options.influencerData) {
    influencerCampaignData = mergeInfluencerWithAnalytics(
      options.influencerData,
      tcAnalyticsData,
      {
        startTime: timeSettings.startTime,
        endTime: timeSettings.endTime,
        timezone: timezoneWithOffset,
      },
      {
        calculateNewCustomerScores: options.calculateNewCustomerScores,
        isNewUserData: options.isNewUserData,
        cooperationsAsAdsets: Boolean(options.cooperationsAsAdsets),
        forceCalculateNvr: options.forceCalculateNvr,
      }
    )?.filter((el) => checkIfBaseScoreHasData(el)) as BaseScore[];
    // set the cooperation data as adsets
    if (options.cooperationData) {
      influencerAdsetData = mergeCooperationWithAnalytics(
        options.cooperationData,
        tcAnalyticsData,
        {
          startTime: timeSettings.startTime,
          endTime: timeSettings.endTime,
          timezone: timezoneWithOffset,
        },
        {
          calculateNewCustomerScores: options.calculateNewCustomerScores,
          isNewUserData: options.isNewUserData,
          forceCalculateNvr: options.forceCalculateNvr,
        }
      )?.filter((el) => checkIfBaseScoreHasData(el)) as BaseScore[];
    }
  } else if (options.cooperationData) {
    influencerCampaignData = mergeCooperationWithAnalytics(
      options.cooperationData,
      tcAnalyticsData,
      {
        startTime: timeSettings.startTime,
        endTime: timeSettings.endTime,
        timezone: timezoneWithOffset,
      },
      {
        calculateNewCustomerScores: options.calculateNewCustomerScores,
        isNewUserData: options.isNewUserData,
        forceCalculateNvr: options.forceCalculateNvr,
      }
    )?.filter((el) => checkIfBaseScoreHasData(el)) as BaseScore[];
  } else if (adsData.length > 0) {
    // we set the pure cooperation link attributions as the ads data for influencers
    // only when we don't want to match against influencer data
    regularAnalyticsData?.ads.set("influencer_module", adsData);
  }

  if (influencerCampaignData.length > 0) {
    regularAnalyticsData?.campaigns.set(
      "influencer_module",
      influencerCampaignData
    );
    regularAnalyticsData?.ads.set("influencer_module", influencerCampaignData);
  }
  if (influencerAdsetData.length > 0) {
    regularAnalyticsData?.adsets.set("influencer_module", influencerAdsetData);
  }
  regularAnalyticsData = mergeBaseAnalyticsWithOrdersValues(
    [regularAnalyticsData, ...regularAnalyticsWithDiscount],
    options
  );
  return regularAnalyticsData;
};
export const mergeBaseAnalyticsWithOrdersValues = (
  analytics: BaseAnalyticsWithOrders[],
  options: FQTransformOptions
) => {
  // ideally we want to have the biggest BaseAnalytics object as the first value so
  // we can set the first value as default to avoid having to loop over the biggest obj
  const joinedAnalytics = {
    ads: analytics[0].ads ?? new Map(),
    adsets: analytics[0].adsets ?? new Map(),
    campaigns: analytics[0].campaigns ?? new Map(),
    orders: analytics[0].orders ?? new Map(),
  } as BaseAnalyticsWithOrders;

  for (const analyticsValue of analytics.slice(1)) {
    const adScores = mergeBaseScoreMap(
      analyticsValue.ads,
      joinedAnalytics.ads,
      options
    );
    const adsetScores = mergeBaseScoreMap(
      analyticsValue.adsets,
      joinedAnalytics.adsets,
      options
    );
    const campaignScores = mergeBaseScoreMap(
      analyticsValue.campaigns,
      joinedAnalytics.campaigns,
      options
    );

    joinedAnalytics.ads = adScores;
    joinedAnalytics.adsets = adsetScores;
    joinedAnalytics.campaigns = campaignScores;
  }
  return joinedAnalytics;
};
export const transformDailyInfluencerWithOrdersResult = (
  result: AnalyticsResult,
  timeSettings: TimeSettingsType,
  options: AnalyticsOptions
) => {
  const dailyAnalyticsData: DailyBaseAnalyticsWithOrders = {};
  if (
    result.attributions?.length === 0 ||
    result.attributions[0] === null ||
    !result.attributions[0].tc_oid_daily
  ) {
    // return empty result
    return dailyAnalyticsData;
  }
  const rawResult = { ...result } as AnalyticsResult;
  const allAdsetIds = result.adsetids;
  const allCampaignIds = result.campaignids;
  let index = 0;
  for (const attribution of rawResult.attributions) {
    if (!attribution.tc_oid_daily) {
      return dailyAnalyticsData;
    }
    const diff = dayjs(timeSettings.endTime).diff(
      dayjs(timeSettings.startTime),
      "day"
    );

    let addedDatesManually = false;
    for (let i = 0; i <= diff; i++) {
      const day = dayjs(timeSettings.startTime)
        .utcOffset(timeSettings.utcOffset ?? 0)
        .add(i, "day")
        .format("YYYY-MM-DD");

      if (!attribution.tc_oid_daily[day]) {
        attribution.tc_oid_daily[day] = { "": { "": {} } };
        addedDatesManually = true;
      }
    }
    if (addedDatesManually) {
      // obj keys need to be sorted for the daily analytics (graphs) to work
      const sortedArray = Object.entries(attribution.tc_oid_daily).sort(
        ([key1], [key2]) => dayjs(key1).unix() - dayjs(key2).unix()
      );
      attribution.tc_oid_daily = Object.fromEntries(sortedArray);
    }
    for (const [attributionDay, attributionData] of Object.entries(
      attribution.tc_oid_daily
    )) {
      // here we get the dates back in the format "YYYY-MM-DD" and we want to
      // transform that to the request timezone in UTC by adding the utcOffset
      // to be able to check if the start and end date are the same as in the current local day
      const attributionDate = timeSettings.utcOffset
        ? dayjs(attributionDay).utcOffset(timeSettings.utcOffset, true)
        : dayjs(attributionDay);

      const isInTimeRange =
        attributionDate.isAfter(
          dayjs(timeSettings.startTime).subtract(1, "second"), // subtract 1 second to avoid having to check for same & after
          "second"
        ) &&
        attributionDate.isBefore(
          dayjs(timeSettings.endTime).add(1, "second"), // add 1 second to avoid having to check for same & before
          "second"
        );

      // only if the date is in the selected timerange, we want to add its result to our analytics data
      if (isInTimeRange) {
        const dailyAggregatedAdKpis: {
          [key: string]: { [key: string]: number };
        } = {};
        if (result?.ads && result?.ads[index]?.ad_kpis) {
          // quick fix for summer time changes where ad connector dates are outside of current date
          // to fix this, we add the offset diff to the adkpi date below
          const startOffset = dayjs(timeSettings.startTime)
            .tz(timeSettings.timezone)
            .utcOffset();
          const offsetDiff = startOffset / 60 - (timeSettings.utcOffset ?? 0);
          // offset diff can be negative, so we only want to apply it if it's positive to avoid timezone inconsistencies
          const offsetToApply = offsetDiff > 0 ? offsetDiff : 0;

          // for every adId we get an array of ad kpis for every date in the timerange (when available)
          for (const [adId, adKpis] of Object.entries(
            result?.ads[index]?.ad_kpis ?? {}
          )) {
            for (const adKpi of adKpis) {
              // here we get the date in UTC format YYYY-MM-DD HH:mm:ss in the accounts
              // timezone in the respective ads manager (fb, google, tiktok)
              // since we can only get date for the account in this timezone,
              // we can also only check if the end of the date in the accounts timezone
              // is at least the same day and add these kpi to this date then

              const adKpiDate = dayjs(adKpi.date)
                .utc(true)
                .add(offsetToApply, "hours");
              // check if date is between start and end of date because we
              // sometimes also have ads that have a different timestamp
              // (i.e. different timezone in ads account) and might be a different date in utc
              const dateBoolean =
                adKpiDate.isAfter(attributionDate.subtract(1, "second")) &&
                adKpiDate.isBefore(
                  attributionDate.add(1, "day").subtract(1, "millisecond")
                );

              if (dateBoolean) {
                dailyAggregatedAdKpis[adId] = adKpi;
              }
            }
          }
        }
        // here we mimic the result we would get back from Hive for only the current date in
        // the aggregated view, so we can reuse the transform function
        const dailyDataToAggregatedMap: AnalyticsResult = {
          adsetids: allAdsetIds,
          campaignids: allCampaignIds,
          totals: result.totals,
          attributions: [{ tc_oid_aggregated: attributionData }],
          ads: result.ads
            ? [
                {
                  timespantype: result.ads[index].timespantype,
                  aggregated_ad_kpis: dailyAggregatedAdKpis,
                  ad_infos: result.ads[index].ad_infos,
                  adset_infos: result.ads[index].adset_infos,
                  campaign_infos: result.ads[index].campaign_infos,
                },
              ]
            : [],
        };
        const dailyData = transformInfluencerWithOrdersResult(
          dailyDataToAggregatedMap,
          {
            ...timeSettings,
            endTime: attributionDate.endOf("day").toISOString(),
            startTime: attributionDate.startOf("day").toISOString(),
          },
          options
        );
        if (dailyAnalyticsData[attributionDay] && dailyData) {
          dailyAnalyticsData[attributionDay] = {
            ...dailyAnalyticsData[attributionDay],
            ...dailyData,
          };
        } else if (dailyData) {
          dailyAnalyticsData[attributionDay] = dailyData;
        }
      }
    }
    index++;
  }
  return dailyAnalyticsData;
};

export const transformAnalyticsResultWithOrders = (
  result: AnalyticsResult,
  orderData: Map<string, BaseOrder>,
  options?: FQTransformOptions
) => {
  if (result.attributions?.length === 0 || result.attributions[0] === null) {
    // pack result
    return null;
  }
  // raw result (from API) and transformed data
  const ads = new Map<string, Array<BaseScore>>();
  const adsets = new Map<string, Array<BaseScore>>();
  const campaigns = new Map<string, Array<BaseScore>>();

  // trim all "max_" or "smart_" prefixes from campaign ids across the whole result data
  // according to https://tracify-ai.atlassian.net/browse/DB-364
  // can be deleted after Hive and the ad-connector trim these prefixes server side
  for (const attribution of result.attributions) {
    const attributionData = attribution.aggregated;
    if (attributionData) {
      for (const [key, value] of Object.entries(attributionData)) {
        if (
          key.startsWith("google::max_") ||
          key.startsWith("google::smart_")
        ) {
          delete attributionData[key];
          const newKey = key
            ?.replace("google::max_", "google::")
            .replace("google::smart_", "google::");
          attributionData[newKey] = value;
        }
      }
    }
  }
  if (result.ads?.length) {
    for (const adData of result.ads) {
      const adInfos = adData.ad_infos;
      if (adInfos) {
        for (const [key, value] of Object.entries(adInfos)) {
          if (
            value?.campaign_id?.startsWith("google::max_") ||
            value?.campaign_id?.startsWith("google::smart_")
          ) {
            const newId = value?.campaign_id
              ?.replace("google::max_", "google::")
              .replace("google::smart_", "google::");
            adInfos[key] = { ...value, campaign_id: newId };
          }
          adData.ad_infos = adInfos;
        }
      }
      const adsetInfos = adData.adset_infos;
      if (adsetInfos) {
        for (const [key, value] of Object.entries(adsetInfos)) {
          if (
            value?.campaign_id?.startsWith("google::max_") ||
            value?.campaign_id?.startsWith("google::smart_")
          ) {
            const newId = value?.campaign_id
              ?.replace("google::max_", "google::")
              .replace("google::smart_", "google::");
            adsetInfos[key] = { ...value, campaign_id: newId };
          }
          adData.adset_infos = adsetInfos;
        }
      }
      const campaignInfos = adData.campaign_infos;
      if (campaignInfos) {
        for (const [key, value] of Object.entries(campaignInfos)) {
          if (
            key.startsWith("google::max_") ||
            key.startsWith("google::smart_")
          ) {
            delete campaignInfos[key];
            const newKey = key
              ?.replace("google::max_", "google::")
              .replace("google::smart_", "google::");
            campaignInfos[newKey] = value;
          }
          adData.campaign_infos = campaignInfos;
        }
      }
      const aggregatedKpis = adData.aggregated_ad_kpis;
      if (aggregatedKpis) {
        for (const [key, value] of Object.entries(aggregatedKpis)) {
          if (
            key.startsWith("google::max_") ||
            key.startsWith("google::smart_")
          ) {
            delete aggregatedKpis[key];
            const newKey = key
              ?.replace("google::max_", "google::")
              .replace("google::smart_", "google::");
            const newId = (value?.ad_id as string)
              ?.replace("google::max_", "google::")
              .replace("google::smart_", "google::");
            aggregatedKpis[newKey] = { ...value, ad_id: newId };
          }
        }
        adData.aggregated_ad_kpis = aggregatedKpis;
      }
    }
  }

  const rawResult = {
    ...result,
    adsetids: new Map(), // will be initialized later
    campaignids: new Map(), // will be initialized later
  } as AnalyticsResult;
  let { scopedAds } = getAdScoresFromAggregatedAttribution(
    rawResult,
    orderData,
    options
  );
  // https://github.com/Tracify-ai/dashboard/issues/91
  // not all ads might be attributed. This will lead to missing
  // spend. Hence, we iterate over the ads in the result
  // and fill up the missing entries.
  const unattributedResult = getUnattributedAdScores(
    rawResult,
    scopedAds,
    options
  );
  if (unattributedResult.scopedAds) {
    scopedAds = new Map([...scopedAds, ...unattributedResult.scopedAds]);
  }

  // we need this to map these unattributedAds to adsets and campaigns later
  // because these were not caught by Hive because not events have been received
  const unattributedAds = unattributedResult.unattributedAds;

  // sort ads by order count here
  scopedAds = new Map(
    [...scopedAds.entries()].sort(
      (a, b) => b[1].purchaseCount - a[1].purchaseCount
    )
  );

  const adsetids: Map<string, string[]> = new Map();
  const campaignids: Map<string, string[]> = new Map();
  // aggregate adsets
  for (const adKey of scopedAds.keys()) {
    const splitKey = adKey.split("::");

    // we only have an adset if they length here is 4 (or >3)
    // i.e. for facebook::123campaignId::123adsetId::123adId
    // if we dont have 4 elements (which also all must have a string value)
    // we typically have something like google::123perfMaxId::::adidhash
    // or referred::https://google.de::::adidhash
    // which don't hold any adsets only a campaign and an ad
    if (splitKey.length > 3 && !splitKey.includes("")) {
      const adsetKey = splitKey.slice(0, -1).join("::");
      const currentAdsetIds = adsetids?.get(adsetKey) ?? [];
      adsetids.set(adsetKey, [...currentAdsetIds, adKey]);
    }

    // only add to campaignids if there actually is an campaign id
    // exception is direct, since direct doesn't have a campaign id
    if (
      (splitKey[1] !== "" && splitKey[0] !== "direct") ||
      splitKey[0] === "direct"
    ) {
      const campaignKey = splitKey.slice(0, -2).join("::");
      const currentCampaignIds = campaignids?.get(campaignKey) ?? [];
      campaignids.set(campaignKey, [...currentCampaignIds, adKey]);
    }
  }
  rawResult.adsetids = adsetids;
  rawResult.campaignids = campaignids;

  let scopedAdsets = getBaseScoreFromAds(
    {
      result: { ...rawResult },
      scopedAds,
      type: "adset",
      unattributedAds,
    },
    options
  );

  scopedAdsets = new Map(
    [...scopedAdsets.entries()].sort(
      (a, b) => b[1].purchaseCount - a[1].purchaseCount
    )
  );

  // aggregate campaigns
  // REMARKS: In earlier versions, max campaign data from ad-connector did not have
  // a prefix campaign id (i.e. "max_{campaign_id}"). But with the introduction of
  // virtual ads this was changed to be consistent with Hive.
  // Therefore we do no longer have to remove the prefix.
  let scopedCampaigns = getBaseScoreFromAds(
    {
      result: { ...rawResult },
      scopedAds,
      scopedAdsets,
      type: "campaign",
      unattributedAds,
    },
    options
  );

  // sort campaigns by purchase count
  scopedCampaigns = new Map(
    [...scopedCampaigns.entries()].sort(
      (a, b) => b[1].purchaseCount - a[1].purchaseCount
    )
  );
  // create channel scoped maps
  for (const [scopedAdId, adScore] of scopedAds.entries()) {
    const [scope] = scopedAdId.split("::");
    const adsArr = ads?.get(scope);
    if (adsArr) adsArr.push(adScore);
    else ads.set(scope, [adScore]);
  }
  for (const [scopedAdsetId, adsetScore] of scopedAdsets.entries()) {
    const [scope] = scopedAdsetId.split("::");
    const adsetsArr = adsets?.get(scope);
    if (adsetsArr) adsetsArr.push(adsetScore);
    else adsets.set(scope, [adsetScore]);
  }
  for (const [scopedCampaignId, campaignScore] of scopedCampaigns.entries()) {
    const [scope] = scopedCampaignId.split("::");
    const campaignsArr = campaigns?.get(scope);
    if (campaignsArr) campaignsArr.push(campaignScore);
    else campaigns.set(scope, [campaignScore]);
  }

  // pack result
  const analytics = {
    ads: ads,
    adsets: adsets,
    campaigns: campaigns,
    orders: orderData,
  } as BaseAnalyticsWithOrders;
  return analytics;
};

export const getAdScoresFromAggregatedAttribution = (
  { attributions, ads }: AnalyticsResult,
  orderData: Map<string, BaseOrder>,
  options?: FQTransformOptions
) => {
  let attrIdx = 0;
  const scopedAds = new Map<string, BaseScore>(); // <- we only use this for adset / campaign grouping
  // here we go through raw result data and transform them into the more readable BaseScore view
  for (const attribution of attributions) {
    if (!attribution.aggregated) return { scopedAds, orderData };
    const correspondingAds = Array.isArray(ads) ? ads?.at(attrIdx) : ads;
    const [adInfos] = extractInfosFromAnalyticsResult(correspondingAds, {
      ads: true,
    });
    attrIdx++;

    for (const [scopedAdId, csidMap] of Object.entries(
      attribution.aggregated ?? {}
    )) {
      const { scope, adId, fqId } = splitScopedId({
        scopedId: scopedAdId,
        type: "ad",
      });

      // just in case we don't have the fq id in the ad connector data
      // we also check if there is data for the regular adId
      const adKpi =
        ads && ads[0] && ads[0].aggregated_ad_kpis
          ? ads[0].aggregated_ad_kpis[`${scope}::${fqId}`] ??
            ads[0].aggregated_ad_kpis[`${scope}::${adId}`]
          : null;

      // we normally return the adInfos with the fq-ids as the key
      // only when there is no campaign/adset id available, we return it with the normal id
      const adInfo =
        adInfos?.get(`${scope}::${fqId}`) ?? adInfos?.get(`${scope}::${adId}`);

      // initialize adScore with adKpi from ad-connector but without attribution values from Hive
      const adScore = initializeBaseScoreValue({
        baseScoreId: adId,
        fqId,
        scope: scope as ScopeType,
        adKpi,
        baseScoreInfo: adInfo,
        type: "ad",
      });
      // add attribution data from Hive to adScore
      for (const [, ordersMap] of Object.entries(csidMap)) {
        for (const [orderId, interactionMap] of Object.entries(ordersMap)) {
          if (
            scope !== "all" &&
            orderId !== "" &&
            orderData.get(orderId) === undefined
          ) {
            orderData.set(orderId, {
              ...emptyOrder,
              orderId: orderId,
              ads: [],
            });
          }
          const order =
            scope !== "all" && orderId !== "" ? orderData.get(orderId) : null;
          const adOrderScore = order
            ? { ...emptyOrder, fqId: fqId, adId: adId, provider: scope }
            : null;
          for (const [interaction, scoreMap] of Object.entries(
            interactionMap as Object
          )) {
            for (const [scoreName, scoreValue] of Object.entries(
              scoreMap as Object
            )) {
              if (scoreName === "attribution") {
                if (interaction === "pageview") {
                  adScore.pageview += scoreValue;
                  if (order) {
                    order.pageview += scoreValue;
                  }
                  if (adOrderScore) {
                    adOrderScore.pageview += scoreValue;
                  }
                } else if (interaction === "productview") {
                  adScore.productview += scoreValue;
                  if (order) {
                    order.productview += scoreValue;
                  }
                  if (adOrderScore) {
                    adOrderScore.productview += scoreValue;
                  }
                } else if (interaction === "checkout") {
                  adScore.checkout += scoreValue;
                  if (order) {
                    order.checkout += scoreValue;
                  }
                  if (adOrderScore) {
                    adOrderScore.checkout += scoreValue;
                  }
                } else if (interaction === "purchase") {
                  adScore.purchaseCount += scoreValue;
                  if (order) {
                    order.purchaseCount += scoreValue;
                  }
                  if (adOrderScore) {
                    adOrderScore.purchaseCount += scoreValue;
                  }
                } else if (interaction === "addtocart") {
                  adScore.addtocart += scoreValue;
                  if (order) {
                    order.addtocart += scoreValue;
                  }
                  if (adOrderScore) {
                    adOrderScore.addtocart += scoreValue;
                  }
                }
              } else if (scoreName === "nitems") {
                if (interaction === "purchase") {
                  adScore.purchaseCartItems += scoreValue;
                  if (order) {
                    order.purchaseCartItems += scoreValue;
                  }
                  if (adOrderScore) {
                    adOrderScore.purchaseCartItems += scoreValue;
                  }
                }
              } else if (scoreName === "amount") {
                if (interaction === "purchase") {
                  adScore.purchaseAmount += scoreValue;
                  if (order) {
                    order.purchaseAmount += scoreValue;
                  }
                  if (adOrderScore) {
                    adOrderScore.purchaseAmount += scoreValue;
                  }
                }
              }
            }
          }
          if (order && adOrderScore) {
            order.ads.push(adOrderScore);
          }
        }
      }

      adScore.roas = kpiCalculations.roas(adScore);
      adScore.frequency = kpiCalculations.frequency(adScore);
      adScore.cpm = kpiCalculations.cpm(adScore);
      adScore.cpo = kpiCalculations.cpo(adScore);
      adScore.ctr = kpiCalculations.ctr(adScore);
      adScore.thumbstopRatio = kpiCalculations.thumbstopRatio(adScore);
      adScore.cpc = kpiCalculations.cpc(adScore);
      adScore.cr = kpiCalculations.cr(adScore);
      adScore.aov = kpiCalculations.aov(adScore);
      if (options?.calculateNewCustomerScores) {
        adScore.cac = kpiCalculations.cac(adScore, options.isNewUserData);
        adScore.newCustomerRate = kpiCalculations.newCustomerRate(
          adScore,
          options.isNewUserData
        );
        adScore.newVisitorRate = kpiCalculations.newVisitorRate(
          adScore,
          options.isNewUserData
        );
      }

      const hasData = checkIfBaseScoreHasData(adScore);

      if (hasData) {
        scopedAds.set(scopedAdId, adScore);
      }
    }
  }
  return { scopedAds, orderData };
};

/**
 *
 * @param {AnalyticsResult} result Data from the result provided by Tracify endpoint /analytics/results
 * @param {TimeSettingsType} timeSettings the timesettings used for creating the request
 * @param {AnalyticsOptions} options Data from the result provided by Tracify endpoint /analytics/results
 * @return {NvrBaseAnalyticsWithOrders} returns the BaseAnalytics result for every day we got data
 */
export const transformNvrInfluencerWithOrdersAnalyticsResult = (
  result: AnalyticsResult,
  timeSettings: TimeSettingsType,
  options: AnalyticsOptions
) => {
  if (
    result.attributions?.length === 0 ||
    result.attributions[0] === null ||
    !result.attributions[0].nvr_tc_oid_aggregated
  ) {
    // return empty result
    return {} as NvrBaseAnalyticsWithOrders;
  }

  const rawResult = { ...result } as AnalyticsResult;
  const allAdsetIds = result.adsetids;
  const allCampaignIds = result.campaignids;
  const nvrAnalyticsData: NvrBaseAnalyticsWithOrders =
    {} as NvrBaseAnalyticsWithOrders;
  let index = 0;
  for (const attribution of rawResult.attributions) {
    if (!attribution.nvr_tc_oid_aggregated) {
      return {} as NvrBaseAnalyticsWithOrders;
    }
    // here we mimic the result we would get back from Hive for only the current date in
    // the aggregated view, so we can reuse the transform function
    const newDataToAggregateMap: AnalyticsResult = {
      adsetids: allAdsetIds,
      campaignids: allCampaignIds,
      totals: result.totals,
      attributions: [
        { tc_oid_aggregated: attribution.nvr_tc_oid_aggregated.new },
      ],
      ads: result.ads ? [result.ads[index]] : [],
    };
    const returningDataToAggregateMap: AnalyticsResult = {
      adsetids: allAdsetIds,
      campaignids: allCampaignIds,
      totals: result.totals,
      attributions: [
        { tc_oid_aggregated: attribution.nvr_tc_oid_aggregated.returning },
      ],
      ads: result.ads ? [result.ads[index]] : [],
    };
    const allAttributions = joinNvrInfluencerWithOrdersAttributionData(
      attribution.nvr_tc_oid_aggregated
    );
    const allDataToAggregateMap: AnalyticsResult = {
      adsetids: allAdsetIds,
      campaignids: allCampaignIds,
      totals: result.totals,
      attributions: [{ tc_oid_aggregated: allAttributions }],
      ads: result.ads ? [result.ads[index]] : [],
    };
    const newData = transformInfluencerWithOrdersResult(
      newDataToAggregateMap,
      timeSettings,
      { ...options, calculateNewCustomerScores: true, isNewUserData: true }
    );

    const returningData = transformInfluencerWithOrdersResult(
      returningDataToAggregateMap,
      timeSettings,
      { ...options, forceCalculateNvr: true }
    );
    const allData = transformInfluencerWithOrdersResult(
      allDataToAggregateMap,
      timeSettings,
      { ...options, calculateNewCustomerScores: true }
    );

    // add the purchaseCount and cac to allData, so we can display that in our tables
    if (newData && allData) {
      addCacDataToNvrAll(newData, allData);
      addAllDataToNvrNew(newData, allData);
      addAllDataToNvrReturning(returningData, allData);
    }
    if (newData) {
      nvrAnalyticsData.new = newData;
    }
    if (returningData) {
      nvrAnalyticsData.returning = returningData;
    }
    if (allData) {
      nvrAnalyticsData.all = allData;
    }

    index += 1;
  }
  return nvrAnalyticsData;
};

/**
 *
 * @param {AnalyticsResult} result Data from the result provided by Tracify endpoint /analytics/results
 * @param {TimeSettingsType} timeSettings the timesettings used for creating the request
 * @param {AnalyticsOptions} options Options for the influencer mapping
 * @return {NvrDailyBaseAnalyticsWithOrders} returns the BaseAnalytics result for every day we got data
 */
export const transformNvrDailyInfluencerWithOrdersAnalyticsResult = (
  result: AnalyticsResult,
  timeSettings: TimeSettingsType,
  options: AnalyticsOptions
) => {
  if (
    result.attributions?.length === 0 ||
    result.attributions[0] === null ||
    !result.attributions[0].nvr_tc_oid_daily
  ) {
    // return empty result
    return {} as NvrDailyBaseAnalyticsWithOrders;
  }

  const rawResult = { ...result } as AnalyticsResult;
  const allAdsetIds = result.adsetids;
  const allCampaignIds = result.campaignids;
  const nvrDailyAnalyticsData: NvrDailyBaseAnalyticsWithOrders = {};
  let index = 0;
  for (const attribution of rawResult.attributions) {
    if (!attribution.nvr_tc_oid_daily) {
      return {} as NvrDailyBaseAnalyticsWithOrders;
    }
    const diff = dayjs(timeSettings.endTime).diff(
      dayjs(timeSettings.startTime),
      "day"
    );
    let addedDatesManually = false;
    for (let i = 0; i <= diff; i++) {
      const day = dayjs(timeSettings.startTime)
        .utcOffset(timeSettings.utcOffset ?? 0)
        .add(i, "day")
        .format("YYYY-MM-DD");
      if (!attribution.nvr_tc_oid_daily[day]) {
        addedDatesManually = true;
        attribution.nvr_tc_oid_daily[day] = {
          new: { "": { "": {} } },
          returning: { "": { "": {} } },
        };
      }
    }
    if (addedDatesManually) {
      // obj keys need to be sorted for the daily analytics (graphs) to work
      const sortedArray = Object.entries(attribution.nvr_tc_oid_daily).sort(
        ([key1], [key2]) => dayjs(key1).unix() - dayjs(key2).unix()
      );
      attribution.nvr_tc_oid_daily = Object.fromEntries(sortedArray);
    }
    for (const [attributionDay, attributionData] of Object.entries(
      attribution.nvr_tc_oid_daily
    )) {
      // here we get the dates back in the format "YYYY-MM-DD" and we want to
      // transform that to the request timezone in UTC by adding the utcOffset
      // to be able to check if the start and end date are the same as in the current local day
      const attributionDate = timeSettings.utcOffset
        ? dayjs(attributionDay).utcOffset(timeSettings.utcOffset, true)
        : dayjs(attributionDay);

      const isInTimeRange =
        attributionDate.isAfter(
          dayjs(timeSettings.startTime).subtract(1, "second"), // subtract 1 second to avoid having to check for same & after
          "second"
        ) &&
        attributionDate.isBefore(
          dayjs(timeSettings.endTime).add(1, "second"), // add 1 second to avoid having to check for same & before
          "second"
        );

      // only if the date is in the selected timerange, we want to add its result to our analytics data
      if (isInTimeRange) {
        const dailyAggregatedAdKpis: {
          [key: string]: { [key: string]: number };
        } = {};

        if (result?.ads && result?.ads[index]?.ad_kpis) {
          // quick fix for summer time changes where ad connector dates are outside of current date
          // to fix this, we add the offset diff to the adkpi date below
          const startOffset = dayjs(timeSettings.startTime)
            .tz(timeSettings.timezone)
            .utcOffset();
          const offsetDiff = startOffset / 60 - (timeSettings.utcOffset ?? 0);

          // for every adId we get an array of ad kpis for every date in the timerange (when available)
          for (const [adId, adKpis] of Object.entries(
            result?.ads[index]?.ad_kpis ?? {}
          )) {
            for (const adKpi of adKpis) {
              // here we get the date in UTC format YYYY-MM-DD HH:mm:ss in the accounts
              // timezone in the respective ads manager (fb, google, tiktok)
              // since we can only get date for the account in this timezone,
              // we can also only check if the end of the date in the accounts timezone
              // is at least the same day and add these kpi to this date then
              const adKpiDate = dayjs(adKpi.date)
                .utc(true)
                .add(offsetDiff, "hours");

              // check if date is between start and end of date because we
              // sometimes also have ads that have a different timestamp
              // (i.e. different timezone in ads account) and might be a different date in utc
              const dateBoolean =
                adKpiDate.isAfter(attributionDate.subtract(1, "second")) &&
                adKpiDate.isBefore(
                  attributionDate.add(1, "day").subtract(1, "millisecond")
                );

              if (dateBoolean) {
                dailyAggregatedAdKpis[adId] = adKpi;
              }
            }
          }
        }

        // here we mimic the result we would get back from Hive for only the current date in
        // the aggregated view, so we can reuse the transform function
        const newDailyDataToAggregateMap: AnalyticsResult = {
          adsetids: allAdsetIds,
          campaignids: allCampaignIds,
          totals: result.totals,
          attributions: [{ tc_oid_aggregated: attributionData.new }],
          ads: result.ads
            ? [
                {
                  timespantype: result.ads[index].timespantype,
                  aggregated_ad_kpis: dailyAggregatedAdKpis,
                  ad_infos: result.ads[index].ad_infos,
                  adset_infos: result.ads[index].adset_infos,
                  campaign_infos: result.ads[index].campaign_infos,
                },
              ]
            : [],
        };
        // here we mimic the result we would get back from Hive for only the current date in
        // the aggregated view, so we can reuse the transform function
        const returningDailyDataToAggregateMap: AnalyticsResult = {
          adsetids: allAdsetIds,
          campaignids: allCampaignIds,
          totals: result.totals,
          attributions: [{ tc_oid_aggregated: attributionData.returning }],
          ads: result.ads
            ? [
                {
                  timespantype: result.ads[index].timespantype,
                  aggregated_ad_kpis: dailyAggregatedAdKpis,
                  ad_infos: result.ads[index].ad_infos,
                  adset_infos: result.ads[index].adset_infos,
                  campaign_infos: result.ads[index].campaign_infos,
                },
              ]
            : [],
        };

        // we want to join both the new and returning data for properly displaying the total amounts
        // without counting the ad-connector data twice
        const allAttributions =
          joinNvrInfluencerWithOrdersAttributionData(attributionData);

        const allDailyDataToAggregateMap: AnalyticsResult = {
          adsetids: allAdsetIds,
          campaignids: allCampaignIds,
          totals: result.totals,
          attributions: [{ tc_oid_aggregated: allAttributions }],
          ads: result.ads
            ? [
                {
                  timespantype: result.ads[index].timespantype,
                  aggregated_ad_kpis: dailyAggregatedAdKpis,
                  ad_infos: result.ads[index].ad_infos,
                  adset_infos: result.ads[index].adset_infos,
                  campaign_infos: result.ads[index].campaign_infos,
                },
              ]
            : [],
        };

        const newDailyData = transformInfluencerWithOrdersResult(
          newDailyDataToAggregateMap,
          {
            ...timeSettings,
            endTime: attributionDate.endOf("day").toISOString(),
            startTime: attributionDate.startOf("day").toISOString(),
          },
          { ...options, calculateNewCustomerScores: true, isNewUserData: true }
        );
        const returningDailyData = transformInfluencerWithOrdersResult(
          returningDailyDataToAggregateMap,
          {
            ...timeSettings,
            endTime: attributionDate.endOf("day").toISOString(),
            startTime: attributionDate.startOf("day").toISOString(),
          },
          { ...options, forceCalculateNvr: true }
        );
        const allDailyData = transformInfluencerWithOrdersResult(
          allDailyDataToAggregateMap,
          {
            ...timeSettings,
            endTime: attributionDate.endOf("day").toISOString(),
            startTime: attributionDate.startOf("day").toISOString(),
          },
          { ...options, calculateNewCustomerScores: true }
        );
        // add the purchaseCount and cac to allData, so we can display that in our tables
        if (newDailyData && allDailyData) {
          addCacDataToNvrAll(newDailyData, allDailyData);
          addAllDataToNvrNew(newDailyData, allDailyData);
          addAllDataToNvrReturning(returningDailyData, allDailyData);
        }

        if (nvrDailyAnalyticsData[attributionDay] && newDailyData) {
          nvrDailyAnalyticsData[attributionDay].new = {
            ...nvrDailyAnalyticsData[attributionDay].new,
            ...newDailyData,
          };
        } else if (newDailyData) {
          nvrDailyAnalyticsData[attributionDay] = { new: newDailyData };
        }

        if (nvrDailyAnalyticsData[attributionDay] && returningDailyData) {
          nvrDailyAnalyticsData[attributionDay].returning = {
            ...nvrDailyAnalyticsData[attributionDay].returning,
            ...returningDailyData,
          };
        } else if (returningDailyData) {
          nvrDailyAnalyticsData[attributionDay] = {
            returning: returningDailyData,
          };
        }
        if (nvrDailyAnalyticsData[attributionDay] && allDailyData) {
          nvrDailyAnalyticsData[attributionDay].all = {
            ...nvrDailyAnalyticsData[attributionDay].all,
            ...allDailyData,
          };
        } else if (allDailyData) {
          nvrDailyAnalyticsData[attributionDay] = {
            all: allDailyData,
          };
        }
      }
    }
    index += 1;
  }
  return nvrDailyAnalyticsData;
};

export const joinNvrInfluencerWithOrdersAttributionData = (
  attributionData: NvrInfluencerAttributionDataWithOrders
) => {
  const newData = attributionData?.new;
  const returningData = attributionData?.returning;

  // in these cases we don't have to join anything
  if (!newData && !returningData) return {};
  if (!newData) return returningData;
  if (!returningData) return newData;
  const allData: InfluencerAttributionDataWithOrders = structuredClone(newData);

  for (const [clid, clResults] of Object.entries(returningData)) {
    for (const [discountCode, values] of Object.entries(clResults)) {
      for (const [fqid, value] of Object.entries(values)) {
        // if we have data for this exact fqid, we have to add the returning data to it
        if (
          allData[clid] &&
          allData[clid][discountCode] &&
          allData[clid][discountCode][fqid]
        ) {
          for (const [csid, ordersMap] of Object.entries(value)) {
            if (!allData[clid][discountCode][fqid][csid]) {
              allData[clid][discountCode][fqid] = {
                ...allData[clid][discountCode][fqid],
                [csid]: { ...ordersMap },
              };
            } else {
              for (const [orderId, interactionMap] of Object.entries(
                ordersMap
              )) {
                if (!allData[clid][discountCode][fqid][csid][orderId]) {
                  allData[clid][discountCode][fqid][csid] = {
                    ...allData[clid][discountCode][fqid][csid],
                    [orderId]: { ...interactionMap },
                  };
                } else {
                  for (const [interaction, scoreMap] of Object.entries(
                    interactionMap
                  )) {
                    for (const [scoreName, scoreValue] of Object.entries(
                      scoreMap
                    )) {
                      if (scoreName === "attribution") {
                        if (interaction === "pageview") {
                          const currentValue =
                            allData[clid][discountCode][fqid][csid][orderId]
                              .pageview?.attribution ?? 0;
                          allData[clid][discountCode][fqid][csid][
                            orderId
                          ].pageview = {
                            attribution: currentValue + scoreValue,
                          };
                        } else if (interaction === "productview") {
                          const currentValue =
                            allData[clid][discountCode][fqid][csid][orderId]
                              .productview?.attribution ?? 0;
                          allData[clid][discountCode][fqid][csid][
                            orderId
                          ].productview = {
                            attribution: currentValue + scoreValue,
                          };
                        } else if (interaction === "purchase") {
                          const currentValue =
                            allData[clid][discountCode][fqid][csid][orderId]
                              .purchase?.attribution ?? 0;
                          const nitemsValue =
                            allData[clid][discountCode][fqid][csid][orderId]
                              .purchase?.nitems ?? 0;
                          const amountValue =
                            allData[clid][discountCode][fqid][csid][orderId]
                              .purchase?.amount ?? 0;

                          allData[clid][discountCode][fqid][csid][
                            orderId
                          ].purchase = {
                            attribution: currentValue + scoreValue,
                            nitems: nitemsValue,
                            amount: amountValue,
                          };
                        } else if (interaction === "addtocart") {
                          const currentValue =
                            allData[clid][discountCode][fqid][csid][orderId]
                              .addtocart?.attribution ?? 0;
                          allData[clid][discountCode][fqid][csid][
                            orderId
                          ].addtocart = {
                            attribution: currentValue + scoreValue,
                          };
                        }
                      } else if (scoreName === "nitems") {
                        if (interaction === "purchase") {
                          const attributionValue =
                            allData[clid][discountCode][fqid][csid][orderId]
                              .purchase?.attribution ?? 0;
                          const nitemsValue =
                            allData[clid][discountCode][fqid][csid][orderId]
                              .purchase?.nitems ?? 0;
                          const amountValue =
                            allData[clid][discountCode][fqid][csid][orderId]
                              .purchase?.amount ?? 0;

                          allData[clid][discountCode][fqid][csid][
                            orderId
                          ].purchase = {
                            attribution: attributionValue,
                            nitems: nitemsValue + scoreValue,
                            amount: amountValue,
                          };
                        }
                      } else if (scoreName === "amount") {
                        if (interaction === "purchase") {
                          const attributionValue =
                            allData[clid][discountCode][fqid][csid][orderId]
                              .purchase?.attribution ?? 0;
                          const nitemsValue =
                            allData[clid][discountCode][fqid][csid][orderId]
                              .purchase?.nitems ?? 0;
                          const amountValue =
                            allData[clid][discountCode][fqid][csid][orderId]
                              .purchase?.amount ?? 0;
                          allData[clid][discountCode][fqid][csid][
                            orderId
                          ].purchase = {
                            attribution: attributionValue,
                            nitems: nitemsValue,
                            amount: amountValue + scoreValue,
                          };
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        } else if (allData[clid] && allData[clid][discountCode]) {
          allData[clid][discountCode][fqid] = { ...value };
        } else if (allData[clid]) {
          allData[clid][discountCode] = { [fqid]: { ...value } };
        } else {
          allData[clid] = { [discountCode]: { [fqid]: { ...value } } };
        }
      }
    }
  }
  return allData;
};
