import React from "react";
import { useAtom } from "jotai";
import {
  attributionPresetAtom,
  selectedMarketingChannelsAtom,
  attributionWindowAtom,
  attributionModelAtom,
  reportTimeAtom,
} from "@/atoms";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import useSelectedStores from "@lib/hooks/use-selected-stores";
import { usePresets } from "@lib/api-hooks/usePresets/usePresets";
import { mapReportTime } from "@lib/api-hooks/usePresets/transformations";
import { mapAttributionModel } from "@lib/api-hooks/usePresets/transformations";
import { AVAILABLE_MARKETING_CHANNEL_OPTIONS } from "@/constants/constants";

const AttributionPresetMenu = () => {
  const { selectedOrganisation } = useSelectedStores();
  const { data: presets = [] } = usePresets(selectedOrganisation ?? "", {
    enabled: Boolean(selectedOrganisation),
  });

  const availablePresets = presets?.filter(
    (preset) => preset.status === "READY" || preset.status === "REPROCESSING"
  );
  const [attributionPreset, setAttributionPreset] = useAtom(
    attributionPresetAtom
  );
  const [selectedMarketingChannels, setSelectedMarketingChannels] = useAtom(
    selectedMarketingChannelsAtom
  );
  const [attributionWindow, setAttributionWindow] = useAtom(
    attributionWindowAtom
  );
  const [attributionModel, setAttributionModel] = useAtom(attributionModelAtom);
  const [reportTime, setReportTime] = useAtom(reportTimeAtom);

  const onChangePreset = (value: string) => {
    const preset = availablePresets?.find((el) => el.presetId === value);
    if (preset) {
      setAttributionPreset(value);
      preset.attributionWindow
        ? setAttributionWindow(
            `${preset.attributionWindow}` as typeof attributionWindow
          )
        : null;
      setAttributionModel(mapAttributionModel(preset.presetType));
      setReportTime(mapReportTime(preset.reportEventTime));
      setSelectedMarketingChannels(
        preset.touchpoints.includes("all_touchpoints")
          ? AVAILABLE_MARKETING_CHANNEL_OPTIONS.map((el) => el.value)
          : preset.touchpoints
      );
    }
  };

  return (
    <Select value={attributionPreset} onValueChange={onChangePreset}>
      <SelectTrigger
        className="w-fit max-w-96 bg-gray-200 dark:bg-gray-700 relative"
        id="attribution-preset-select"
      >
        <SelectValue placeholder="Choose a preset" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Presets</SelectLabel>
          {availablePresets?.map((option) => (
            <SelectItem key={option.presetId} value={option.presetId}>
              {option.name}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

export default AttributionPresetMenu;
