import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useCurrencyRates } from "@lib/api-hooks/useCurrencyRates";
import { useLocalStorage } from "@lib/hooks/use-local-storage";
import useSelectedStores from "@lib/hooks/use-selected-stores";
import { currencyModalStatusAtom } from "atoms";
import { useAtom } from "jotai";
import { availableCurrencies, Currency, STORAGE_KEYS } from "./constants";
import useLocale from "./use-locale";

interface LocalStorageCurrency {
  id: string;
  currency: string;
}

interface localStorageBlendingCurrency {
  [key: string]: string;
}

function useCurrency() {
  const [, setCurrencyModal] = useAtom(currencyModalStatusAtom);
  const { selectedStoreIds, selectedOrganisationData } = useSelectedStores();

  const [storesData, setStoresData] = useLocalStorage<LocalStorageCurrency[]>(
    STORAGE_KEYS.STORE_CURRENCY,
    {
      usingJson: true,
    }
  );

  const [blendingStoresData, setBlendingStoresData] =
    useLocalStorage<localStorageBlendingCurrency>(
      STORAGE_KEYS.BLENDING_CURRENCY,
      {
        usingJson: true,
      }
    );

  const { data } = useCurrencyRates();

  const { currentLocale } = useLocale();

  const baseCurrency = data?.base || "EUR";

  const availableCurrenciesWithRate = useMemo(() => {
    const currencyRates = data?.rates || {};

    return availableCurrencies.map((el) => ({
      ...el,
      multiplier: currencyRates[el.short] || 1,
    }));
  }, [data?.rates]);

  const updateSingleStoreCurrency = useCallback(
    (storeId: string, shortCurrency: string) => {
      if (!storesData?.some((el) => el.id === storeId)) {
        setStoresData((prev) => [
          ...(prev ?? []),
          { id: storeId, currency: shortCurrency },
        ]);
      }
    },
    [storesData, setStoresData]
  );

  const updateGlobalCurrency = useCallback(
    (shortCurrency: string) => {
      if (!selectedOrganisationData) return;

      setBlendingStoresData((prev) => ({
        ...prev,
        [selectedOrganisationData.id]: shortCurrency,
      }));
    },
    [selectedOrganisationData, setBlendingStoresData]
  );

  const isBlendingCurrencyConflicted = useMemo(() => {
    if (selectedStoreIds.length > 1) {
      const selectedStoreShortCurrencies = selectedStoreIds.reduce(
        (acc, storeId) => {
          const currency = storesData?.find((el) => el.id === storeId)
            ?.currency;
          if (currency) {
            acc.push(currency);
          }
          return acc;
        },
        [] as string[]
      );

      const conflicted = !selectedStoreShortCurrencies.every(
        (short) => short === selectedStoreShortCurrencies[0]
      );

      return conflicted;
    }
  }, [selectedStoreIds, storesData]);

  const commonCurrency = useMemo(() => {
    const selectedStoreShortCurrencies = selectedStoreIds.reduce(
      (acc, storeId) => {
        const currency = storesData?.find((el) => el.id === storeId)?.currency;
        if (currency) {
          acc.push(currency);
        }
        return acc;
      },
      [] as string[]
    );

    const commonCurrency = selectedStoreShortCurrencies.every(
      (cur) => cur === selectedStoreShortCurrencies[0]
    )
      ? selectedStoreShortCurrencies[0]
      : undefined;

    return commonCurrency;
  }, [selectedStoreIds, storesData]);

  const changeStoreCurrency = useCallback(
    (storeId: string, currencyCode: string) => {
      const currencyElement = availableCurrencies.find(
        (el) => el.short === currencyCode
      );

      if (!currencyElement) {
        return;
      }

      const existingEntry = storesData?.find((el) => el.id === storeId);
      if (existingEntry) {
        setStoresData((prev) => {
          if (!prev) return prev;
          return prev.map((el) => {
            if (el.id === storeId) {
              return {
                ...el,
                currency: currencyCode,
              };
            }
            return el;
          });
        });
      } else {
        setStoresData((prev) => [
          ...(prev ?? []),
          { id: storeId, currency: currencyCode },
        ]);
      }
    },
    [storesData, setStoresData]
  );

  const blendingCurrencyFromLocalStorage = useMemo(() => {
    if (!selectedOrganisationData || !blendingStoresData) {
      return undefined;
    }

    return blendingStoresData[selectedOrganisationData.id];
  }, [selectedOrganisationData, blendingStoresData]);

  const globalCurrency = useMemo(() => {
    if (selectedStoreIds.length > 1) {
      return blendingCurrencyFromLocalStorage || commonCurrency || baseCurrency;
    } else {
      const storeCurrency = storesData?.find(
        (el) => el.id === selectedStoreIds[0]
      );
      return storeCurrency?.currency || baseCurrency;
    }
  }, [
    baseCurrency,
    blendingCurrencyFromLocalStorage,
    commonCurrency,
    selectedStoreIds,
    storesData,
  ]);

  const globalCurrencySymbol = useMemo(() => {
    const currencyElement = availableCurrencies.find(
      (el) => el.short === globalCurrency
    );
    if (currencyElement) {
      return currencyElement.value;
    }
    return baseCurrency;
  }, [globalCurrency, baseCurrency]);

  const calculateCurrencyConvertion = useCallback(
    (
      value: number | undefined,
      numDigits: number,
      displaySymbol: boolean = true
    ) => {
      const currency = availableCurrenciesWithRate.find(
        (el) => el.short === globalCurrency
      );
      const currencyMultiplier = currency?.multiplier || 1;
      const convertedValue = value ? value * (1 / currencyMultiplier) : 0;

      const symbol = displaySymbol ? globalCurrencySymbol : "";
      const returnString =
        currentLocale === "en-US"
          ? `${symbol}${
              currency?.alwaysAddSpace ? " " : ""
            }${convertedValue.toLocaleString(currentLocale, {
              maximumFractionDigits: numDigits,
              minimumFractionDigits: numDigits,
            })}`
          : `${convertedValue.toLocaleString(currentLocale, {
              maximumFractionDigits: numDigits,
              minimumFractionDigits: numDigits,
            })} ${symbol}`;

      return returnString;
    },
    [
      globalCurrency,
      availableCurrenciesWithRate,
      currentLocale,
      globalCurrencySymbol,
    ]
  );

  const getCurrencyShortAndSymbolPerStoreId = useCallback(
    (storeId: string) => {
      const currentCurrency =
        storesData?.find((el) => el.id === storeId)?.currency || baseCurrency;
      const currencyElement = availableCurrencies.find(
        (el) => el.short === currentCurrency
      );

      return `${currencyElement?.short} (${currencyElement?.value})`;
    },
    [baseCurrency, storesData]
  );

  const getValueFromGlobalCurrency = useCallback(
    (value: number) => {
      const currency = availableCurrenciesWithRate.find(
        (el) => el.short === globalCurrency
      );
      const currencyMultiplier = currency?.multiplier || 1;
      const convertedValue = value ? value * (1 / currencyMultiplier) : 0;

      return convertedValue;
    },
    [globalCurrency, availableCurrenciesWithRate]
  );

  const getValueToBaseCurrency = useCallback(
    (value: number) => {
      const currentGlobalCurrency = availableCurrenciesWithRate.find(
        (el) => el.short === globalCurrency
      );

      const convertedValue = value
        ? value * currentGlobalCurrency?.multiplier!
        : 0;
      return convertedValue;
    },
    [globalCurrency, availableCurrenciesWithRate]
  );

  const checkExtraSpace = (symbol: string) => {
    return (
      availableCurrencies.find((el) => el.short === symbol)?.alwaysAddSpace ||
      false
    );
  };

  const convertToGlobalCurrency = useCallback(
    (value: number, fromCurrency: string) => {
      if (fromCurrency === globalCurrency) {
        return calculateCurrencyConvertion(value, 2);
      }

      const fromRate =
        availableCurrenciesWithRate.find((el) => el.short === fromCurrency)
          ?.multiplier || 1;
      const toRate =
        availableCurrenciesWithRate.find((el) => el.short === globalCurrency)
          ?.multiplier || 1;

      // Convert to base currency first, then to target currency
      const convertedValue = value * (fromRate / toRate);

      return calculateCurrencyConvertion(convertedValue, 2);
    },
    [globalCurrency, availableCurrenciesWithRate, calculateCurrencyConvertion]
  );

  useEffect(() => {
    if (isBlendingCurrencyConflicted && !blendingCurrencyFromLocalStorage) {
      setCurrencyModal(true);
      updateGlobalCurrency(baseCurrency);
    }
  }, [
    setCurrencyModal,
    selectedStoreIds,
    isBlendingCurrencyConflicted,
    blendingCurrencyFromLocalStorage,
    baseCurrency,
    updateGlobalCurrency,
  ]);

  useEffect(() => {
    selectedStoreIds.forEach((storeId) => {
      updateSingleStoreCurrency(storeId, baseCurrency);
    });
  }, [selectedStoreIds, baseCurrency, updateSingleStoreCurrency]);

  return {
    globalCurrency,
    availableCurrenciesWithRate,
    setCurrency: changeStoreCurrency,
    calculateCurrencyConvertion,
    convertToGlobalCurrency,
    globalCurrencySymbol,
    getCurrencyShortAndSymbolPerStoreId,
    blendingCurrencyFromLocalStorage,
    updateGlobalCurrency,
    getValueFromGlobalCurrency,
    getValueToBaseCurrency,
    checkExtraSpace,
  };
}

export default useCurrency;
