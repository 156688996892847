import { AVAILABLE_MARKETING_CHANNEL_OPTIONS } from "@/constants/constants";
import { PresetAPIResponse, Preset } from "./types";

export const mapAttributionModel = (type: PresetAPIResponse["preset_type"]) => {
  switch (type) {
    case "last_click":
      return "last";
    case "first_click":
      return "first";
    case "linear":
      return "linear";
    case "ushape":
      return "ushape";
    case "ai":
      return "dda_stcb_purchaseatd_t3";
  }
};

export const mapReportTime = (type: PresetAPIResponse["report_event_time"]) => {
  switch (type) {
    case "click_based":
      return "adview";
    case "event_based":
      return "event";
  }
};
export const mapAttributionModelToPreset = (type: string) => {
  switch (type) {
    case "last":
      return "last_click";
    case "first":
      return "first_click";
    case "linear":
      return "linear";
    case "ushape":
      return "ushape";
    case "dda_stcb_purchaseatd_t3":
      return "ai";
    default:
      return "ai";
  }
};

export const mapReportTimeToPreset = (type: string) => {
  switch (type) {
    case "adview":
      return "click_based";
    case "event":
      return "event_based";
    default:
      return "event_based";
  }
};

export const mapAttributionWindowToPreset = (type: string | undefined) => {
  return type === "unlimited" || type === undefined
    ? undefined
    : parseInt(type);
};

export const mapTouchpointsToPreset = (touchpoints: string[]) => {
  const newTouchpoints = touchpoints.map((el) =>
    el === "facebook" ? "meta" : el
  );
  if (newTouchpoints.length === AVAILABLE_MARKETING_CHANNEL_OPTIONS.length)
    return ["all_touchpoints"];

  if (newTouchpoints.includes("referred"))
    return [...newTouchpoints, "referral"];

  return newTouchpoints;
};

export const transformPreset = (preset: PresetAPIResponse): Preset => {
  return {
    presetType: preset.preset_type,
    reportEventTime: preset.report_event_time,
    touchpoints: preset.touchpoints,
    attributionWindow: preset.attribution_window,
    status: preset.status,
    lastSetToProcessingAt: preset.last_set_to_processing_at,
    lastSetToReadyAt: preset.last_set_to_ready_at,
    statusPerSite: {
      [preset.site]: preset.status,
    },
    sites: [preset.site],
    isDefault: preset.is_default,
    name: preset.name,
    presetId: preset.preset_id,
    description: preset.description,
    ushapeBorder: preset.ushape_border,
    createdAt: preset.created_at,
    updatedAt: preset.updated_at,
  };
};

const mergePresetStatus = (
  current: PresetAPIResponse["status"],
  newStatus: PresetAPIResponse["status"]
) => {
  if (current === "READY" && newStatus !== "READY") {
    return newStatus;
  }
  if (current === "REGISTERED" && newStatus === "PROCESSING") {
    return newStatus;
  }
  if (current === "REGISTERED" && newStatus === "REPROCESSING") {
    return newStatus;
  }
  if (current === "REPROCESSING" && newStatus === "PROCESSING") {
    return newStatus;
  }
  return current;
};

export const transformPresets = (presets: PresetAPIResponse[]): Preset[] => {
  const transformedPresets: Preset[] = [];

  for (const preset of presets) {
    const existingPreset = transformedPresets.find(
      (p) => p.presetId === preset.preset_id
    );

    if (existingPreset) {
      existingPreset.sites.push(preset.site);
      existingPreset.statusPerSite[preset.site] = preset.status;
      existingPreset.status = mergePresetStatus(
        existingPreset.status,
        preset.status
      );
    } else {
      transformedPresets.push(transformPreset(preset));
    }
  }
  return transformedPresets;
};
