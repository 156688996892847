import React, { useEffect, useMemo } from "react";
import { BookOpenIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { coreSystemAtom, newVsReturningAtom } from "atoms";
import { NVR_OPTIONS, V2_NVR_OPTIONS } from "constants/constants";
import { useAtom } from "jotai";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
} from "@components/ui/select";

const NvrDropdown: React.FC = () => {
  const [newVsReturning, setNewVsReturning] = useAtom(newVsReturningAtom);
  const [coreSystem, setCoreSystem] = useAtom(coreSystemAtom);

  useEffect(() => {
    if (coreSystem === "v2" && newVsReturning === "default") {
      setNewVsReturning("all");
    }
  }, [coreSystem, newVsReturning, setNewVsReturning]);

  const dropdownOptions = useMemo(() => {
    if (coreSystem === "v2") {
      return V2_NVR_OPTIONS;
    } else {
      return NVR_OPTIONS;
    }
  }, [coreSystem]);
  return (
    <Select
      value={newVsReturning}
      onValueChange={(val: typeof newVsReturning) => setNewVsReturning(val)}
    >
      <SelectTrigger
        className="bg-gray-200 dark:bg-gray-700"
        id="nvr-select-trigger"
      >
        <span className="truncate font-semibold mr-2 inline-flex items-center">
          <UserGroupIcon className="h-4 w-4 inline-block mr-2" />{" "}
          {(
            dropdownOptions.find((el) => el.value === newVsReturning)?.label ??
            "All Users"
          ).replace("Users", "")}
        </span>
      </SelectTrigger>
      <SelectContent className="relative max-w-[270px]">
        <SelectGroup className="space-y-1">
          <SelectLabel>User Segment</SelectLabel>
          {dropdownOptions.map((option, index) => (
            <>
              <SelectItem key={option.value} value={option.value}>
                <div className="text-sm font-medium leading-tight">
                  {option.title}
                </div>
                <div className="mt-1 text-xs font-normal leading-none">
                  {option.description}
                </div>
              </SelectItem>
              {index === 0 && coreSystem !== "v2" ? <SelectSeparator /> : null}
            </>
          ))}
        </SelectGroup>
        <SelectGroup>
          <SelectSeparator />
          <SelectLabel className="py-3 -m-1 font-normal rounded-t-none bg-accent/50 flex items-center">
            <BookOpenIcon className="h-4 w-4" />
            <a
              href="https://knowledge.tracify.ai/en/articles/32101-what-is-user-segmentation-new-vs-returning-users-customers"
              className="underline ml-1 text-xs"
              target="_blank"
            >
              Learn more about user segmentation
            </a>
          </SelectLabel>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

export default NvrDropdown;
