/* eslint-disable @next/next/no-img-element */
import React from "react";
import { Button } from "@/components/ui/button";
import { DialogHeader } from "@components/ui/dialog";
import { DialogTitle } from "@radix-ui/react-dialog";
import Image from "next/image";

interface Step1ContentProps {
  onNext: () => void;
}

export function Step1Content({ onNext }: Step1ContentProps) {
  return (
    <>
      <DialogHeader className="overflow-hidden h-[337.6px]">
        <div className="mx-auto rounded-xl overflow-hidden">
          <img
            alt="logo"
            src={"/tracify-v2.webp"}
            width={600}
            height={337.6}
            className="scale-105 rounded-xl"
          />
        </div>
      </DialogHeader>
      <div className="space-y-6 flex flex-col">
        <div className="grid grid-cols-[1fr,2.25fr] gap-4">
          <h4 className="text-xl font-semibold">
            Introducing Tracify 2.0 - Built for Speed
          </h4>
          <div className="grid space-y-4">
            <p>Stay ahead with faster insights and unlimited date ranges.</p>
            <ul className="list-disc pl-5 space-y-2">
              <li>
                <b>Lightning-fast Loading:</b> Get your insights rapidly with
                our fastest dashboards yet
              </li>
              <li>
                <b>Unlimited History:</b> Explore your entire data history -
                without limits
              </li>
              <li>
                <b>Attribution Presets:</b> New shortcuts that make it easy to
                access your preferred attribution settings with a single click
              </li>
            </ul>
            <p>
              Next up: We&apos;ll guide you to set up your preferred attribution
              settings as saved presets.
            </p>
          </div>
        </div>
        <Button className="self-end" onClick={onNext}>
          Continue
        </Button>
      </div>
    </>
  );
}
