import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { z } from "zod";
import { useAuth } from "../useAuth";
import { transformPresets } from "./transformations";
import { Preset } from "./types";
import { posthogFeatureFlagsAtom } from "@/atoms/store";
import { coreSystemAtom } from "@/atoms/store";
import { useAtom } from "jotai";

export const FetchPresetsSchema = z.object({
  token: z.string({ required_error: "User Session not available" }),
  organisationId: z.string({ required_error: "Organisation ID is required" }),
});

export type FetchPresetsProps = z.infer<typeof FetchPresetsSchema>;

async function fetchPresets({
  token,
  organisationId,
}: FetchPresetsProps): Promise<Preset[]> {
  if (!token) {
    throw new Error("User Session not available");
  }

  try {
    FetchPresetsSchema.parse({ token, organisationId });
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_TRACIFY_API_BASE_URL}/pipeline_manager/presets/${organisationId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": token,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error(response.data.error);
    }

    return transformPresets(response.data ?? []);
  } catch (err: any) {
    return [];
    // if (axios.isAxiosError(err)) {
    //   if (!err.response) {
    //     throw err;
    //   }
    //   const axiosError = err as AxiosError;
    //   const apiError = axiosError.response?.data as AdConnectorAPIError;
    //   const firstErr = apiError.detail[0];
    //   throw new Error(firstErr?.msg ?? axiosError.message);
    // }
    // throw new Error("Unknown error occurred!");
  }
}

export interface UsePresetsOptions {
  enabled?: boolean;
  onSuccess?: ((data: Preset[]) => void) | undefined;
}

export function usePresets(
  organisationId: string,
  options?: UsePresetsOptions
) {
  const { data: authData } = useAuth();
  const [posthogFeatureFlags, setPosthogFeatureFlags] = useAtom(
    posthogFeatureFlagsAtom
  );
  const [coreSystem, setCoreSystem] = useAtom(coreSystemAtom);

  const featureFlagsEnabled = Boolean(posthogFeatureFlags?.tracify_v2?.enabled);
  const enabled =
    options?.enabled !== undefined
      ? options.enabled &&
        !!authData?.token &&
        Boolean(organisationId) &&
        featureFlagsEnabled
      : !!authData?.token && Boolean(organisationId) && featureFlagsEnabled;

  return useQuery({
    queryKey: ["presets", organisationId],
    queryFn: () =>
      fetchPresets({
        token: authData?.token!,
        organisationId,
      }),
    ...options,
    staleTime: 5,
    gcTime: 5,
    refetchOnWindowFocus: false,
    enabled,
  });
}
