import { TableTypes } from "@components/Commons/ColumnSelector/ColumnSelectorDialog";
import { CooperationChannels } from "@lib/api-hooks/useCooperations";
import { UseAnalyticsProps } from "@lib/api-hooks";
import { Mutable } from "@lib/util-types";
import { FilterTypesEnum } from "interface/AdsFilterInterface";
import { ChartBarSquareIcon } from "@heroicons/react/24/outline";
import { AnalyticsFilterType } from "@api/services/analytics";
import ChartLineIcon from "@components/Layout/icons/chart-line";
import { BaseAnalytics } from "@api/types/backendTypes";

export const emptyBaseAnalytics: BaseAnalytics = {
  ads: new Map(),
  adsets: new Map(),
  campaigns: new Map(),
  totals: new Map(),
};

export const TRACIFY_APP_STATE_VAR = "TRACIFY_APP_STATE";

export const GIT_REPO_SLUG = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF;
export const MARKETING_CHANNELS_VIEWS = ["ads-manager", "dashboard"];
export const MARKETING_CHANNELS_VIEW_VAR = "MARKETING_CHANNELS_VIEW";
export const DEFAULT_MARKETING_CHANNELS_VIEW: (typeof MARKETING_CHANNELS_VIEWS)[number] =
  "dashboard";

export const DEPLOY_ENVIRONMENT =
  GIT_REPO_SLUG === "main"
    ? "production"
    : GIT_REPO_SLUG === "beta"
      ? "beta"
      : GIT_REPO_SLUG === "demo"
        ? "demo"
        : "development";

export const START_DATE_RANGE_SELECT_OPTIONS_BASIC = [
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Last 7 days", value: "last_7_days" },
  { label: "Last 14 days", value: "last_14_days" },
  { label: "Last 30 days", value: "last_30_days" },
  { label: "This Month", value: "this_month" },
  { label: "Last Month", value: "last_month" },
] as const;

export const START_DATE_RANGE_SELECT_OPTIONS_EXTRA = [
  { label: "Last 3 months", value: "last_3_months" },
  { label: "Last 6 months", value: "last_6_months" },
  { label: "Last 1 year", value: "last_1_year" },
  { label: "Custom", value: "Custom" },
] as const;

export const START_DATE_RANGE_SELECT_OPTIONS_FULL = [
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Last 7 days", value: "last_7_days" },
  { label: "Last 10 days", value: "last_10_days" },
  { label: "Last 14 days", value: "last_14_days" },
  { label: "Last 15 days", value: "last_15_days" },
  { label: "Last 30 days", value: "last_30_days" },
  { label: "This Month", value: "this_month" },
  { label: "Last Month", value: "last_month" },
  { label: "Last 3 months", value: "last_3_months" },
  { label: "Last 6 months", value: "last_6_months" },
  { label: "Last 1 year", value: "last_1_year" },
  { label: "Custom", value: "Custom" },
] as const;

export const PREVIOUS_DATE_RANGE_SELECT_OPTIONS = [
  { label: "Previous Period", value: "last_period" },
  { label: "Last Month", value: "last_month" },
  { label: "Last Quarter", value: "last_quarter" },
  { label: "Last Year", value: "last_year" },
] as const;

export const ATTRIBUTION_WINDOW_OPTIONS = [
  {
    label: "1 day",
    value: "1",
  },
  {
    label: "2 days",
    value: "2",
  },
  {
    label: "7 days",
    value: "7",
  },
  {
    label: "14 days",
    value: "14",
  },
  {
    label: "28 days",
    value: "28",
  },
  {
    label: "60 days",
    value: "60",
  },
  {
    label: "90 days",
    value: "90",
  },
] as const;

export const ATTRIBUTION_MODEL_OPTIONS = [
  {
    label: "AI Attribution",
    value: "dda_stcb_purchaseatd_t3",
    description:
      "Understand the true impact of each touchpoint on all conversions",
    detailedDescription:
      "Our AI Attribution automatically calculates the impact of all touchpoints across your customer's journey; standard attribution settings no longer need to be configured.",
  },
  {
    label: "First Click",
    value: "first",
    description: "Best for campaigns driving initial customer interest",
    detailedDescription:
      "This model gives 100% of the credit for a sale to the first touchpoint a customer had with your business.",
  },
  {
    label: "Last Click",
    value: "last",
    description: "Best for campaigns directly leading to conversions",
    detailedDescription:
      "This model gives 100% of the credit for a sale to the last touchpoint a customer had with your business.",
  },
  {
    label: "U-Shape",
    value: "ushape",
    description: "For emphasizing initial engagement and final conversion",
    detailedDescription:
      "This model gives 100% of the credit for a sale to the first touchpoint a customer had with your business.",
  },
  {
    label: "Linear",
    value: "linear",
    description: "For strategies where every touchpoint is considered equal",
    detailedDescription:
      "This model gives 100% of the credit for a sale to the first touchpoint a customer had with your business.",
  },

  {
    label: "Isolated",
    value: "isolated",
    description: "Give 100% attribution credit to",
    detailedDescription: "",
  },
] as const;
export const CREATIVE_ATTRIBUTION_OPTIONS = [
  {
    label: "Isolated",
    value: "isolated",
  },
  ...ATTRIBUTION_MODEL_OPTIONS,
] as const;

export const PAID_CHANNELS = [
  {
    label: "Meta",
    value: "facebook",
  },
  {
    label: "Google",
    value: "google",
  },
  {
    label: "TikTok",
    value: "tiktok",
  },
  {
    label: "Influencer",
    value: "influencer",
  },
  {
    label: "Microsoft Ads",
    value: "msads",
  },

  {
    label: "Pinterest",
    value: "pinterest",
  },
  {
    label: "Snapchat",
    value: "snapchat",
  },

  {
    label: "Taboola",
    value: "taboola",
  },
  {
    label: "Outbrain",
    value: "outbrain",
  },
  {
    label: "Criteo",
    value: "criteo",
  },
];

export const CHANNELS_WITHOUT_BASESCORE_TYPE = [
  "influencer",
  "influencer_module",
  "email",
  "referred",
  "referral",
  "whatsapp",
  "direct",
];

export const AVAILABLE_MARKETING_CHANNEL_OPTIONS = [
  {
    label: "Meta",
    value: "facebook",
  },
  {
    label: "Google",
    value: "google",
  },
  {
    label: "TikTok",
    value: "tiktok",
  },
  {
    label: "Influencer",
    value: "influencer",
  },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "WhatsApp",
    value: "whatsapp",
  },
  {
    label: "Microsoft Ads",
    value: "msads",
  },

  {
    label: "Pinterest",
    value: "pinterest",
  },
  {
    label: "Snapchat",
    value: "snapchat",
  },

  {
    label: "Taboola",
    value: "taboola",
  },
  {
    label: "Outbrain",
    value: "outbrain",
  },
  {
    label: "Criteo",
    value: "criteo",
  },
  {
    label: "Organic",
    value: "referred",
  },
  {
    label: "Direct",
    value: "direct",
  },
];
export const CHANNEL_SELECT_OPTIONS = [
  {
    label: "All",
    value: "all",
    description: "Analyze paid and non-paid touchpoints",
    channels: AVAILABLE_MARKETING_CHANNEL_OPTIONS,
    hasSubMenu: false,
  },
  {
    label: "Paid Media",
    value: "paid",
    description: "Analyze only paid touchpoints",
    channels: PAID_CHANNELS,
    hasSubMenu: false,
  },
  {
    label: "Custom",
    value: "custom",
    description: "Choose which touchpoints to include",
    channels: AVAILABLE_MARKETING_CHANNEL_OPTIONS,
    hasSubMenu: true,
  },
] as const;

export const AVAILABLE_INFLUENCER_SOURCE_OPTIONS = [
  {
    label: "TikTok",
    value: "tiktok",
  },
  {
    label: "Facebook",
    value: "facebook",
  },
  {
    label: "Instagram",
    value: "instagram",
  },
  {
    label: "YouTube",
    value: "youtube",
  },
  {
    label: "Blog",
    value: "blog",
  },
  {
    label: "Twitch",
    value: "twitch",
  },
  {
    label: "Pinterest",
    value: "pinterest",
  },
  {
    label: "LinkedIn",
    value: "linkedin",
  },
  {
    label: "Snapchat",
    value: "snapchat",
  },
  {
    label: "Podcast",
    value: "podcast",
  },
  {
    label: "Other",
    value: "other",
  },
];

export const AVAILABLE_GOOGLE_CHANNEL_OPTIONS = [
  {
    label: "Perf. Max",
    value: "PERFORMANCE_MAX",
  },
  {
    label: "Search",
    value: "SEARCH",
  },
  {
    label: "Demand Gen",
    value: "DEMAND_GEN",
  },
  {
    label: "Display",
    value: "DISPLAY",
  },
  {
    label: "Shopping",
    value: "SHOPPING",
  },
  {
    label: "Local",
    value: "LOCAL",
  },
  {
    label: "Local Services",
    value: "LOCAL_SERVICES",
  },
  {
    label: "Multi Channel",
    value: "MULTI_CHANNEL",
  },
  {
    label: "Smart",
    value: "SMART",
  },
  {
    label: "Unknown",
    value: "UNKNOWN",
  },
  {
    label: "Unspecified",
    value: "UNSPECIFIED",
  },
  {
    label: "Video",
    value: "VIDEO",
  },
  {
    label: "Catalog Sales",
    value: "CATALOG_SALES",
  },
] as const;

export const REPORT_TIME_OPTIONS = [
  {
    label: "Conversion Date",
    value: "event",
  },
  {
    label: "Click Date",
    value: "adview",
  },
] as const;

export const PPS_METRICS_OPTIONS = [
  { label: "Revenue", value: "purchaseAmount" },
  { label: "Orders", value: "purchaseCount" },
  { label: "ROAS", value: "roas" },
  { label: "AOV", value: "aov" },
  { label: "CPO", value: "cpo" },
  { label: "CAC", value: "cac" },
];

export const COLUMN_OPTIONS = [
  { label: "Ad Spend", value: "spend" },
  { label: "Historical Chart", value: "historical" },
  { label: "Fixed Commissions", value: "fixedCommissionAmount" },
  { label: "Variable Commissions", value: "variableCommissionAmount" },
  { label: "Discount Matching Rate", value: "discountMatchingRate" },
  { label: "Orders", value: "purchaseCount" },
  { label: "Labels", value: "labels" },
  { label: "Revenue", value: "purchaseAmount" },
  { label: "ROAS", value: "roas" },
  { label: "Ad Spend", value: "cost" },
  { label: "CAC", value: "cac" },
  { label: "New Customer Rate", value: "newCustomerRate" },
  { label: "New Visitor Rate", value: "newVisitorRate" },
  { label: "CPO", value: "cpo" },
  { label: "CTR", value: "ctr" },
  { label: "CTR (link)", value: "ctrLink" },
  { label: "Average Order Value", value: "aov" },
  { label: "Conversion Rate", value: "cr" },
  { label: "CPM", value: "cpm" },
  { label: "Frequency", value: "frequency" },
  { label: "Clicks", value: "clicks" },
  { label: "Link Clicks", value: "linkClick" },
  { label: "Google Conversions", value: "platformConversions" },
  { label: "All Google Conversions", value: "allPlatformConversions" },
  { label: "CPC", value: "cpc" },
  { label: "Unique Adds to Cart", value: "addtocart" },
  { label: "Unique Checkouts Initiated", value: "checkout" },
  { label: "Unique Content Views", value: "productview" },
  { label: "Unique Page Views", value: "pageview" },
  { label: "Impressions", value: "impressions" },
  { label: "Reach", value: "reach" },
  { label: "ø time to sale", value: "avgConversionTime" },
  { label: "New Customer Rate", value: "ncr" },
  { label: "% of Customers", value: "customerPercentage" },
  { label: "Steps", value: "steps" },
  { label: "Comments", value: "comment" },
  { label: "Post Saves", value: "onsiteConversionPostSave" },
  { label: "Post Shares", value: "post" },
  { label: "Avg. Watch Time", value: "videoAvgTimeWatchedActions" },
  { label: "3s Video Views", value: "videoView" },
  { label: "Thumbstop Ratio", value: "thumbstopRatio" },
  { label: "Acquisition ROAS", value: "acquisitionRoas" },
];

// for the general dashboard mode
export const GENERAL_COLUMN_OPTIONS = [
  { label: "Ad Spend", value: "spend" },
  { label: "Conversions", value: "purchaseCount" },
  { label: "Historical Chart", value: "historical" },
  { label: "Conv. value", value: "purchaseAmount" },
  { label: "ROAS", value: "roas" },
  { label: "Ad Spend", value: "cost" },
  { label: "CAC", value: "cac" },
  { label: "New Customer Rate", value: "newCustomerRate" },
  { label: "New Visitor Rate", value: "newVisitorRate" },
  { label: "CPA", value: "cpo" },
  { label: "CTR", value: "ctr" },
  { label: "Average Conversion Value", value: "aov" },
  { label: "Conversion Rate", value: "cr" },
  { label: "CPM", value: "cpm" },
  { label: "Frequency", value: "frequency" },
  { label: "Clicks", value: "clicks" },
  { label: "Link Clicks", value: "linkClick" },
  { label: "CPC", value: "cpc" },
  { label: "Unique Adds to Cart", value: "addtocart" },
  { label: "Unique Checkouts Initiated", value: "checkout" },
  { label: "Unique Content Views", value: "productview" },
  { label: "Unique Page Views", value: "pageview" },
  { label: "Impressions", value: "impressions" },
  { label: "Reach", value: "reach" },
  { label: "ø time to sale", value: "avgConversionTime" },
  { label: "New Customer Rate", value: "ncr" },
  { label: "% of Customers", value: "customerPercentage" },
  { label: "Steps", value: "steps" },
  { label: "Comments", value: "comment" },
  { label: "Post Saves", value: "onsiteConversionPostSave" },
  { label: "Post Shares", value: "post" },
  { label: "Avg. Watch Time", value: "videoAvgTimeWatchedActions" },
  { label: "3s Video Views", value: "videoView" },
  { label: "Thumbstop Ratio", value: "thumbstopRatio" },
  { label: "Acquisition ROAS", value: "acquisitionRoas" },
];

export const NVR_OPTIONS = [
  {
    label: "All",
    title: "General Overview",
    value: "default",
    description:
      "Faster loading. Doesn’t include metrics based on segmentation like CAC.",
  },
  {
    label: "New Users",
    title: "New Users",
    value: "new",
    description: "Shows your visitors + customers which were new to your site.",
  },
  {
    label: "Returning Users",
    title: "Returning Users",
    value: "returning",
    description:
      "Shows your visitors + customers which were already to your site or converted.",
  },
  {
    label: "New & Returning Users",
    title: "New & Returning Users",
    value: "all",
    description:
      "Aggregates the metrics from new + returning & shows shares between the two.",
  },
] as const;

export const V2_NVR_OPTIONS = [
  {
    label: "All Users",
    title: "All Users",
    value: "all",
    description:
      "Aggregates the metrics from new + returning & shows shares between the two.",
  },
  {
    label: "New Users",
    title: "New Users",
    value: "new",
    description: "Shows your visitors + customers which were new to your site.",
  },
  {
    label: "Returning Users",
    title: "Returning Users",
    value: "returning",
    description:
      "Shows your visitors + customers which were already to your site or converted.",
  },
] as const;

export const AVAILABLE_COLUMNS_PER_TABLE: Map<
  TableTypes,
  Array<(typeof COLUMN_OPTIONS)[number]["value"]>
> = new Map([
  [
    "realtime",
    [
      "pageview",
      "productview",
      "purchaseAmount",
      "purchaseCount",
      "addtocart",
      "aov",
      "cr",
    ],
  ],
  [
    "marketingChannelOverview",
    [
      "historical",
      "aov",
      "cr",
      "cac",
      "newCustomerRate",
      "newVisitorRate",
      "cpo",
      "roas",
      "addtocart",
      "pageview",
      "productview",
      "purchaseCount",
      "purchaseAmount",
      "spend",
    ],
  ],
  [
    "creativesOverview",
    [
      "spend",
      "purchaseCount",
      "purchaseAmount",
      "roas",
      "cac",
      "newCustomerRate",
      "newVisitorRate",
      "cpo",
      "ctr",
      "aov",
      "cr",
      "cpm",
      "frequency",
      "clicks",
      "cpc",
      "addtocart",
      "productview",
      "pageview",
      "reach",
      "impressions",
      // "historical",
    ],
  ],
  [
    "pinterestCreativesOverview",
    [
      "spend",
      "purchaseCount",
      "purchaseAmount",
      "roas",
      "cac",
      "newCustomerRate",
      "newVisitorRate",
      "cpo",
      "ctr",
      "aov",
      "cr",
      "cpm",
      "clicks",
      "cpc",
      "addtocart",
      "productview",
      "pageview",
      "impressions",
      // "historical",
    ],
  ],
  [
    "facebookCreativesOverview",
    [
      "spend",
      "purchaseCount",
      "purchaseAmount",
      "roas",
      "cac",
      "cpo",
      "ctr",
      "aov",
      "cr",
      "cpm",
      "frequency",
      "linkClick",
      "cpc",
      "addtocart",
      "productview",
      "pageview",
      "reach",
      "impressions",
      "newCustomerRate",
      "newVisitorRate",
      // "historical",
    ],
  ],
  [
    "googleChannels",
    [
      "historical",
      "spend",
      "purchaseCount",
      "purchaseAmount",
      "roas",
      "cac",
      "newCustomerRate",
      "newVisitorRate",
      "cpo",
      "ctr",
      "aov",
      "cr",
      "cpm",
      "frequency",
      "clicks",
      "cpc",
      "addtocart",
      "productview",
      "pageview",
      "reach",
      "impressions",
    ],
  ],
  [
    "adsManager",
    [
      "historical",
      "spend",
      "purchaseCount",
      "purchaseAmount",
      "roas",
      "cac",
      "newCustomerRate",
      "newVisitorRate",
      "cpo",
      "ctr",
      "aov",
      "cr",
      "cpm",
      "frequency",
      "clicks",
      "cpc",
      "addtocart",
      "productview",
      "pageview",
      "reach",
      "impressions",
    ],
  ],
  [
    "pinterestAdsManager",
    [
      "historical",
      "spend",
      "purchaseCount",
      "purchaseAmount",
      "roas",
      "cac",
      "newCustomerRate",
      "newVisitorRate",
      "cpo",
      "ctr",
      "aov",
      "cr",
      "cpm",
      "clicks",
      "cpc",
      "addtocart",
      "productview",
      "pageview",
      "impressions",
    ],
  ],
  [
    "facebookAdsManager",
    [
      "historical",
      "spend",
      "purchaseCount",
      "purchaseAmount",
      "roas",
      "cac",
      "cpo",
      "ctr",
      "aov",
      "cr",
      "cpm",
      "frequency",
      "linkClick",
      "cpc",
      "addtocart",
      "productview",
      "pageview",
      "reach",
      "impressions",
      "newCustomerRate",
      "newVisitorRate",
    ],
  ],
  [
    "googleAdsManager",
    [
      "historical",
      "spend",
      "purchaseCount",
      "purchaseAmount",
      "roas",
      "cac",
      "newCustomerRate",
      "newVisitorRate",
      "cpo",
      "ctr",
      "aov",
      "cr",
      "cpm",
      "clicks",
      "cpc",
      "addtocart",
      "productview",
      "pageview",
      "impressions",
      "platformConversions",
      "allPlatformConversions",
    ],
  ],
  [
    "influencer",
    [
      "purchaseCount",
      "purchaseAmount",
      "spend",
      "fixedCommissionAmount",
      "variableCommissionAmount",
      "discountMatchingRate",
      "roas",
      "addtocart",
      "productview",
      "pageview",
      "aov",
      "cr",
      "cpo",
      "cac",
      "newCustomerRate",
      "newVisitorRate",
      // "name",
    ],
  ],
  [
    "cooperations",
    [
      "purchaseCount",
      "purchaseAmount",
      "spend",
      "labels",
      "fixedCommissionAmount",
      "variableCommissionAmount",
      "discountMatchingRate",
      "roas",
      "addtocart",
      "productview",
      "pageview",
      "aov",
      "cr",
      "cpo",
      "cac",
      "newCustomerRate",
      "newVisitorRate",
      // "name",
    ],
  ],
  [
    "influencerChannelOverview",
    [
      "historical",
      "aov",
      "cr",
      "cac",
      "newCustomerRate",
      "newVisitorRate",
      "cpo",
      "roas",
      "addtocart",
      "pageview",
      "productview",
      "purchaseCount",
      "purchaseAmount",
      "spend",
    ],
  ],
  [
    "creativeComparison",
    [
      "spend",
      "purchaseCount",
      "purchaseAmount",
      "cpc",
      "cpm",
      "ctr",
      "clicks",
      "aov",
      "cr",
      "frequency",
      "roas",
      "cac",
      "newCustomerRate",
      "newVisitorRate",
      "cpo",
      "addtocart",
      "reach",
      "impressions",
      "productview",
      "pageview",
    ],
  ],
  [
    "facebookCreativeComparison",
    [
      "spend",
      "purchaseCount",
      "purchaseAmount",
      "cpc",
      "cpm",
      "ctr",
      "linkClick",
      "aov",
      "cr",
      "frequency",
      "roas",
      "cac",
      "newCustomerRate",
      "newVisitorRate",
      "cpo",
      "addtocart",
      "thumbstopRatio",
      "comment",
      "onsiteConversionPostSave",
      "post",
      "reach",
      "impressions",
      "videoAvgTimeWatchedActions",
      "videoView",
      "productview",
      "pageview",
      // "historical",
    ],
  ],
  [
    "facebookCreativeAnalysis",
    [
      "spend",
      "purchaseCount",
      "purchaseAmount",
      "roas",
      "cac",
      "newCustomerRate",
      "newVisitorRate",
      "cpo",
      "ctr",
      "aov",
      "cr",
      "cpm",
      "frequency",
      "linkClick",
      "cpc",
      "addtocart",
      "productview",
      "pageview",
      "reach",
      "impressions",
      "comment",
      "onsiteConversionPostSave",
      "post",
      "videoAvgTimeWatchedActions",
      "videoView",
      "thumbstopRatio",
    ],
  ],
  [
    "creativeAnalysis",
    [
      "spend",
      "purchaseCount",
      "purchaseAmount",
      "roas",
      "cac",
      "newCustomerRate",
      "newVisitorRate",
      "cpo",
      "ctr",
      "aov",
      "cr",
      "cpm",
      "frequency",
      "clicks",
      "cpc",
      "addtocart",
      "productview",
      "pageview",
      "reach",
      "impressions",
    ],
  ],
]);

export const maxMetricCountForTables: Record<string, number> = {
  facebookCreativeAnalysis: 10,
  creativeAnalysis: 10,
};

export const chartTooltipStyles = {
  backgroundColor: "hsl(var(--popover))",
  borderColor: "hsl(var(--gray-500))",
  borderRadius: "0.5rem",
  padding: "1rem",
};

export const CONSTANT_LABELS: { [key: string]: string } = {
  meta: "Meta",
  facebook: "Meta",
  google: "Google",
  tiktok: "TikTok",
  instagram: "Instagram",
  threads: "Threads",
  twitter: "Twitter / X",
  linkedin: "LinkedIn",
  snapchat: "Snapchat",
  youtube: "YouTube",
  twitch: "Twitch",
  podcast: "Podcast",
  blog: "Blog",
  other: "Other",
  email: "Email",
  influencer: "Influencer (old)",
  influencer_module: "Influencer",
  organic: "Organic",
  referred: "Organic",
  referral: "Referral",
  taboola: "Taboola",
  outbrain: "Outbrain",
  pinterest: "Pinterest",
  whatsapp: "WhatsApp",
  direct: "Direct",
  msads: "Microsoft",
  criteo: "Criteo",
  purchaseAmount: "Revenue",
  historical: "Historical Chart",
  purchaseCount: "Orders",
  spend: "Ad Spend",
  fixedCommissionAmount: "Fixed Commissions",
  variableCommissionAmount: "Variable Commissions",
  discountMatchingRate: "Discount Matching Rate",
  productview: "Unique Content Views",
  pageview: "Unique Page Views",
  addtocart: "Unique Adds to Cart",
  checkout: "Unique Checkouts Initiated",
  roas: "ROAS",
  mer: "MER",
  cac: "CAC",
  cpo: "CPO",
  aov: "Average Order Value",
  cr: "Conversion Rate",
  new_pageview: "New visitors",
  returning_pageview: "Returning visitors",
  new_customers: "New Customers",
  returning_customers: "Returning Customers",
  all_customers: "All Customers",
  story: "Story",
  post: "Post",
  reel: "Reel",
  live: "Live",
  video: "Video",
  short: "Short",
  pin: "Pin",
};

export const GENERAL_CONSTANT_LABELS: { [key: string]: string } = {
  ...CONSTANT_LABELS,
  purchaseAmount: "Conv. value",
  purchaseCount: "Conversions",
  cpo: "CPA",
  aov: "ACV",
};

export const METRICS_LABELS: { [key: string]: string } = {
  purchaseAmount: "Revenue",
  historical: "Historical Chart (Spend)",

  purchaseCount: "Orders",
  spend: "Ad Spend",
  ctr: "CTR",
  productview: "uCV",
  pageview: "uPV",
  addtocart: "uATC",
  checkout: "uIC",
  roas: "ROAS",
  mer: "MER",
  cac: "CAC",
  cpo: "CPO",
  compared_cpo: "Previous CPO",
  compared_mer: "Previous MER",
  compared_cac: "Previous CAC",
  aov: "AOV",
  cpm: "CPM",
  frequency: "Frequency",
  clicks: "Clicks",
  linkClick: "Clicks (link)",
  platformConversions: "Google Conversions",
  allPlatformConversions: "All Google Conversions",
  cpc: "CPC",
  newCustomerRate: "New Customer Rate",
  newVisitorRate: "New Visitor Rate",
  thumbstopRatio: "Thumbstop Ratio",
  videoView: "Video Views",
  fixedCommissionAmount: "Fixed Commissions",
  variableCommissionAmount: "Variable Commissions",
  discountMatchingRate: "Discount Matching Rate",
  videoAvgTimeWatchedActions: "Avg. Watch Time",
  onsiteConversionPostSave: "Post Saves",
  comment: "Comments",
  post: "Post Shares",
  reach: "Reach",
  impressions: "Impressions",
  cr: "Conversion Rate",
  new_pageview: "New visitors",
  returning_pageview: "Returning visitors",
  new_customers: "New Customers",
  all_customers: "All Customers",
  returning_customers: "Returning Customers",
};

export const METRICS_BIGGER_IS_BETTER: { [key: string]: boolean } = {
  purchaseAmount: true,
  purchaseCount: true,
  spend: true,
  ctr: true,
  productview: true,
  pageview: true,
  addtocart: true,
  checkout: true,
  roas: true,
  mer: true,
  cac: false,
  cpo: false,
  compared_cpo: false,
  compared_mer: false,
  compared_cac: false,
  aov: true,
  cpm: false,
  frequency: true,
  clicks: true,
  linkClick: true,
  platformConversions: true,
  allPlatformConversions: true,
  cpc: false,
  newCustomerRate: true,
  newVisitorRate: true,
  thumbstopRatio: true,
  videoView: true,
  fixedSpent: true,
  variableSpent: true,
  videoAvgTimeWatchedActions: true,
  onsiteConversionPostSave: false,
  comment: true,
  post: true,
  reach: true,
  impressions: true,
  cr: true,
  new_pageview: true,
  returning_pageview: true,
  new_customers: true,
  all_customers: true,
  returning_customers: true,
};

export const GENERAL_METRICS_LABELS: { [key: string]: string } = {
  ...METRICS_LABELS,
  purchaseAmount: "Conv. value",
  purchaseCount: "Conversions",
  cpo: "CPA",
  compared_cpo: "Previous CPA",
  aov: "ACV",
};

export const METRICS_LABELS_INF: { [key: string]: string } = {
  ...METRICS_LABELS,
  spend: "Ad Spend",
};
export const GENERAL_METRICS_LABELS_INF: { [key: string]: string } = {
  ...METRICS_LABELS_INF,
  purchaseAmount: "Conv. value",
  purchaseCount: "Conversions",
  cpo: "CPA",
  aov: "ACV",
};

export const METRIC_SUFFIXES: { [key: string]: string } = {
  purchaseAmount: "€",
  historical: "€",
  purchaseCount: "",
  spend: "€",
  ctr: "%",
  productview: "",
  pageview: "",
  addtocart: "",
  checkout: "",
  roas: "",
  mer: "",
  cac: "€",
  cpo: "€",
  aov: "€",
  cpm: "€",
  frequency: "",
  clicks: "",
  linkClick: "",
  cpc: "€",
  reach: "",
  impressions: "",
  cr: "%",
  newCustomerRate: "%",
  newVisitorRate: "%",
  thumbstopRatio: "%",
  comment: "",
  onsiteConversionPostSave: "",
  post: "",
  videoAvgTimeWatchedActions: "s",
  videoView: "",
};

export const METRIC_DECIMALS: { [key: string]: number } = {
  purchaseAmount: 2,
  historical: 2,
  purchaseCount: 0,
  spend: 2,
  ctr: 2,
  productview: 0,
  pageview: 0,
  addtocart: 0,
  checkout: 0,
  roas: 2,
  mer: 2,
  cac: 2,
  cpo: 2,
  aov: 2,
  cpm: 2,
  frequency: 0,
  clicks: 0,
  linkClick: 0,
  cpc: 2,
  reach: 0,
  impressions: 0,
  cr: 2,
  newCustomerRate: 2,
  newVisitorRate: 2,
  thumbstopRatio: 2,
  comment: 0,
  onsiteConversionPostSave: 0,
  post: 0,
  videoAvgTimeWatchedActions: 1,
  videoView: 0,
};
export const METRIC_DECIMALS_LINEAR_USHAPE: { [key: string]: number } = {
  ...METRIC_DECIMALS,
  purchaseCount: 2,
  productview: 2,
  pageview: 2,
  addtocart: 2,
};

export const CHANNEL_URL_PARAMS: { [key in CooperationChannels]: string } = {
  facebook: "fb",
  tiktok: "tt",
  instagram: "ig",
  linkedin: "lin",
  snapchat: "sc",
  youtube: "yt",
  twitch: "ttv",
  podcast: "pod",
  blog: "blg",
  pinterest: "pin",
  twx: "twx",
  threads: "thr",
  other: "other",
};

export const AXIS_VALUES = [
  1, 1.5, 2, 3, 4, 5, 6, 8, 10, 25, 50, 75, 100, 250, 500, 750, 1000, 2500,
  5000, 7500, 10000, 25000, 50000, 75000, 100000, 250000, 500000, 750000,
  1000000,
];
export const FINE_GRAINED_AXIS_VALUES = [
  1, 1.5, 2, 3, 4, 5, 6, 8, 10, 12, 15, 18, 20, 25, 30, 40, 50, 75, 100, 125,
  150, 200, 250, 300, 400, 500, 750, 1000, 1250, 1500, 2000, 2500, 3000, 4000,
  5000, 7500, 10000, 12500, 15000, 20000, 25000, 30000, 40000, 50000, 75000,
  100000, 125000, 150000, 200000, 250000, 400000, 500000, 750000, 1000000,
];

export const DASHBOARD_APPROVALS = ["access_rts_dashboard"] as const;

export type FilterTypes = {
  label: string;
  value: string;
  type: string;
  availableFilter?: FilterTypesEnum[];
  hidden?: boolean;
  inputLabel?: string;
  options?: Array<{ label: string; value: any }>;
};

export const ADS_MANAGER_FILTERS = [
  {
    label: "Campaign Name",
    value: "campaigns",
    type: "text",
    availableFilter: [FilterTypesEnum.contains, FilterTypesEnum.contains_not],
  },
  {
    label: "Ad group Name",
    value: "adsets",
    type: "text",
    availableFilter: [FilterTypesEnum.contains, FilterTypesEnum.contains_not],
  },
  {
    label: "Ad Name",
    value: "ads",
    type: "text",
    availableFilter: [FilterTypesEnum.contains, FilterTypesEnum.contains_not],
  },
  { label: "Ad Spend", value: "spend", inputLabel: "€", type: "number" },
  {
    label: "Revenue",
    value: "purchaseAmount",
    inputLabel: "€",
    type: "number",
  },
  { label: "ROAS", value: "roas", type: "number" },
  { label: "CTR", value: "ctr", inputLabel: "%", type: "number" },
  { label: "CAC", value: "cac", inputLabel: "€", type: "number" },
  { label: "CPO", value: "cpo", inputLabel: "€", type: "number" },
  { label: "Campaign Id", value: "campaignIds", type: "text" },
  { label: "Ad group Id", value: "adsetIds", type: "text" },
  { label: "Ad Id", value: "adIds", type: "text" },
  {
    label: "Channel Type",
    value: "advertisingChannelType",
    type: "text",
    availableFilter: [FilterTypesEnum.contains],
    hidden: true,
  },
  // {
  //   label: "Conversion Rate",
  //   value: "conversionRate",
  //   inputLabel: "%",
  //   type: "number",
  // },
];

export const GENERAL_ADS_MANAGER_FILTERS = [
  {
    label: "Campaign Name",
    value: "campaigns",
    type: "text",
    availableFilter: [FilterTypesEnum.contains, FilterTypesEnum.contains_not],
  },
  {
    label: "Ad group Name",
    value: "adsets",
    type: "text",
    availableFilter: [FilterTypesEnum.contains, FilterTypesEnum.contains_not],
  },
  {
    label: "Ad Name",
    value: "ads",
    type: "text",
    availableFilter: [FilterTypesEnum.contains, FilterTypesEnum.contains_not],
  },
  { label: "Ad Spend", value: "spend", inputLabel: "€", type: "number" },
  {
    label: "Conv. Value",
    value: "purchaseAmount",
    inputLabel: "€",
    type: "number",
  },
  { label: "ROAS", value: "roas", type: "number" },
  { label: "CTR", value: "ctr", inputLabel: "%", type: "number" },
  { label: "CAC", value: "cac", inputLabel: "€", type: "number" },
  { label: "CPA", value: "cpo", inputLabel: "€", type: "number" },
  { label: "Campaign Id", value: "campaignIds", type: "text" },
  { label: "Ad group Id", value: "adsetIds", type: "text" },
  { label: "Ad Id", value: "adIds", type: "text" },
  {
    label: "Channel Type",
    value: "advertisingChannelType",
    type: "text",
    availableFilter: [FilterTypesEnum.contains],
    hidden: true,
  },
  // {
  //   label: "Conversion Rate",
  //   value: "conversionRate",
  //   inputLabel: "%",
  //   type: "number",
  // },
];

export const CONVERSION_PATH_FILTERS = [
  {
    label: "Number of touchpoints",
    value: "steps",
    inputLabel: "",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },

  {
    label: "First touchpoint",
    value: "firstTouchpoint",
    type: "select",
    options: AVAILABLE_MARKETING_CHANNEL_OPTIONS as Mutable<
      typeof AVAILABLE_MARKETING_CHANNEL_OPTIONS
    >,
  },
  {
    label: "Last touchpoint",
    value: "lastTouchpoint",
    type: "select",
    options: AVAILABLE_MARKETING_CHANNEL_OPTIONS as Mutable<
      typeof AVAILABLE_MARKETING_CHANNEL_OPTIONS
    >,
  },
  {
    label: "Included touchpoint",
    value: "path",
    type: "select",
    options: AVAILABLE_MARKETING_CHANNEL_OPTIONS as Mutable<
      typeof AVAILABLE_MARKETING_CHANNEL_OPTIONS
    >,
  },
  {
    label: "Revenue",
    value: "purchaseAmount",
    inputLabel: "€",
    type: "number",
  },
  {
    label: "Orders",
    value: "purchaseCount",
    inputLabel: "",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
  {
    label: "Average Order Value",
    value: "aov",
    type: "number",
    inputLabel: "€",
  },
  {
    label: "Conversion Rate",
    value: "cr",
    inputLabel: "%",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
  {
    label: "ø time to sale",
    value: "avgConversionTime",
    inputLabel: "hours",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
  // {
  //   label: "New customer rate",
  //   value: "ncr",
  //   inputLabel: "%",
  //   type: "number",
  //   availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  // },
  {
    label: "% of Customers",
    value: "customerPercentage",
    inputLabel: "%",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
];

export const GENERAL_CONVERSION_PATH_FILTERS = [
  {
    label: "Number of touchpoints",
    value: "steps",
    inputLabel: "",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
  {
    label: "First touchpoint",
    value: "firstTouchpoint",
    type: "select",
    options: AVAILABLE_MARKETING_CHANNEL_OPTIONS as Mutable<
      typeof AVAILABLE_MARKETING_CHANNEL_OPTIONS
    >,
  },
  {
    label: "Last touchpoint",
    value: "lastTouchpoint",
    type: "select",
    options: AVAILABLE_MARKETING_CHANNEL_OPTIONS as Mutable<
      typeof AVAILABLE_MARKETING_CHANNEL_OPTIONS
    >,
  },
  {
    label: "Conv. Value",
    value: "purchaseAmount",
    inputLabel: "€",
    type: "number",
  },
  {
    label: "Conversions",
    value: "purchaseCount",
    inputLabel: "",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
  {
    label: "Average Conversion Value",
    value: "aov",
    type: "number",
    inputLabel: "€",
  },
  {
    label: "Conversion Rate",
    value: "cr",
    inputLabel: "%",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
  {
    label: "ø time to conversion",
    value: "avgConversionTime",
    inputLabel: "hours",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
  {
    label: "% of Customers",
    value: "customerPercentage",
    inputLabel: "%",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
];

export const MAPPED_FILTER_OPERATORS = {
  "⊆": FilterTypesEnum.contains,
  "~⊆": FilterTypesEnum.contains_not,
  ">": FilterTypesEnum.gt,
  "<": FilterTypesEnum.lt,
};

export const CREATIVE_SEGMENTS_FILTERS = [
  {
    label: "Campaign Name",
    value: "campaigns",
    type: "text",
    availableFilter: [FilterTypesEnum.contains, FilterTypesEnum.contains_not],
  },
  {
    label: "Adset Name",
    value: "adsets",
    type: "text",
    availableFilter: [FilterTypesEnum.contains, FilterTypesEnum.contains_not],
  },
  {
    label: "Ad Name",
    value: "ads",
    type: "text",
    availableFilter: [FilterTypesEnum.contains, FilterTypesEnum.contains_not],
  },
  {
    label: "Ad Spend",
    value: "spend",
    inputLabel: "€",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
  {
    label: "Revenue",
    value: "purchaseAmount",
    inputLabel: "€",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
  {
    label: "Orders",
    value: "purchaseCount",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
  {
    label: "Impressions",
    value: "impressions",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
];
export const GENERAL_CREATIVE_SEGMENTS_FILTERS = [
  {
    label: "Campaign Name",
    value: "campaigns",
    type: "text",
    availableFilter: [FilterTypesEnum.contains, FilterTypesEnum.contains_not],
  },
  {
    label: "Adset Name",
    value: "adsets",
    type: "text",
    availableFilter: [FilterTypesEnum.contains, FilterTypesEnum.contains_not],
  },
  {
    label: "Ad Name",
    value: "ads",
    type: "text",
    availableFilter: [FilterTypesEnum.contains, FilterTypesEnum.contains_not],
  },
  {
    label: "Ad Spend",
    value: "spend",
    inputLabel: "€",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
  {
    label: "Conv. Value",
    value: "purchaseAmount",
    inputLabel: "€",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
  {
    label: "Conversions",
    value: "purchaseCount",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
  {
    label: "Impressions",
    value: "impressions",
    type: "number",
    availableFilter: [FilterTypesEnum.gt, FilterTypesEnum.lt],
  },
];

export const CREATIVES_FILTERS = [
  {
    label: "Ad Name",
    value: "ads",
    type: "text",
    availableFilter: [FilterTypesEnum.contains, FilterTypesEnum.contains_not],
  },
  {
    label: "Creative Format",
    value: "creativeFormat",
    type: "select",
    options: [
      { label: "Image", value: "image" },
      { label: "Video", value: "video" },
    ],
  },
  { label: "Ad Spend", value: "spend", inputLabel: "€", type: "number" },
  {
    label: "Revenue",
    value: "purchaseAmount",
    inputLabel: "€",
    type: "number",
  },
  { label: "Impressions", value: "impressions", type: "number" },
  { label: "ROAS", value: "roas", type: "number" },
  { label: "CTR", value: "ctr", inputLabel: "%", type: "number" },
  { label: "CAC", value: "cac", inputLabel: "€", type: "number" },
  { label: "CPO", value: "cpo", inputLabel: "€", type: "number" },
];

export const GENERAL_CREATIVES_FILTERS = [
  {
    label: "Ad Name",
    value: "ads",
    type: "text",
    availableFilter: [FilterTypesEnum.contains, FilterTypesEnum.contains_not],
  },
  {
    label: "Creative Format",
    value: "creativeFormat",
    type: "select",
    options: [
      { label: "Image", value: "image" },
      { label: "Video", value: "video" },
    ],
  },
  { label: "Ad Spend", value: "spend", inputLabel: "€", type: "number" },
  {
    label: "Conv. Value",
    value: "purchaseAmount",
    inputLabel: "€",
    type: "number",
  },
  { label: "Impressions", value: "impressions", type: "number" },
  { label: "ROAS", value: "roas", type: "number" },
  { label: "CTR", value: "ctr", inputLabel: "%", type: "number" },
  { label: "CAC", value: "cac", inputLabel: "€", type: "number" },
  { label: "CPA", value: "cpo", inputLabel: "€", type: "number" },
];

export const ECOMMERCE_TO_GENERAL_LABEL_MAPPING = {
  Revenue: "Conv. value",
  Orders: "Conversions",
  ROAS: "ROAS",
  CPO: "CPA",
};

export const STRINGS_TO_REMOVE_FROM_NAMES = [
  /( - copy)( \d{1,5})?/gi,
  /( - kopie)( \d{1,5})?/gi,
  /( - copie)( \d{1,5})?/gi,
  /( - copia)( \d{1,5})?/gi,
  /(copy)( \d{1,5})?/gi,
  /(kopie)( \d{1,5})?/gi,
  /(copie)( \d{1,5})?/gi,
  /(copia)( \d{1,5})?/gi,
  "/",
  ":",
  // countryCodeRegex,
];

// channel has to be defined in the value prop before _
// because we use it in other functions
export const INFLUENCER_PLACEMENT_TYPES = [
  { label: "Instagram Story", value: "instagram_story" },
  { label: "Instagram Post", value: "instagram_post" },
  { label: "Instagram Reel", value: "instagram_reel" },
  { label: "Instagram Live", value: "instagram_live" },
  { label: "TikTok Post", value: "tiktok_post" },
  { label: "TikTok Story", value: "tiktok_story" },
  { label: "Youtube Video", value: "youtube_video" },
  { label: "Youtube Short", value: "youtube_short" },
  { label: "Twitch Live", value: "twitch_live" },
  { label: "Pinterest Pin", value: "pinterest_pin" },
  { label: "Blog Post", value: "blog_post" },
  { label: "Podcast", value: "podcast" },
  { label: "LinkedIn Post", value: "linkedin_post" },
  { label: "Snapchat Story", value: "snapchat_story" },
  { label: "Facebook Post", value: "facebook_post" },
  { label: "Facebook Story", value: "facebook_story" },
  { label: "Twitter / X Post", value: "twx_post" },
  { label: "Threads Post", value: "threads_post" },
  { label: "Other", value: "other_post" },
] as const;

export const channelDashboardSettings = (
  nvrEnabled: boolean
): UseAnalyticsProps => ({
  collectAdInfo: true,
  collectAdKpis: true,
  collectAdKpiTimeline: true,
  views: nvrEnabled
    ? ["nvr_tc_aggregated", "nvr_tc_daily"]
    : ["tc_aggregated", "tc_daily"],
  collectAdInfoFqMode: true,
  fullyQualifiedIds: true,
  enableCompare: true,
});
export const channelAdsManagerSettings = (
  nvrEnabled: boolean
): UseAnalyticsProps => ({
  views: nvrEnabled
    ? ["nvr_tc_aggregated", "nvr_tc_daily"]
    : ["tc_aggregated", "tc_daily"],
  collectAdInfo: true,
  collectAdInfoFqMode: true,
  collectAdKpiTimeline: true,
  collectAdKpis: true,
  fullyQualifiedIds: true,
  enableCompare: true,
});
export const realtimeDashboardSettings = (
  nvrEnabled: boolean
): UseAnalyticsProps => ({
  views: nvrEnabled ? ["nvr_tc_aggregated"] : ["tc_aggregated"],
  collectAdInfo: true,
  include: ["influencers", "cooperations"],
  collectAdInfoFqMode: true,
  fullyQualifiedIds: true,
  cooperationsAsAdsets: true,
  enableCompare: true,
});
export const marketingOverviewSettings = (
  nvrEnabled: boolean,
  ppsEnabled?: boolean
): UseAnalyticsProps => ({
  views: ppsEnabled
    ? nvrEnabled
      ? ["nvr_tc_oid_aggregated", "nvr_tc_oid_daily"]
      : ["tc_oid_aggregated", "tc_oid_daily"]
    : nvrEnabled
      ? ["nvr_tc_aggregated", "nvr_tc_daily"]
      : ["tc_aggregated", "tc_daily"],
  include: ["influencers"],
  collectAdKpis: true,
  collectAdKpiTimeline: true,
  collectAdInfoFqMode: true,
  fullyQualifiedIds: true,
  enableCompare: true,
});

export const customerJourneySettings = (): UseAnalyticsProps => ({
  collectAdKpis: true,
  collectAdKpiTimeline: true,
  fixedMarketingChannels: AVAILABLE_MARKETING_CHANNEL_OPTIONS.map(
    (el) => el.value
  ),
  fixedReportTime: "event",
  fixedAttributionModel: "last",
  fixedAttributionWindow: "60",
  include: ["cooperations"],
  views: ["customer_journey_tc"],
  useDefaultMarketingChannels: DEPLOY_ENVIRONMENT === "production",
});
export const influencerOverviewSettings = (
  nvrEnabled: boolean
): UseAnalyticsProps => ({
  views: nvrEnabled
    ? ["nvr_tc_aggregated", "nvr_tc_daily"]
    : ["tc_aggregated", "tc_daily"],
  include: ["influencers", "cooperations"],
  collectAdKpis: false,
  collectAdKpiTimeline: false,
  collectAdInfoFqMode: false,
  fullyQualifiedIds: true,
  enableCompare: true,
});
export const singleInfluencerSettings = (
  nvrEnabled: boolean,
  filter?: AnalyticsFilterType
): UseAnalyticsProps => ({
  views: nvrEnabled
    ? ["nvr_tc_aggregated", "nvr_tc_daily"]
    : ["tc_aggregated", "tc_daily"],
  filter: filter ?? undefined,
  collectAdKpis: false,
  collectAdKpiTimeline: false,
  collectAdInfoFqMode: false,
  fullyQualifiedIds: true,
  enableCompare: true,
});

export const influencerDashboardSettings = (
  nvrEnabled: boolean
): UseAnalyticsProps => ({
  views: nvrEnabled ? ["nvr_tc_aggregated"] : ["tc_aggregated"],
  collectAdInfo: false,
  collectAdInfoFqMode: false,
  collectAdKpis: false,
  collectAdKpiTimeline: false,
  enableCompare: true,
});

export const creativeDashboardSettings = (
  nvrEnabled: boolean
): UseAnalyticsProps => ({
  views: nvrEnabled
    ? ["nvr_tc_aggregated", "nvr_tc_daily"]
    : ["tc_aggregated", "tc_daily"],
  collectAdInfoFqMode: true,
  fullyQualifiedIds: true,
  collectAdInfo: true,
  collectAdKpis: true,
  collectAdKpiTimeline: true,
});

export const fixedSettingsPerDashboardRoute = (
  nvrEnabled: boolean,
  options?: {
    creativeAttribution?: (typeof CREATIVE_ATTRIBUTION_OPTIONS)[number]["value"];
    creativeChannel?: "facebook" | "tiktok";
  }
) => ({
  "/": marketingOverviewSettings(nvrEnabled),
  "/realtime": realtimeDashboardSettings(nvrEnabled),
  "/channels/google/dashboard": channelDashboardSettings(nvrEnabled),
  "/channels/google/ads-manager": channelAdsManagerSettings(nvrEnabled),
  "/channels/meta/dashboard": channelDashboardSettings(nvrEnabled),
  "/channels/meta/ads-manager": channelAdsManagerSettings(nvrEnabled),
  "/channels/tiktok/dashboard": channelDashboardSettings(nvrEnabled),
  "/channels/tiktok/ads-manager": channelAdsManagerSettings(nvrEnabled),
  "/influencers": influencerDashboardSettings(nvrEnabled),
  "/influencers/cooperations": influencerDashboardSettings(nvrEnabled),
  "/customer-journeys": customerJourneySettings(),
  "/creatives/comparison": creativeDashboardSettings(nvrEnabled),
  "/creatives/analysis": creativeDashboardSettings(nvrEnabled),
});

export const creativeComparisonColors = [
  "text-[#6254FF]",
  "text-[#4D73A3]",
  "text-[#9E5A54]",
  "text-[#46A070]",
  "text-[#AD648D]",
  "text-[#6491A3]",
  "text-[#9267A6]",
  "text-[#7657A4]",
];
export const pureCreativeComparisonColors = [
  "#6254FF",
  "#4D73A3",
  "#9E5A54",
  "#46A070",
  "#AD648D",
  "#6491A3",
  "#9267A6",
  "#7657A4",
];

export const creativeComparisonGradients = [
  "bg-gradient-to-r from-[#6254FF]",
  "bg-gradient-to-r from-[#4D73A3]",
  "bg-gradient-to-r from-[#9E5A54]",
  "bg-gradient-to-r from-[#46A070]",
  "bg-gradient-to-r from-[#AD648D]",
  "bg-gradient-to-r from-[#6491A3]",
  "bg-gradient-to-r from-[#9267A6]",
  "bg-gradient-to-r from-[#7657A4]",
];

export const CREATIVE_COMPARISON_OPTIONS = [
  { label: "Ad Spend", value: "spend", suffix: "€" },
  {
    label: "Revenue",
    value: "purchaseAmount",
    suffix: "€",
  },
  { label: "ROAS", value: "roas" },
  { label: "CTR", value: "ctr", suffix: "%" },
  { label: "CAC", value: "cac", suffix: "€" },
  { label: "New Customer Rate", value: "newCustomerRate", suffix: "%" },
  { label: "CPO", value: "cpo", suffix: "€" },
  {
    label: "Conversion Rate",
    value: "cr",
    suffix: "%",
  },
];
// for general dashboard mode
export const GENERAL_CREATIVE_COMPARISON_OPTIONS = [
  { label: "Ad Spend", value: "spend", suffix: "€" },
  {
    label: "Conv. Value",
    value: "purchaseAmount",
    suffix: "€",
  },
  { label: "ROAS", value: "roas" },
  { label: "CTR", value: "ctr", suffix: "%" },
  { label: "CAC", value: "cac", suffix: "€" },
  { label: "New Customer Rate", value: "newCustomerRate", suffix: "%" },
  { label: "CPA", value: "cpo", suffix: "€" },
  {
    label: "Conversion Rate",
    value: "cr",
    suffix: "%",
  },
];

export const CREATIVE_CHART_OPTIONS = [
  { label: "Bar Chart", value: "bar", icon: ChartBarSquareIcon },
  {
    label: "Line Chart",
    value: "line",
    icon: ChartLineIcon,
  },
];

export const INFLUENCER_PERFORMANCE_OPTIONS = [
  { label: "Ad Spend", value: "spend", suffix: "€" },
  {
    label: "Revenue",
    value: "purchaseAmount",
    suffix: "€",
  },
  {
    label: "Orders",
    value: "purchaseCount",
    suffix: "",
  },
  { label: "ROAS", value: "roas" },
  {
    label: "Conversion Rate",
    value: "cr",
    suffix: "%",
  },
  { label: "CPO", value: "cpo", suffix: "€" },
  { label: "CAC", value: "cac", suffix: "€" },
  { label: "New Customer Rate", value: "newCustomerRate", suffix: "%" },
];

export const GENERAL_INFLUENCER_PERFORMANCE_OPTIONS = [
  { label: "Ad Spend", value: "spend", suffix: "€" },
  {
    label: "Conv. Value",
    value: "purchaseAmount",
    suffix: "€",
  },
  {
    label: "Conversions",
    value: "purchaseCount",
    suffix: "",
  },
  { label: "ROAS", value: "roas" },
  {
    label: "Conversion Rate",
    value: "cr",
    suffix: "%",
  },
  { label: "CPA", value: "cpo", suffix: "€" },
  { label: "CAC", value: "cac", suffix: "€" },
  { label: "New Customer Rate", value: "newCustomerRate", suffix: "%" },
];
export const pageLookup = {
  "/": "realtime dashboard",
  "/channels/meta/ads-manager": "meta ads manager",
  "/channels/google/ads-manager": "google ads manager",
  "/channels/tiktok/ads-manager": "tiktok ads manager",
  "/channels/pinterest/ads-manager": "pinterest ads manager",
};

export const COUNTRIES = [
  {
    name: "Ascension Island",
    code: "SH",
    emoji: "🇦🇨",
    unicode: "U+1F1E6 U+1F1E8",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AC.svg",
  },
  {
    name: "Andorra",
    code: "AD",
    emoji: "🇦🇩",
    unicode: "U+1F1E6 U+1F1E9",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AD.svg",
  },
  {
    name: "United Arab Emirates",
    code: "AE",
    emoji: "🇦🇪",
    unicode: "U+1F1E6 U+1F1EA",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AE.svg",
  },
  {
    name: "Afghanistan",
    code: "AF",
    emoji: "🇦🇫",
    unicode: "U+1F1E6 U+1F1EB",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AF.svg",
  },
  {
    name: "Antigua & Barbuda",
    code: "AG",
    emoji: "🇦🇬",
    unicode: "U+1F1E6 U+1F1EC",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AG.svg",
  },
  {
    name: "Anguilla",
    code: "AI",
    emoji: "🇦🇮",
    unicode: "U+1F1E6 U+1F1EE",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AI.svg",
  },
  {
    name: "Albania",
    code: "AL",
    emoji: "🇦🇱",
    unicode: "U+1F1E6 U+1F1F1",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AL.svg",
  },
  {
    name: "Armenia",
    code: "AM",
    emoji: "🇦🇲",
    unicode: "U+1F1E6 U+1F1F2",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AM.svg",
  },
  {
    name: "Angola",
    code: "AO",
    emoji: "🇦🇴",
    unicode: "U+1F1E6 U+1F1F4",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AO.svg",
  },
  {
    name: "Antarctica",
    code: "AQ",
    emoji: "🇦🇶",
    unicode: "U+1F1E6 U+1F1F6",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AQ.svg",
  },
  {
    name: "Argentina",
    code: "AR",
    emoji: "🇦🇷",
    unicode: "U+1F1E6 U+1F1F7",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AR.svg",
  },
  {
    name: "American Samoa",
    code: "AS",
    emoji: "🇦🇸",
    unicode: "U+1F1E6 U+1F1F8",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AS.svg",
  },
  {
    name: "Austria",
    code: "AT",
    emoji: "🇦🇹",
    unicode: "U+1F1E6 U+1F1F9",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AT.svg",
  },
  {
    name: "Australia",
    code: "AU",
    emoji: "🇦🇺",
    unicode: "U+1F1E6 U+1F1FA",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AU.svg",
  },
  {
    name: "Aruba",
    code: "AW",
    emoji: "🇦🇼",
    unicode: "U+1F1E6 U+1F1FC",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AW.svg",
  },
  {
    name: "Åland Islands",
    code: "AX",
    emoji: "🇦🇽",
    unicode: "U+1F1E6 U+1F1FD",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AX.svg",
  },
  {
    name: "Azerbaijan",
    code: "AZ",
    emoji: "🇦🇿",
    unicode: "U+1F1E6 U+1F1FF",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AZ.svg",
  },
  {
    name: "Bosnia & Herzegovina",
    code: "BA",
    emoji: "🇧🇦",
    unicode: "U+1F1E7 U+1F1E6",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BA.svg",
  },
  {
    name: "Barbados",
    code: "BB",
    emoji: "🇧🇧",
    unicode: "U+1F1E7 U+1F1E7",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BB.svg",
  },
  {
    name: "Bangladesh",
    code: "BD",
    emoji: "🇧🇩",
    unicode: "U+1F1E7 U+1F1E9",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BD.svg",
  },
  {
    name: "Belgium",
    code: "BE",
    emoji: "🇧🇪",
    unicode: "U+1F1E7 U+1F1EA",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BE.svg",
  },
  {
    name: "Burkina Faso",
    code: "BF",
    emoji: "🇧🇫",
    unicode: "U+1F1E7 U+1F1EB",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BF.svg",
  },
  {
    name: "Bulgaria",
    code: "BG",
    emoji: "🇧🇬",
    unicode: "U+1F1E7 U+1F1EC",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BG.svg",
  },
  {
    name: "Bahrain",
    code: "BH",
    emoji: "🇧🇭",
    unicode: "U+1F1E7 U+1F1ED",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg",
  },
  {
    name: "Burundi",
    code: "BI",
    emoji: "🇧🇮",
    unicode: "U+1F1E7 U+1F1EE",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BI.svg",
  },
  {
    name: "Benin",
    code: "BJ",
    emoji: "🇧🇯",
    unicode: "U+1F1E7 U+1F1EF",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BJ.svg",
  },
  {
    name: "St. Barthélemy",
    code: "BL",
    emoji: "🇧🇱",
    unicode: "U+1F1E7 U+1F1F1",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BL.svg",
  },
  {
    name: "Bermuda",
    code: "BM",
    emoji: "🇧🇲",
    unicode: "U+1F1E7 U+1F1F2",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BM.svg",
  },
  {
    name: "Brunei",
    code: "BN",
    emoji: "🇧🇳",
    unicode: "U+1F1E7 U+1F1F3",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BN.svg",
  },
  {
    name: "Bolivia",
    code: "BO",
    emoji: "🇧🇴",
    unicode: "U+1F1E7 U+1F1F4",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BO.svg",
  },
  {
    name: "Caribbean Netherlands",
    code: "BQ",
    emoji: "🇧🇶",
    unicode: "U+1F1E7 U+1F1F6",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BQ.svg",
  },
  {
    name: "Brazil",
    code: "BR",
    emoji: "🇧🇷",
    unicode: "U+1F1E7 U+1F1F7",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BR.svg",
  },
  {
    name: "Bahamas",
    code: "BS",
    emoji: "🇧🇸",
    unicode: "U+1F1E7 U+1F1F8",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BS.svg",
  },
  {
    name: "Bhutan",
    code: "BT",
    emoji: "🇧🇹",
    unicode: "U+1F1E7 U+1F1F9",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BT.svg",
  },
  {
    name: "Bouvet Island",
    code: "BV",
    emoji: "🇧🇻",
    unicode: "U+1F1E7 U+1F1FB",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BV.svg",
  },
  {
    name: "Botswana",
    code: "BW",
    emoji: "🇧🇼",
    unicode: "U+1F1E7 U+1F1FC",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BW.svg",
  },
  {
    name: "Belarus",
    code: "BY",
    emoji: "🇧🇾",
    unicode: "U+1F1E7 U+1F1FE",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BY.svg",
  },
  {
    name: "Belize",
    code: "BZ",
    emoji: "🇧🇿",
    unicode: "U+1F1E7 U+1F1FF",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BZ.svg",
  },
  {
    name: "Canada",
    code: "CA",
    emoji: "🇨🇦",
    unicode: "U+1F1E8 U+1F1E6",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CA.svg",
  },
  {
    name: "Cocos (Keeling) Islands",
    code: "CC",
    emoji: "🇨🇨",
    unicode: "U+1F1E8 U+1F1E8",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CC.svg",
  },
  {
    name: "Congo - Kinshasa",
    code: "CD",
    emoji: "🇨🇩",
    unicode: "U+1F1E8 U+1F1E9",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CD.svg",
  },
  {
    name: "Central African Republic",
    code: "CF",
    emoji: "🇨🇫",
    unicode: "U+1F1E8 U+1F1EB",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CF.svg",
  },
  {
    name: "Congo - Brazzaville",
    code: "CG",
    emoji: "🇨🇬",
    unicode: "U+1F1E8 U+1F1EC",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CG.svg",
  },
  {
    name: "Switzerland",
    code: "CH",
    emoji: "🇨🇭",
    unicode: "U+1F1E8 U+1F1ED",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CH.svg",
  },
  {
    name: "Côte d’Ivoire",
    code: "CI",
    emoji: "🇨🇮",
    unicode: "U+1F1E8 U+1F1EE",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CI.svg",
  },
  {
    name: "Cook Islands",
    code: "CK",
    emoji: "🇨🇰",
    unicode: "U+1F1E8 U+1F1F0",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CK.svg",
  },
  {
    name: "Chile",
    code: "CL",
    emoji: "🇨🇱",
    unicode: "U+1F1E8 U+1F1F1",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CL.svg",
  },
  {
    name: "Cameroon",
    code: "CM",
    emoji: "🇨🇲",
    unicode: "U+1F1E8 U+1F1F2",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CM.svg",
  },
  {
    name: "China",
    code: "CN",
    emoji: "🇨🇳",
    unicode: "U+1F1E8 U+1F1F3",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CN.svg",
  },
  {
    name: "Colombia",
    code: "CO",
    emoji: "🇨🇴",
    unicode: "U+1F1E8 U+1F1F4",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CO.svg",
  },
  {
    name: "Costa Rica",
    code: "CR",
    emoji: "🇨🇷",
    unicode: "U+1F1E8 U+1F1F7",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CR.svg",
  },
  {
    name: "Cuba",
    code: "CU",
    emoji: "🇨🇺",
    unicode: "U+1F1E8 U+1F1FA",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CU.svg",
  },
  {
    name: "Cape Verde",
    code: "CV",
    emoji: "🇨🇻",
    unicode: "U+1F1E8 U+1F1FB",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CV.svg",
  },
  {
    name: "Curaçao",
    code: "CW",
    emoji: "🇨🇼",
    unicode: "U+1F1E8 U+1F1FC",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CW.svg",
  },
  {
    name: "Christmas Island",
    code: "CX",
    emoji: "🇨🇽",
    unicode: "U+1F1E8 U+1F1FD",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CX.svg",
  },
  {
    name: "Cyprus",
    code: "CY",
    emoji: "🇨🇾",
    unicode: "U+1F1E8 U+1F1FE",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CY.svg",
  },
  {
    name: "Czechia",
    code: "CZ",
    emoji: "🇨🇿",
    unicode: "U+1F1E8 U+1F1FF",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CZ.svg",
  },
  {
    name: "Germany",
    code: "DE",
    emoji: "🇩🇪",
    unicode: "U+1F1E9 U+1F1EA",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DE.svg",
  },
  {
    name: "Djibouti",
    code: "DJ",
    emoji: "🇩🇯",
    unicode: "U+1F1E9 U+1F1EF",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DJ.svg",
  },
  {
    name: "Denmark",
    code: "DK",
    emoji: "🇩🇰",
    unicode: "U+1F1E9 U+1F1F0",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DK.svg",
  },
  {
    name: "Dominica",
    code: "DM",
    emoji: "🇩🇲",
    unicode: "U+1F1E9 U+1F1F2",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DM.svg",
  },
  {
    name: "Dominican Republic",
    code: "DO",
    emoji: "🇩🇴",
    unicode: "U+1F1E9 U+1F1F4",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DO.svg",
  },
  {
    name: "Algeria",
    code: "DZ",
    emoji: "🇩🇿",
    unicode: "U+1F1E9 U+1F1FF",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DZ.svg",
  },
  {
    name: "Ecuador",
    code: "EC",
    emoji: "🇪🇨",
    unicode: "U+1F1EA U+1F1E8",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EC.svg",
  },
  {
    name: "Estonia",
    code: "EE",
    emoji: "🇪🇪",
    unicode: "U+1F1EA U+1F1EA",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EE.svg",
  },
  {
    name: "Egypt",
    code: "EG",
    emoji: "🇪🇬",
    unicode: "U+1F1EA U+1F1EC",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EG.svg",
  },
  {
    name: "Western Sahara",
    code: "EH",
    emoji: "🇪🇭",
    unicode: "U+1F1EA U+1F1ED",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EH.svg",
  },
  {
    name: "Eritrea",
    code: "ER",
    emoji: "🇪🇷",
    unicode: "U+1F1EA U+1F1F7",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ER.svg",
  },
  {
    name: "Spain",
    code: "ES",
    emoji: "🇪🇸",
    unicode: "U+1F1EA U+1F1F8",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ES.svg",
  },
  {
    name: "Ethiopia",
    code: "ET",
    emoji: "🇪🇹",
    unicode: "U+1F1EA U+1F1F9",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ET.svg",
  },
  {
    name: "Finland",
    code: "FI",
    emoji: "🇫🇮",
    unicode: "U+1F1EB U+1F1EE",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FI.svg",
  },
  {
    name: "Fiji",
    code: "FJ",
    emoji: "🇫🇯",
    unicode: "U+1F1EB U+1F1EF",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FJ.svg",
  },
  {
    name: "Falkland Islands",
    code: "FK",
    emoji: "🇫🇰",
    unicode: "U+1F1EB U+1F1F0",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FK.svg",
  },
  {
    name: "Micronesia",
    code: "FM",
    emoji: "🇫🇲",
    unicode: "U+1F1EB U+1F1F2",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FM.svg",
  },
  {
    name: "Faroe Islands",
    code: "FO",
    emoji: "🇫🇴",
    unicode: "U+1F1EB U+1F1F4",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FO.svg",
  },
  {
    name: "France",
    code: "FR",
    emoji: "🇫🇷",
    unicode: "U+1F1EB U+1F1F7",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FR.svg",
  },
  {
    name: "Gabon",
    code: "GA",
    emoji: "🇬🇦",
    unicode: "U+1F1EC U+1F1E6",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GA.svg",
  },
  {
    name: "United Kingdom",
    code: "GB",
    emoji: "🇬🇧",
    unicode: "U+1F1EC U+1F1E7",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg",
  },
  {
    name: "Grenada",
    code: "GD",
    emoji: "🇬🇩",
    unicode: "U+1F1EC U+1F1E9",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GD.svg",
  },
  {
    name: "Georgia",
    code: "GE",
    emoji: "🇬🇪",
    unicode: "U+1F1EC U+1F1EA",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GE.svg",
  },
  {
    name: "French Guiana",
    code: "GF",
    emoji: "🇬🇫",
    unicode: "U+1F1EC U+1F1EB",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GF.svg",
  },
  {
    name: "Guernsey",
    code: "GG",
    emoji: "🇬🇬",
    unicode: "U+1F1EC U+1F1EC",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GG.svg",
  },
  {
    name: "Ghana",
    code: "GH",
    emoji: "🇬🇭",
    unicode: "U+1F1EC U+1F1ED",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GH.svg",
  },
  {
    name: "Gibraltar",
    code: "GI",
    emoji: "🇬🇮",
    unicode: "U+1F1EC U+1F1EE",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GI.svg",
  },
  {
    name: "Greenland",
    code: "GL",
    emoji: "🇬🇱",
    unicode: "U+1F1EC U+1F1F1",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GL.svg",
  },
  {
    name: "Gambia",
    code: "GM",
    emoji: "🇬🇲",
    unicode: "U+1F1EC U+1F1F2",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GM.svg",
  },
  {
    name: "Guinea",
    code: "GN",
    emoji: "🇬🇳",
    unicode: "U+1F1EC U+1F1F3",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GN.svg",
  },
  {
    name: "Guadeloupe",
    code: "GP",
    emoji: "🇬🇵",
    unicode: "U+1F1EC U+1F1F5",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GP.svg",
  },
  {
    name: "Equatorial Guinea",
    code: "GQ",
    emoji: "🇬🇶",
    unicode: "U+1F1EC U+1F1F6",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GQ.svg",
  },
  {
    name: "Greece",
    code: "GR",
    emoji: "🇬🇷",
    unicode: "U+1F1EC U+1F1F7",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GR.svg",
  },
  {
    name: "South Georgia & South Sandwich Islands",
    code: "GS",
    emoji: "🇬🇸",
    unicode: "U+1F1EC U+1F1F8",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GS.svg",
  },
  {
    name: "Guatemala",
    code: "GT",
    emoji: "🇬🇹",
    unicode: "U+1F1EC U+1F1F9",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GT.svg",
  },
  {
    name: "Guam",
    code: "GU",
    emoji: "🇬🇺",
    unicode: "U+1F1EC U+1F1FA",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GU.svg",
  },
  {
    name: "Guinea-Bissau",
    code: "GW",
    emoji: "🇬🇼",
    unicode: "U+1F1EC U+1F1FC",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GW.svg",
  },
  {
    name: "Guyana",
    code: "GY",
    emoji: "🇬🇾",
    unicode: "U+1F1EC U+1F1FE",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GY.svg",
  },
  {
    name: "Hong Kong SAR China",
    code: "HK",
    emoji: "🇭🇰",
    unicode: "U+1F1ED U+1F1F0",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HK.svg",
  },
  {
    name: "Heard & McDonald Islands",
    code: "HM",
    emoji: "🇭🇲",
    unicode: "U+1F1ED U+1F1F2",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HM.svg",
  },
  {
    name: "Honduras",
    code: "HN",
    emoji: "🇭🇳",
    unicode: "U+1F1ED U+1F1F3",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HN.svg",
  },
  {
    name: "Croatia",
    code: "HR",
    emoji: "🇭🇷",
    unicode: "U+1F1ED U+1F1F7",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HR.svg",
  },
  {
    name: "Haiti",
    code: "HT",
    emoji: "🇭🇹",
    unicode: "U+1F1ED U+1F1F9",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HT.svg",
  },
  {
    name: "Hungary",
    code: "HU",
    emoji: "🇭🇺",
    unicode: "U+1F1ED U+1F1FA",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HU.svg",
  },
  {
    name: "Canary Islands",
    code: "IC",
    emoji: "🇮🇨",
    unicode: "U+1F1EE U+1F1E8",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IC.svg",
  },
  {
    name: "Indonesia",
    code: "ID",
    emoji: "🇮🇩",
    unicode: "U+1F1EE U+1F1E9",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ID.svg",
  },
  {
    name: "Ireland",
    code: "IE",
    emoji: "🇮🇪",
    unicode: "U+1F1EE U+1F1EA",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IE.svg",
  },
  {
    name: "Israel",
    code: "IL",
    emoji: "🇮🇱",
    unicode: "U+1F1EE U+1F1F1",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IL.svg",
  },
  {
    name: "Isle of Man",
    code: "IM",
    emoji: "🇮🇲",
    unicode: "U+1F1EE U+1F1F2",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IM.svg",
  },
  {
    name: "India",
    code: "IN",
    emoji: "🇮🇳",
    unicode: "U+1F1EE U+1F1F3",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg",
  },
  {
    name: "British Indian Ocean Territory",
    code: "IO",
    emoji: "🇮🇴",
    unicode: "U+1F1EE U+1F1F4",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IO.svg",
  },
  {
    name: "Iraq",
    code: "IQ",
    emoji: "🇮🇶",
    unicode: "U+1F1EE U+1F1F6",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IQ.svg",
  },
  {
    name: "Iran",
    code: "IR",
    emoji: "🇮🇷",
    unicode: "U+1F1EE U+1F1F7",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IR.svg",
  },
  {
    name: "Iceland",
    code: "IS",
    emoji: "🇮🇸",
    unicode: "U+1F1EE U+1F1F8",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IS.svg",
  },
  {
    name: "Italy",
    code: "IT",
    emoji: "🇮🇹",
    unicode: "U+1F1EE U+1F1F9",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IT.svg",
  },
  {
    name: "Jersey",
    code: "JE",
    emoji: "🇯🇪",
    unicode: "U+1F1EF U+1F1EA",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JE.svg",
  },
  {
    name: "Jamaica",
    code: "JM",
    emoji: "🇯🇲",
    unicode: "U+1F1EF U+1F1F2",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JM.svg",
  },
  {
    name: "Jordan",
    code: "JO",
    emoji: "🇯🇴",
    unicode: "U+1F1EF U+1F1F4",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JO.svg",
  },
  {
    name: "Japan",
    code: "JP",
    emoji: "🇯🇵",
    unicode: "U+1F1EF U+1F1F5",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JP.svg",
  },
  {
    name: "Kenya",
    code: "KE",
    emoji: "🇰🇪",
    unicode: "U+1F1F0 U+1F1EA",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KE.svg",
  },
  {
    name: "Kyrgyzstan",
    code: "KG",
    emoji: "🇰🇬",
    unicode: "U+1F1F0 U+1F1EC",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KG.svg",
  },
  {
    name: "Cambodia",
    code: "KH",
    emoji: "🇰🇭",
    unicode: "U+1F1F0 U+1F1ED",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KH.svg",
  },
  {
    name: "Kiribati",
    code: "KI",
    emoji: "🇰🇮",
    unicode: "U+1F1F0 U+1F1EE",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KI.svg",
  },
  {
    name: "Comoros",
    code: "KM",
    emoji: "🇰🇲",
    unicode: "U+1F1F0 U+1F1F2",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KM.svg",
  },
  {
    name: "St. Kitts & Nevis",
    code: "KN",
    emoji: "🇰🇳",
    unicode: "U+1F1F0 U+1F1F3",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KN.svg",
  },
  {
    name: "North Korea",
    code: "KP",
    emoji: "🇰🇵",
    unicode: "U+1F1F0 U+1F1F5",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KP.svg",
  },
  {
    name: "South Korea",
    code: "KR",
    emoji: "🇰🇷",
    unicode: "U+1F1F0 U+1F1F7",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KR.svg",
  },
  {
    name: "Kuwait",
    code: "KW",
    emoji: "🇰🇼",
    unicode: "U+1F1F0 U+1F1FC",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KW.svg",
  },
  {
    name: "Cayman Islands",
    code: "KY",
    emoji: "🇰🇾",
    unicode: "U+1F1F0 U+1F1FE",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KY.svg",
  },
  {
    name: "Kazakhstan",
    code: "KZ",
    emoji: "🇰🇿",
    unicode: "U+1F1F0 U+1F1FF",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KZ.svg",
  },
  {
    name: "Laos",
    code: "LA",
    emoji: "🇱🇦",
    unicode: "U+1F1F1 U+1F1E6",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LA.svg",
  },
  {
    name: "Lebanon",
    code: "LB",
    emoji: "🇱🇧",
    unicode: "U+1F1F1 U+1F1E7",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LB.svg",
  },
  {
    name: "St. Lucia",
    code: "LC",
    emoji: "🇱🇨",
    unicode: "U+1F1F1 U+1F1E8",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LC.svg",
  },
  {
    name: "Liechtenstein",
    code: "LI",
    emoji: "🇱🇮",
    unicode: "U+1F1F1 U+1F1EE",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LI.svg",
  },
  {
    name: "Sri Lanka",
    code: "LK",
    emoji: "🇱🇰",
    unicode: "U+1F1F1 U+1F1F0",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LK.svg",
  },
  {
    name: "Liberia",
    code: "LR",
    emoji: "🇱🇷",
    unicode: "U+1F1F1 U+1F1F7",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LR.svg",
  },
  {
    name: "Lesotho",
    code: "LS",
    emoji: "🇱🇸",
    unicode: "U+1F1F1 U+1F1F8",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LS.svg",
  },
  {
    name: "Lithuania",
    code: "LT",
    emoji: "🇱🇹",
    unicode: "U+1F1F1 U+1F1F9",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LT.svg",
  },
  {
    name: "Luxembourg",
    code: "LU",
    emoji: "🇱🇺",
    unicode: "U+1F1F1 U+1F1FA",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LU.svg",
  },
  {
    name: "Latvia",
    code: "LV",
    emoji: "🇱🇻",
    unicode: "U+1F1F1 U+1F1FB",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LV.svg",
  },
  {
    name: "Libya",
    code: "LY",
    emoji: "🇱🇾",
    unicode: "U+1F1F1 U+1F1FE",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LY.svg",
  },
  {
    name: "Morocco",
    code: "MA",
    emoji: "🇲🇦",
    unicode: "U+1F1F2 U+1F1E6",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MA.svg",
  },
  {
    name: "Monaco",
    code: "MC",
    emoji: "🇲🇨",
    unicode: "U+1F1F2 U+1F1E8",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MC.svg",
  },
  {
    name: "Moldova",
    code: "MD",
    emoji: "🇲🇩",
    unicode: "U+1F1F2 U+1F1E9",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MD.svg",
  },
  {
    name: "Montenegro",
    code: "ME",
    emoji: "🇲🇪",
    unicode: "U+1F1F2 U+1F1EA",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ME.svg",
  },
  {
    name: "St. Martin",
    code: "MF",
    emoji: "🇲🇫",
    unicode: "U+1F1F2 U+1F1EB",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MF.svg",
  },
  {
    name: "Madagascar",
    code: "MG",
    emoji: "🇲🇬",
    unicode: "U+1F1F2 U+1F1EC",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MG.svg",
  },
  {
    name: "Marshall Islands",
    code: "MH",
    emoji: "🇲🇭",
    unicode: "U+1F1F2 U+1F1ED",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MH.svg",
  },
  {
    name: "North Macedonia",
    code: "MK",
    emoji: "🇲🇰",
    unicode: "U+1F1F2 U+1F1F0",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MK.svg",
  },
  {
    name: "Mali",
    code: "ML",
    emoji: "🇲🇱",
    unicode: "U+1F1F2 U+1F1F1",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ML.svg",
  },
  {
    name: "Myanmar (Burma)",
    code: "MM",
    emoji: "🇲🇲",
    unicode: "U+1F1F2 U+1F1F2",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MM.svg",
  },
  {
    name: "Mongolia",
    code: "MN",
    emoji: "🇲🇳",
    unicode: "U+1F1F2 U+1F1F3",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MN.svg",
  },
  {
    name: "Macao SAR China",
    code: "MO",
    emoji: "🇲🇴",
    unicode: "U+1F1F2 U+1F1F4",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MO.svg",
  },
  {
    name: "Northern Mariana Islands",
    code: "MP",
    emoji: "🇲🇵",
    unicode: "U+1F1F2 U+1F1F5",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MP.svg",
  },
  {
    name: "Martinique",
    code: "MQ",
    emoji: "🇲🇶",
    unicode: "U+1F1F2 U+1F1F6",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MQ.svg",
  },
  {
    name: "Mauritania",
    code: "MR",
    emoji: "🇲🇷",
    unicode: "U+1F1F2 U+1F1F7",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MR.svg",
  },
  {
    name: "Montserrat",
    code: "MS",
    emoji: "🇲🇸",
    unicode: "U+1F1F2 U+1F1F8",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MS.svg",
  },
  {
    name: "Malta",
    code: "MT",
    emoji: "🇲🇹",
    unicode: "U+1F1F2 U+1F1F9",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MT.svg",
  },
  {
    name: "Mauritius",
    code: "MU",
    emoji: "🇲🇺",
    unicode: "U+1F1F2 U+1F1FA",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MU.svg",
  },
  {
    name: "Maldives",
    code: "MV",
    emoji: "🇲🇻",
    unicode: "U+1F1F2 U+1F1FB",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MV.svg",
  },
  {
    name: "Malawi",
    code: "MW",
    emoji: "🇲🇼",
    unicode: "U+1F1F2 U+1F1FC",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MW.svg",
  },
  {
    name: "Mexico",
    code: "MX",
    emoji: "🇲🇽",
    unicode: "U+1F1F2 U+1F1FD",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MX.svg",
  },
  {
    name: "Malaysia",
    code: "MY",
    emoji: "🇲🇾",
    unicode: "U+1F1F2 U+1F1FE",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MY.svg",
  },
  {
    name: "Mozambique",
    code: "MZ",
    emoji: "🇲🇿",
    unicode: "U+1F1F2 U+1F1FF",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MZ.svg",
  },
  {
    name: "Namibia",
    code: "NA",
    emoji: "🇳🇦",
    unicode: "U+1F1F3 U+1F1E6",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NA.svg",
  },
  {
    name: "New Caledonia",
    code: "NC",
    emoji: "🇳🇨",
    unicode: "U+1F1F3 U+1F1E8",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NC.svg",
  },
  {
    name: "Niger",
    code: "NE",
    emoji: "🇳🇪",
    unicode: "U+1F1F3 U+1F1EA",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NE.svg",
  },
  {
    name: "Norfolk Island",
    code: "NF",
    emoji: "🇳🇫",
    unicode: "U+1F1F3 U+1F1EB",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NF.svg",
  },
  {
    name: "Nigeria",
    code: "NG",
    emoji: "🇳🇬",
    unicode: "U+1F1F3 U+1F1EC",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NG.svg",
  },
  {
    name: "Nicaragua",
    code: "NI",
    emoji: "🇳🇮",
    unicode: "U+1F1F3 U+1F1EE",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NI.svg",
  },
  {
    name: "Netherlands",
    code: "NL",
    emoji: "🇳🇱",
    unicode: "U+1F1F3 U+1F1F1",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NL.svg",
  },
  {
    name: "Norway",
    code: "NO",
    emoji: "🇳🇴",
    unicode: "U+1F1F3 U+1F1F4",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NO.svg",
  },
  {
    name: "Nepal",
    code: "NP",
    emoji: "🇳🇵",
    unicode: "U+1F1F3 U+1F1F5",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NP.svg",
  },
  {
    name: "Nauru",
    code: "NR",
    emoji: "🇳🇷",
    unicode: "U+1F1F3 U+1F1F7",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NR.svg",
  },
  {
    name: "Niue",
    code: "NU",
    emoji: "🇳🇺",
    unicode: "U+1F1F3 U+1F1FA",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NU.svg",
  },
  {
    name: "New Zealand",
    code: "NZ",
    emoji: "🇳🇿",
    unicode: "U+1F1F3 U+1F1FF",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NZ.svg",
  },
  {
    name: "Oman",
    code: "OM",
    emoji: "🇴🇲",
    unicode: "U+1F1F4 U+1F1F2",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/OM.svg",
  },
  {
    name: "Panama",
    code: "PA",
    emoji: "🇵🇦",
    unicode: "U+1F1F5 U+1F1E6",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PA.svg",
  },
  {
    name: "Peru",
    code: "PE",
    emoji: "🇵🇪",
    unicode: "U+1F1F5 U+1F1EA",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PE.svg",
  },
  {
    name: "French Polynesia",
    code: "PF",
    emoji: "🇵🇫",
    unicode: "U+1F1F5 U+1F1EB",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PF.svg",
  },
  {
    name: "Papua New Guinea",
    code: "PG",
    emoji: "🇵🇬",
    unicode: "U+1F1F5 U+1F1EC",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PG.svg",
  },
  {
    name: "Philippines",
    code: "PH",
    emoji: "🇵🇭",
    unicode: "U+1F1F5 U+1F1ED",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PH.svg",
  },
  {
    name: "Pakistan",
    code: "PK",
    emoji: "🇵🇰",
    unicode: "U+1F1F5 U+1F1F0",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PK.svg",
  },
  {
    name: "Poland",
    code: "PL",
    emoji: "🇵🇱",
    unicode: "U+1F1F5 U+1F1F1",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PL.svg",
  },
  {
    name: "St. Pierre & Miquelon",
    code: "PM",
    emoji: "🇵🇲",
    unicode: "U+1F1F5 U+1F1F2",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PM.svg",
  },
  {
    name: "Pitcairn Islands",
    code: "PN",
    emoji: "🇵🇳",
    unicode: "U+1F1F5 U+1F1F3",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PN.svg",
  },
  {
    name: "Puerto Rico",
    code: "PR",
    emoji: "🇵🇷",
    unicode: "U+1F1F5 U+1F1F7",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PR.svg",
  },
  {
    name: "Palestinian Territories",
    code: "PS",
    emoji: "🇵🇸",
    unicode: "U+1F1F5 U+1F1F8",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PS.svg",
  },
  {
    name: "Portugal",
    code: "PT",
    emoji: "🇵🇹",
    unicode: "U+1F1F5 U+1F1F9",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PT.svg",
  },
  {
    name: "Palau",
    code: "PW",
    emoji: "🇵🇼",
    unicode: "U+1F1F5 U+1F1FC",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PW.svg",
  },
  {
    name: "Paraguay",
    code: "PY",
    emoji: "🇵🇾",
    unicode: "U+1F1F5 U+1F1FE",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PY.svg",
  },
  {
    name: "Qatar",
    code: "QA",
    emoji: "🇶🇦",
    unicode: "U+1F1F6 U+1F1E6",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/QA.svg",
  },
  {
    name: "Réunion",
    code: "RE",
    emoji: "🇷🇪",
    unicode: "U+1F1F7 U+1F1EA",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RE.svg",
  },
  {
    name: "Romania",
    code: "RO",
    emoji: "🇷🇴",
    unicode: "U+1F1F7 U+1F1F4",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RO.svg",
  },
  {
    name: "Serbia",
    code: "RS",
    emoji: "🇷🇸",
    unicode: "U+1F1F7 U+1F1F8",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RS.svg",
  },
  {
    name: "Russia",
    code: "RU",
    emoji: "🇷🇺",
    unicode: "U+1F1F7 U+1F1FA",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RU.svg",
  },
  {
    name: "Rwanda",
    code: "RW",
    emoji: "🇷🇼",
    unicode: "U+1F1F7 U+1F1FC",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RW.svg",
  },
  {
    name: "Saudi Arabia",
    code: "SA",
    emoji: "🇸🇦",
    unicode: "U+1F1F8 U+1F1E6",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SA.svg",
  },
  {
    name: "Solomon Islands",
    code: "SB",
    emoji: "🇸🇧",
    unicode: "U+1F1F8 U+1F1E7",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SB.svg",
  },
  {
    name: "Seychelles",
    code: "SC",
    emoji: "🇸🇨",
    unicode: "U+1F1F8 U+1F1E8",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SC.svg",
  },
  {
    name: "Sudan",
    code: "SD",
    emoji: "🇸🇩",
    unicode: "U+1F1F8 U+1F1E9",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SD.svg",
  },
  {
    name: "Sweden",
    code: "SE",
    emoji: "🇸🇪",
    unicode: "U+1F1F8 U+1F1EA",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SE.svg",
  },
  {
    name: "Singapore",
    code: "SG",
    emoji: "🇸🇬",
    unicode: "U+1F1F8 U+1F1EC",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SG.svg",
  },
  {
    name: "Slovenia",
    code: "SI",
    emoji: "🇸🇮",
    unicode: "U+1F1F8 U+1F1EE",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SI.svg",
  },
  {
    name: "Svalbard & Jan Mayen",
    code: "SJ",
    emoji: "🇸🇯",
    unicode: "U+1F1F8 U+1F1EF",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SJ.svg",
  },
  {
    name: "Slovakia",
    code: "SK",
    emoji: "🇸🇰",
    unicode: "U+1F1F8 U+1F1F0",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SK.svg",
  },
  {
    name: "Sierra Leone",
    code: "SL",
    emoji: "🇸🇱",
    unicode: "U+1F1F8 U+1F1F1",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SL.svg",
  },
  {
    name: "San Marino",
    code: "SM",
    emoji: "🇸🇲",
    unicode: "U+1F1F8 U+1F1F2",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SM.svg",
  },
  {
    name: "Senegal",
    code: "SN",
    emoji: "🇸🇳",
    unicode: "U+1F1F8 U+1F1F3",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SN.svg",
  },
  {
    name: "Somalia",
    code: "SO",
    emoji: "🇸🇴",
    unicode: "U+1F1F8 U+1F1F4",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SO.svg",
  },
  {
    name: "Suriname",
    code: "SR",
    emoji: "🇸🇷",
    unicode: "U+1F1F8 U+1F1F7",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SR.svg",
  },
  {
    name: "South Sudan",
    code: "SS",
    emoji: "🇸🇸",
    unicode: "U+1F1F8 U+1F1F8",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SS.svg",
  },
  {
    name: "São Tomé & Príncipe",
    code: "ST",
    emoji: "🇸🇹",
    unicode: "U+1F1F8 U+1F1F9",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ST.svg",
  },
  {
    name: "El Salvador",
    code: "SV",
    emoji: "🇸🇻",
    unicode: "U+1F1F8 U+1F1FB",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SV.svg",
  },
  {
    name: "Sint Maarten",
    code: "SX",
    emoji: "🇸🇽",
    unicode: "U+1F1F8 U+1F1FD",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SX.svg",
  },
  {
    name: "Syria",
    code: "SY",
    emoji: "🇸🇾",
    unicode: "U+1F1F8 U+1F1FE",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SY.svg",
  },
  {
    name: "Eswatini",
    code: "SZ",
    emoji: "🇸🇿",
    unicode: "U+1F1F8 U+1F1FF",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SZ.svg",
  },

  {
    name: "Turks & Caicos Islands",
    code: "TC",
    emoji: "🇹🇨",
    unicode: "U+1F1F9 U+1F1E8",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TC.svg",
  },
  {
    name: "Chad",
    code: "TD",
    emoji: "🇹🇩",
    unicode: "U+1F1F9 U+1F1E9",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TD.svg",
  },
  {
    name: "French Southern Territories",
    code: "TF",
    emoji: "🇹🇫",
    unicode: "U+1F1F9 U+1F1EB",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TF.svg",
  },
  {
    name: "Togo",
    code: "TG",
    emoji: "🇹🇬",
    unicode: "U+1F1F9 U+1F1EC",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TG.svg",
  },
  {
    name: "Thailand",
    code: "TH",
    emoji: "🇹🇭",
    unicode: "U+1F1F9 U+1F1ED",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TH.svg",
  },
  {
    name: "Tajikistan",
    code: "TJ",
    emoji: "🇹🇯",
    unicode: "U+1F1F9 U+1F1EF",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TJ.svg",
  },
  {
    name: "Tokelau",
    code: "TK",
    emoji: "🇹🇰",
    unicode: "U+1F1F9 U+1F1F0",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TK.svg",
  },
  {
    name: "Timor-Leste",
    code: "TL",
    emoji: "🇹🇱",
    unicode: "U+1F1F9 U+1F1F1",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TL.svg",
  },
  {
    name: "Turkmenistan",
    code: "TM",
    emoji: "🇹🇲",
    unicode: "U+1F1F9 U+1F1F2",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TM.svg",
  },
  {
    name: "Tunisia",
    code: "TN",
    emoji: "🇹🇳",
    unicode: "U+1F1F9 U+1F1F3",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TN.svg",
  },
  {
    name: "Tonga",
    code: "TO",
    emoji: "🇹🇴",
    unicode: "U+1F1F9 U+1F1F4",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TO.svg",
  },
  {
    name: "Turkey",
    code: "TR",
    emoji: "🇹🇷",
    unicode: "U+1F1F9 U+1F1F7",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TR.svg",
  },
  {
    name: "Trinidad & Tobago",
    code: "TT",
    emoji: "🇹🇹",
    unicode: "U+1F1F9 U+1F1F9",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TT.svg",
  },
  {
    name: "Tuvalu",
    code: "TV",
    emoji: "🇹🇻",
    unicode: "U+1F1F9 U+1F1FB",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TV.svg",
  },
  {
    name: "Taiwan",
    code: "TW",
    emoji: "🇹🇼",
    unicode: "U+1F1F9 U+1F1FC",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TW.svg",
  },
  {
    name: "Tanzania",
    code: "TZ",
    emoji: "🇹🇿",
    unicode: "U+1F1F9 U+1F1FF",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TZ.svg",
  },
  {
    name: "Ukraine",
    code: "UA",
    emoji: "🇺🇦",
    unicode: "U+1F1FA U+1F1E6",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UA.svg",
  },
  {
    name: "Uganda",
    code: "UG",
    emoji: "🇺🇬",
    unicode: "U+1F1FA U+1F1EC",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UG.svg",
  },
  {
    name: "U.S. Outlying Islands",
    code: "UM",
    emoji: "🇺🇲",
    unicode: "U+1F1FA U+1F1F2",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UM.svg",
  },
  {
    name: "United States",
    code: "US",
    emoji: "🇺🇸",
    unicode: "U+1F1FA U+1F1F8",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg",
  },
  {
    name: "Uruguay",
    code: "UY",
    emoji: "🇺🇾",
    unicode: "U+1F1FA U+1F1FE",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UY.svg",
  },
  {
    name: "Uzbekistan",
    code: "UZ",
    emoji: "🇺🇿",
    unicode: "U+1F1FA U+1F1FF",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UZ.svg",
  },
  {
    name: "Vatican City",
    code: "VA",
    emoji: "🇻🇦",
    unicode: "U+1F1FB U+1F1E6",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VA.svg",
  },
  {
    name: "St. Vincent & Grenadines",
    code: "VC",
    emoji: "🇻🇨",
    unicode: "U+1F1FB U+1F1E8",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VC.svg",
  },
  {
    name: "Venezuela",
    code: "VE",
    emoji: "🇻🇪",
    unicode: "U+1F1FB U+1F1EA",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VE.svg",
  },
  {
    name: "British Virgin Islands",
    code: "VG",
    emoji: "🇻🇬",
    unicode: "U+1F1FB U+1F1EC",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VG.svg",
  },
  {
    name: "U.S. Virgin Islands",
    code: "VI",
    emoji: "🇻🇮",
    unicode: "U+1F1FB U+1F1EE",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VI.svg",
  },
  {
    name: "Vietnam",
    code: "VN",
    emoji: "🇻🇳",
    unicode: "U+1F1FB U+1F1F3",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VN.svg",
  },
  {
    name: "Vanuatu",
    code: "VU",
    emoji: "🇻🇺",
    unicode: "U+1F1FB U+1F1FA",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VU.svg",
  },
  {
    name: "Wallis & Futuna",
    code: "WF",
    emoji: "🇼🇫",
    unicode: "U+1F1FC U+1F1EB",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WF.svg",
  },
  {
    name: "Samoa",
    code: "WS",
    emoji: "🇼🇸",
    unicode: "U+1F1FC U+1F1F8",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WS.svg",
  },
  {
    name: "Kosovo",
    code: "XK",
    emoji: "🇽🇰",
    unicode: "U+1F1FD U+1F1F0",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/XK.svg",
  },
  {
    name: "Yemen",
    code: "YE",
    emoji: "🇾🇪",
    unicode: "U+1F1FE U+1F1EA",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YE.svg",
  },
  {
    name: "Mayotte",
    code: "YT",
    emoji: "🇾🇹",
    unicode: "U+1F1FE U+1F1F9",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YT.svg",
  },
  {
    name: "South Africa",
    code: "ZA",
    emoji: "🇿🇦",
    unicode: "U+1F1FF U+1F1E6",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZA.svg",
  },
  {
    name: "Zambia",
    code: "ZM",
    emoji: "🇿🇲",
    unicode: "U+1F1FF U+1F1F2",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZM.svg",
  },
  {
    name: "Zimbabwe",
    code: "ZW",
    emoji: "🇿🇼",
    unicode: "U+1F1FF U+1F1FC",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZW.svg",
  },
];

export const EDUCATION_BANNER = {
  REALTIME_DASHBOARD: {
    video: "https://youtu.be/d2taLIJyzTc",
    article: undefined,
  },
  MARKETING_OVERVIEW: {
    video: "https://youtu.be/-JO49GmHWwA",
    article: undefined,
  },
  INFLUENCER_MODULE: {
    video: "https://www.youtube.com/watch?v=weDJoRIOIHk",
    article:
      "https://knowledge.tracify.ai/en/collections/46362-influencer-dashboard",
  },
  CREATIVE_DASHBOARD: {
    video: "https://www.youtube.com/watch?v=u1yGpm9yFRk",
    article:
      "https://knowledge.tracify.ai/en/collections/126864-creative-dashboard",
  },
  CUSTOMER_JOURNEYS: {
    video: "https://www.youtube.com/watch?v=3TFK649yXGQ",
    article:
      "https://knowledge.tracify.ai/en/collections/126900-customer-journey-dashboard",
  },
  MARKETING_GOOGLE: {
    video: "https://youtu.be/etAy1lo7RjE",
    article: undefined,
  },
  MARKETING_META: {
    video: "https://youtu.be/EdE3X743eg0",
    article: undefined,
  },
  MARKETING_TIKTOK: {
    video: "https://youtu.be/93zdKE082_A",
    article: undefined,
  },
  MARKETING_PINTEREST: {
    video: "https://youtu.be/93zdKE082_A",
    article: undefined,
  },
  PROFIT_AND_LOSS: {
    video: "https://youtu.be/klDHpdvO92w",
    article:
      "https://knowledge.tracify.ai/en/collections/591447-profit-loss-pnl-dashboard",
  },
};
