import React from "react";
import useSelectedStores from "@lib/hooks/use-selected-stores";
import SidebarStoreSelector from "@components/Stores/StoreSelectorMenu/SidebarStoreSelector";
import LinkWithPersistentQuery from "@components/LinkWithQuery/LinkWithQuery";
import Image from "next/image";
import { useRouter } from "next/router";
import { classNames } from "@lib/util-functions";
import { useLogo } from "@lib/utils";
import { lastVisitedPageAtom, posthogFeatureFlagsAtom } from "atoms";
import { useAtom } from "jotai";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import SettingsIcon from "./icons/settings-icon";
import IntegrationsIcon from "./icons/integrations-icon";
import WebhooksIcon from "./icons/webhooks-icon";
import FirstPartyTrackingIcon from "./icons/first-party-tracking-icon";
import CostExpensesIcon from "./icons/cost-expenses-settings-icon";
import PresetsIcon from "./icons/presets-icon";
import SlidersIcon from "./icons/sliders-icon";
import ShopSystemIcon from "./icons/shop-system-icon";
import RecentActivityIcon from "./icons/recent-activity-icon";

type NavigationType = {
  name: string;
  icon: any;
  href?: string;
  label?: string;
  color?: string;
  id: string;
};

const navigation: NavigationType[] = [
  {
    name: "General Settings",
    icon: SettingsIcon,
    href: "/settings",
    id: "sidebar-general-settings",
  },
  {
    name: "Shop System",
    icon: ShopSystemIcon,
    href: "/settings/shop-system",
    id: "sidebar-shop-system",
  },
  {
    name: "Tracking Parameters",
    icon: SlidersIcon,
    href: "/settings/tracking-parameters",
    id: "tracking-parameters",
  },
  {
    name: "Presets",
    icon: PresetsIcon,
    href: "/settings/presets",
    id: "sidebar-presets",
    label: "New",
    color: "bg-[#ffd85c] text-background-soft",
  },
  {
    name: "Webhooks",
    icon: WebhooksIcon,
    href: "/settings/webhooks",
    id: "sidebar-webhooks",
  },
  {
    name: "First-Party-Tracking",
    icon: FirstPartyTrackingIcon,
    href: "/settings/first-party-tracking",
    id: "sidebar-first-party-tracking",
  },
  {
    name: "Costs & Expenses",
    icon: CostExpensesIcon,
    href: "/settings/costs-expenses",
    id: "sidebar-costs-expenses",
  },
  {
    name: "Recent Activity",
    icon: RecentActivityIcon,
    href: "/settings/recent-activity",
    id: "recent-activity",
  },
];

const organisationNavigation: NavigationType[] = [
  {
    name: "Integrations",
    icon: IntegrationsIcon,
    href: "/settings/integrations",
    id: "sidebar-integrations",
  },
];

const activeClassNames = "bg-primary-dark text-white dark:text-gray-50";
const normalClassNames =
  "text-sidebar dark:text-gray-50 hover:text-gray-100 dark:hover:text-gray-50 hover:bg-primary-dark group-hover:text-foreground dark:group-hover:text-gray-200";

const SettingsSidebar = () => {
  const [lastVisitedPage, setLastVisitedPage] = useAtom(lastVisitedPageAtom);
  const { onStoreChanged, selectedStoreIds, selectedStores } =
    useSelectedStores();
  const logo = useLogo();
  const router = useRouter();
  const isShopifyOrCustomStore = selectedStores?.some(
    (el) =>
      el.shop_system === "shopify" ||
      el.shop_system === "shopify_plus" ||
      el.shop_system === "custom"
  );
  const [posthogFeatureFlags] = useAtom(posthogFeatureFlagsAtom);
  const isPnlEnabled = posthogFeatureFlags?.pnl_dashboard?.pnl;
  const isShopifySystem =
    selectedStores[0]?.shop_system?.toLowerCase().includes("shopify") &&
    isPnlEnabled;

  const tracifyV2Enabled = posthogFeatureFlags?.tracify_v2?.enabled;

  const navigationItems = navigation.filter((item) => {
    let include = true;
    if (!isShopifyOrCustomStore) {
      include = include && item.name !== "First-Party-Tracking";
    }
    if (!isShopifySystem) {
      include = include && item.name !== "Costs & Expenses";
    }
    if (!tracifyV2Enabled) {
      include = include && item.name !== "Presets";
    }
    return include;
  });

  return (
    <div className="flex flex-col flex-grow py-6 bg-gray-200 dark:bg-gray-700 overflow-y-auto">
      <div className="flex items-center flex-shrink-0 px-4">
        <Image width={166.4} height={28} alt="logo" src={logo} priority />
      </div>

      <div className="mt-4 flex-grow flex flex-col ">
        <div className="px-2 mt-4">
          <SidebarStoreSelector
            storeOptions={selectedStoreIds}
            onStoreChanged={onStoreChanged}
          />
        </div>
        <nav
          className="flex-1 mt-6 px-2 space-y-8 bg-gray-200 dark:bg-gray-700 flex flex-col justify-between"
          aria-label="Sidebar"
        >
          <div>
            <LinkWithPersistentQuery
              passHref
              href={lastVisitedPage}
              className={classNames(
                normalClassNames,
                "group flex items-center py-3 pl-3 pr-1 text-sm font-medium rounded-md relative"
              )}
              id="sidebar-back-to-dashboard"
            >
              <ChevronLeftIcon className="h-4 w-4 mr-2" />
              Back to Dashboard
            </LinkWithPersistentQuery>
            <p className="uppercase text-sm text-gray-600 dark:text-gray-100 px-3 mt-6 mb-2">
              Store Settings
            </p>
            {navigationItems.map((item) => {
              const current = item.href && router.pathname === item.href;

              const className = classNames(
                current ? activeClassNames : normalClassNames,
                "group flex items-baseline py-3 pl-3 pr-1 text-sm font-medium rounded-md relative"
              );

              const content = (
                <>
                  <span
                    className={classNames(
                      current
                        ? "text-on-primary dark:text-gray-50 fill-gray-100 group-hover:fill-gray-800 dark:group-hover:fill-gray-200"
                        : "group-hover:text-background dark:group-hover:text-gray-200 fill-gray-200 group-hover:fill-gray-800 dark:group-hover:fill-gray-200",
                      "mr-3 flex-shrink-0 h-4 w-4 hover:stroke-gray-100 dark:hover:stroke-gray-50"
                    )}
                    aria-hidden="true"
                  >
                    <item.icon />
                  </span>
                  {item.name}
                  {item.label && (
                    <p
                      className={`text-xs ml-2 rounded-md py-0.5 px-1 ${item.color} inline`}
                    >
                      {item.label}
                    </p>
                  )}
                </>
              );
              return (
                <LinkWithPersistentQuery
                  passHref
                  key={item.name}
                  href={item.href ?? ""}
                  className={className}
                  id={item.id}
                >
                  {content}
                </LinkWithPersistentQuery>
              );
            })}
            <p className="uppercase text-sm text-gray-600 dark:text-gray-100 px-3 mt-6 mb-2">
              Organisation Settings
            </p>
            {organisationNavigation.map((item) => {
              const current = item.href && router.pathname === item.href;

              const className = classNames(
                current ? activeClassNames : normalClassNames,
                "group flex items-baseline py-3 pl-3 pr-1 text-sm font-medium rounded-md"
              );

              const content = (
                <>
                  <span
                    className={classNames(
                      current
                        ? "text-on-primary dark:text-gray-50 fill-gray-100 group-hover:fill-gray-800 dark:group-hover:fill-gray-200"
                        : "group-hover:text-background dark:group-hover:text-gray-200 fill-gray-200 group-hover:fill-gray-800 dark:group-hover:fill-gray-200",
                      "mr-3 flex-shrink-0 h-4 w-4 hover:stroke-gray-100 dark:hover:stroke-gray-50"
                    )}
                    aria-hidden="true"
                  >
                    <item.icon />
                  </span>
                  {item.name}
                </>
              );
              return (
                <LinkWithPersistentQuery
                  passHref
                  key={item.name}
                  href={item.href ?? ""}
                  className={className}
                >
                  {content}
                </LinkWithPersistentQuery>
              );
            })}
          </div>
        </nav>
      </div>
    </div>
  );
};

export default SettingsSidebar;
