import React from "react";

const FirstPartyTrackingIcon = ({
  color,
  className,
}: {
  color: string;
  className?: string;
}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M16.6668 10.8333C16.6668 15 13.7502 17.0833 10.2835 18.2917C10.102 18.3532 9.90478 18.3502 9.72516 18.2833C6.25016 17.0833 3.3335 15 3.3335 10.8333V5C3.3335 4.77898 3.42129 4.56702 3.57757 4.41074C3.73385 4.25446 3.94582 4.16666 4.16683 4.16666C5.8335 4.16666 7.91683 3.16666 9.36683 1.9C9.54337 1.74916 9.76796 1.66629 10.0002 1.66629C10.2324 1.66629 10.457 1.74916 10.6335 1.9C12.0918 3.175 14.1668 4.16666 15.8335 4.16666C16.0545 4.16666 16.2665 4.25446 16.4228 4.41074C16.579 4.56702 16.6668 4.77898 16.6668 5V10.8333Z"
        stroke="currentColor"
        strokeWidth="1.45833"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FirstPartyTrackingIcon;
