import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { z } from "zod";
import { useAuth } from "../useAuth";
import { transformToAccounts } from "./transformations";
import { AdAccounts } from "./types";
import { AdConnectorAPIError } from "@api/types/backendTypes";

export const FetchAdAccountsSchema = z.object({
  token: z.string({ required_error: "User Session not available" }),
});

export type FetchAdAccountsProps = z.infer<typeof FetchAdAccountsSchema>;

async function fetchAdAccounts({
  token,
}: FetchAdAccountsProps): Promise<AdAccounts> {
  if (!token) {
    throw new Error("User Session not available");
  }
  if (process.env.NEXT_PUBLIC_BACKEND_BASE_URL?.includes("dev")) {
    console.log("DEV");
    return [];
  }
  try {
    FetchAdAccountsSchema.parse({ token });
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_AD_CONNECTOR_V1_BASE_URL}/account/list?limit=100000`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": token,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(response.data.error);
    }
    // Inferred return type: Promise<MyData>
    return transformToAccounts(response.data?.items ?? []);
  } catch (err: any) {
    if (axios.isAxiosError(err)) {
      if (!err.response) {
        throw err;
      }
      const axiosError = err as AxiosError;
      const apiError = axiosError.response?.data as AdConnectorAPIError;
      const firstErr = apiError.detail[0];
      throw new Error(firstErr?.msg ?? axiosError.message);
    }
    throw new Error("Unknown error occured!");
  }
}

export type UseAdAccountsOptions = {
  enabled?: boolean;
  onSuccess?: ((data: AdAccounts) => void) | undefined;
};

export function useAdAccounts(options?: UseAdAccountsOptions) {
  const { data } = useAuth();
  return useQuery({
    queryKey: ["adAccounts"],
    queryFn: () => fetchAdAccounts({ token: data?.token! }),
    ...options,
    staleTime: 60 * 1000,
    gcTime: 60 * 1000,
    refetchOnWindowFocus: false,
    enabled:
      options?.enabled !== undefined
        ? options.enabled && !!data?.token
        : !!data?.token,
  });
}
