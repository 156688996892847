import LinkWithPersistentQuery from "@components/LinkWithQuery/LinkWithQuery";
import { Button } from "@components/ui/button";
import { ChevronLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { CONSTANT_LABELS } from "constants/constants";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useState } from "react";

type Props = {
  onClose: () => void;
  open?: boolean;
  channel: string;
};

const EmptyDashboardModal = ({ onClose, open, channel }: Props) => {
  const router = useRouter();
  const [previousPath, setPreviousPath] = useState<string | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      setPreviousPath(router.asPath);
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router]);

  const handleGoBack = useCallback(() => {
    if (previousPath && previousPath.startsWith("/login")) {
      router.push("/");
    } else {
      router.back();
    }
  }, [previousPath, router]);

  if (!mounted || !open) return null;

  return (
    <div className="absolute z-[50]  inset-0 z-popover overflow-y-auto">
      <div className="min-h-screen text-center">
        <div className="fixed inset-0 bg-black/30 backdrop-blur-sm" />

        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>

        <div className="inline-block w-full max-w-3xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-100 dark:bg-gray-800 rounded-lg shadow-xl relative">
          <button
            onClick={handleGoBack}
            aria-label="Close panel"
            className="hover:text-gray-200 transition ease-in-out duration-150 focus:outline-none absolute right-0 top-0 m-2"
          >
            <XMarkIcon className="h-5 w-5" />
          </button>

          <h3 className="text-lg font-medium leading-6 mb-4">
            No {CONSTANT_LABELS[channel] ?? channel} accounts connected...
          </h3>

          <div className="flex flex-col">
            <div className="flex-1">
              <div className="pb-12">
                <p>
                  You have not connected any{" "}
                  {CONSTANT_LABELS[channel] ?? channel} accounts to the current
                  store selection yet. Please connect a{" "}
                  {CONSTANT_LABELS[channel] ?? channel} account to view data.
                </p>
              </div>
            </div>
            <div className="flex justify-end space-x-2">
              <LinkWithPersistentQuery href="/settings/integrations">
                <Button variant="primary">Connect Account</Button>
              </LinkWithPersistentQuery>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyDashboardModal;
