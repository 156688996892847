import React from "react";
import s from "./input.module.css";

export interface InputBlockLabelLabel {
  name?: string;
}

const InputBlockLabel: React.FC<
  React.PropsWithChildren<InputBlockLabelLabel>
> = ({ children, name }) => {
  return (
    <label
      className={`${s.label} block font-semibold text-foreground text-sm mb-2`}
      id={[name, "label"].join("-")}
      htmlFor={name}
    >
      {children}
    </label>
  );
};

const MemoInputBlockLabel = React.memo(InputBlockLabel);

export default MemoInputBlockLabel;
