import dayjs from "dayjs";
import React from "react";

const XAxisTicksWithYear = ({
  rechartsProps: { x, y, payload, ...props },
  xAxisTicks,
}: {
  rechartsProps: any;
  xAxisTicks: Array<string>;
}) => {
  const index = xAxisTicks.indexOf(payload.value);
  const isDifferentYear =
    index === 0 ||
    index === xAxisTicks.length - 1 ||
    (dayjs(payload.value).isSame(dayjs(xAxisTicks[index - 1]), "year") ===
      false &&
      index < xAxisTicks.length - 2); // if the next tick is the last tick, don't show the year here

  const text = isDifferentYear
    ? [
        dayjs(payload.value).format("DD MMM"),
        dayjs(payload.value).format("YYYY"),
      ]
    : [dayjs(payload.value).format("DD MMM")];
  return (
    <g transform={`translate(${x},${y})`}>
      {text.map((line, i) => (
        <text {...props} key={i} x={0} y={0} dy={i * 15} dx={-18}>
          {line}
        </text>
      ))}
    </g>
  );
};

export default XAxisTicksWithYear;
