/* eslint-disable @next/next/no-img-element */
import React from "react";
import { Button } from "@/components/ui/button";
import { DialogHeader } from "@components/ui/dialog";
import { DialogTitle } from "@radix-ui/react-dialog";
import Image from "next/image";

interface Step2ContentProps {
  onNext: () => void;
}

export function Step2Content({ onNext }: Step2ContentProps) {
  return (
    <>
      <DialogHeader className="-mt-6 -mx-6 overflow-hidden w-[798px ] h-[390px]">
        <img
          alt="logo"
          src={"/static/tracify-presets-onboarding.jpg"}
          className="w-[798px] h-[390px]"
        />
      </DialogHeader>
      <div className="space-y-6 flex flex-col">
        <div className="grid grid-cols-[1fr,2.25fr] gap-4">
          <h4 className="text-xl font-semibold">What are presets?</h4>
          <div className="grid space-y-4">
            <p>
              Presets bundle your favorite attribution settings. This makes it
              simple to use the same setup for reports anytime. You can switch
              between them freely. A preset contains:
            </p>
            <ul className="list-disc pl-5 space-y-2">
              <li>Attribution Model</li>
              <li>Channel Touchpoints</li>
              <li>Attribution Window</li>
              <li>Report Time</li>
            </ul>
          </div>
        </div>
        <Button className="self-end" onClick={onNext}>
          Continue
        </Button>
      </div>
    </>
  );
}
