import React, { useEffect, useMemo, useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ComposedChart,
  Label,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useAtom } from "jotai";
import {
  comparedAtom,
  dashboardModeAtom,
  endTimeAtom,
  newVsReturningAtom,
  startTimeAtom,
} from "atoms";
import {
  chartTooltipStyles,
  GENERAL_METRICS_LABELS,
  METRICS_LABELS,
} from "constants/constants";
import { MarketingChannelOverviewInterface } from "interface/MarketingChannelOverviewInterface";
import dayjs from "dayjs";
import { getNearestAxisValue } from "@components/ChannelControlMetrics/ConversionValueChart";
import { kpiCalculations } from "@lib/util-functions";
import useFormat from "@lib/hooks/use-format";
import useCurrency from "@lib/hooks/use-currency";
import { NewVsReturningType } from "@api/types/backendTypes";
import XAxisTicksWithYear from "@components/Charts/XAxisTicksWithYear";

export type DailyChannelData = {
  [key: string]:
    | MarketingChannelOverviewInterface
    | MarketingChannelOverviewInterface[];
};

type Props = {
  title: string;
  tooltip?: React.ReactNode;
  dailyChannelData: {
    new: DailyChannelData;
    returning: DailyChannelData;
    all: DailyChannelData;
    compared?: {
      new: DailyChannelData;
      returning: DailyChannelData;
      all: DailyChannelData;
    };
  };
  trackedOrdersRatio?: { [date: string]: number };
  dataKeys: (keyof typeof kpiCalculations)[];
  unit: string;
  colors: { [key: string]: string };
  roundTotalValues?: number;
  loading: boolean;
  helperFormatFn?: (value: number, decimals: number, symbol: boolean) => string;
};

const getChartData = ({
  dailyChannelData,
  unit,
  dataKeys,
  trackedOrdersRatio,
  newVsReturning,
  comparedTimerange,
}: {
  newVsReturning: NewVsReturningType;
  dailyChannelData: Props["dailyChannelData"];
  unit: string;
  dataKeys: Props["dataKeys"];
  trackedOrdersRatio: Props["trackedOrdersRatio"];
  comparedTimerange?: number;
}) => {
  const currentChartData: { [key: string]: any }[] = [];

  let currentMaxValue = 0;
  // we use the "all" attribute, because it has to include both datasets and therefore must include ALL dates
  const allNewValues: MarketingChannelOverviewInterface[] = [];
  const allReturningValues: MarketingChannelOverviewInterface[] = [];
  const allValues: MarketingChannelOverviewInterface[] = [];

  Object.entries(dailyChannelData.all).forEach(([date, entry]) => {
    const newValue = dailyChannelData.new ? dailyChannelData.new[date] : null;
    const returningValue = dailyChannelData.returning
      ? dailyChannelData.returning[date]
      : null;
    Array.isArray(entry)
      ? allValues.push(...entry)
      : entry
        ? allValues.push(entry)
        : null;
    Array.isArray(newValue)
      ? allNewValues.push(...newValue)
      : newValue
        ? allNewValues.push(newValue)
        : null;
    Array.isArray(returningValue)
      ? allReturningValues.push(...returningValue)
      : returningValue
        ? allReturningValues.push(returningValue)
        : null;

    const valueData: { [key: string]: any } = {};
    if (comparedTimerange) {
      valueData["date"] = dayjs(date)
        .add(comparedTimerange, "day")
        .format("YYYY-MM-DD");
    } else {
      valueData["date"] = date;
    }
    for (const key of dataKeys) {
      let allDailyValue: any = 0;
      let newDailyValue: any = 0;
      let returningDailyValue: any = 0;
      const conversionFunction = kpiCalculations[key];
      if (!!conversionFunction) {
        if (newValue) {
          newDailyValue = conversionFunction(newValue, true);
        }
        if (returningValue) {
          returningDailyValue = conversionFunction(returningValue);
        }
        if (entry) {
          allDailyValue = conversionFunction(entry);
        }
      }
      if (
        ["cr"].includes(key) &&
        trackedOrdersRatio &&
        trackedOrdersRatio[date]
      ) {
        returningDailyValue = returningDailyValue / trackedOrdersRatio[date];
        newDailyValue = newDailyValue / trackedOrdersRatio[date];
        allDailyValue = allDailyValue / trackedOrdersRatio[date];
      }

      if (allDailyValue !== undefined) {
        if (typeof allDailyValue === "number") {
          if (unit?.trim() === "%") {
            valueData[key] = allDailyValue * 100;
          } else {
            valueData[key] = allDailyValue;
          }
          if (valueData[key] > currentMaxValue) {
            currentMaxValue = valueData[key];
          }
        } else {
          valueData[key] = allDailyValue;
        }
      }

      if (newDailyValue !== undefined) {
        if (typeof newDailyValue === "number") {
          if (unit?.trim() === "%") {
            valueData["new_" + key] = newDailyValue * 100;
          } else {
            valueData["new_" + key] = newDailyValue;
          }
          if (valueData["new_" + key] > currentMaxValue) {
            currentMaxValue = valueData["new_" + key];
          }
        } else {
          valueData["new_" + key] = newDailyValue;
        }
      }

      if (returningDailyValue !== undefined) {
        if (typeof returningDailyValue === "number") {
          if (unit?.trim() === "%") {
            valueData["returning_" + key] = returningDailyValue * 100;
          } else {
            valueData["returning_" + key] = returningDailyValue;
          }
          if (valueData["returning_" + key] > currentMaxValue) {
            currentMaxValue = valueData["returning_" + key];
          }
        } else {
          valueData["returning_" + key] = returningDailyValue;
        }
      }
    }

    currentChartData.push(valueData);
  });
  const totals: {
    [key: string]: {
      allTotal: number;
      newTotal: number;
      returningTotal: number;
    };
  } = {};
  for (const key of dataKeys) {
    let newTotal = 0;
    let returningTotal = 0;
    let allTotal = 0;
    const conversionFunction = kpiCalculations[key];
    if (!!conversionFunction) {
      if (allNewValues) {
        newTotal = conversionFunction(allNewValues, true);
      }
      if (allReturningValues) {
        returningTotal = conversionFunction(allReturningValues);
      }
      if (allValues) {
        allTotal = conversionFunction(allValues);
      }
    }
    if (unit?.trim() === "%") {
      totals[key] = {
        returningTotal: newVsReturning !== "new" ? returningTotal * 100 : 0,
        newTotal: newVsReturning !== "returning" ? newTotal * 100 : 0,
        allTotal: allTotal * 100,
      };
    } else {
      totals[key] = {
        returningTotal: newVsReturning !== "new" ? returningTotal : 0,
        newTotal: newVsReturning !== "returning" ? newTotal : 0,
        allTotal: allTotal,
      };
    }
  }
  return {
    currentChartData,
    currentMaxValue,
    allNewValues,
    allReturningValues,
    allValues,
    totals,
  };
};

/**
 * Component representing the charts in store performance sector.
 *
 * @param {Props} props
 * @return {CustomElements}
 */
export function StorePerformanceChart({
  title,
  tooltip,
  dailyChannelData,
  dataKeys,
  unit,
  colors,
  loading,
  trackedOrdersRatio,
  roundTotalValues,
  helperFormatFn,
}: Props) {
  const [chartData, setChartData] = useState<{ [key: string]: any }[]>([]);
  const [newVsReturning] = useAtom(newVsReturningAtom);
  const { formatNumber } = useFormat();
  const [dashboardMode] = useAtom(dashboardModeAtom);

  const metricLabels =
    dashboardMode === "general" ? GENERAL_METRICS_LABELS : METRICS_LABELS;
  const { globalCurrencySymbol } = useCurrency();
  const [maxValue, setMaxValue] = useState(0);
  const minDateFromComparedDaily = useMemo(
    () =>
      Math.min(
        ...Object.keys(dailyChannelData.compared?.all ?? {}).map((el) =>
          dayjs(el).unix()
        )
      ),
    [dailyChannelData.compared?.all]
  );
  const maxDateFromDaily = useMemo(
    () =>
      Math.max(
        ...Object.keys(dailyChannelData.all).map((el) => dayjs(el).unix())
      ),
    [dailyChannelData.all]
  );

  const minDateFromDaily = useMemo(
    () =>
      Math.min(
        ...Object.keys(dailyChannelData.all).map((el) => dayjs(el).unix())
      ),
    [dailyChannelData.all]
  );
  const timerange = useMemo(
    () =>
      dayjs(minDateFromDaily * 1000).diff(
        dayjs(minDateFromComparedDaily * 1000),
        "day"
      ),
    [minDateFromDaily, minDateFromComparedDaily]
  );
  const [totalNumbers, setTotalNumbers] = useState<{
    [key: string]: {
      allTotal: number;
      newTotal: number;
      returningTotal: number;
    };
  }>({});
  const [comparedTotalNumbers, setComparedTotalNumbers] = useState<{
    [key: string]: {
      allTotal: number;
      newTotal: number;
      returningTotal: number;
    };
  }>({});
  // don't use the atom value for compared here, because we want to re-render the chart only when the compared data changes
  // to keep the loading UX consistent
  const compared = Boolean(
    Object.keys(dailyChannelData.compared?.all ?? {}).length > 0
  );
  useEffect(() => {
    setChartData([]);
    setMaxValue(0);
    if (Object.values(dailyChannelData.all).length > 0) {
      const chartData = getChartData({
        dailyChannelData,
        unit,
        dataKeys,
        trackedOrdersRatio,
        newVsReturning,
      });
      const { currentChartData, currentMaxValue, totals } = chartData;
      let comparedData: typeof chartData | undefined;
      if (compared && timerange > 0 && dailyChannelData.compared) {
        comparedData = getChartData({
          dailyChannelData: dailyChannelData.compared,
          unit,
          dataKeys,
          trackedOrdersRatio,
          newVsReturning,
          comparedTimerange: timerange,
        });
        const keys = [];
        for (const key of dataKeys) {
          if (newVsReturning !== "default") {
            keys.push(key);
            keys.push("new_" + key);
            keys.push("returning_" + key);
          } else {
            keys.push(key);
          }
        }
        for (const value of comparedData.currentChartData) {
          const indexInCurrent = currentChartData.findIndex(
            (el) => el.date === value.date
          );
          if (indexInCurrent !== -1) {
            for (const key of keys) {
              currentChartData[indexInCurrent][`compared_${key}`] = value[key];
            }
          }
        }
        setComparedTotalNumbers(comparedData.totals);
      }
      setChartData(currentChartData);
      setMaxValue(
        comparedData?.currentMaxValue
          ? Math.max(currentMaxValue, comparedData.currentMaxValue)
          : currentMaxValue
      );
      setTotalNumbers(totals);
    }
  }, [
    dailyChannelData,
    dataKeys,
    trackedOrdersRatio,
    unit,
    newVsReturning,
    compared,
    timerange,
  ]);

  const [xAxisTicks, setXAxisTicks] = useState(
    dailyChannelData?.all
      ? Object.keys(dailyChannelData.all)
      : Object.keys(dailyChannelData)
  );

  useEffect(() => {
    function setXAxisTicksHandler() {
      const sessionChart = document.getElementById(
        `${dataKeys.join("-")}-chart-container`
      );
      const width = sessionChart?.clientWidth ?? 0;
      const maxAmountOfTicks = Math.floor((width - 300) / (36 + 15)); // the labels are roughly 36px wide + a 15px spacing
      const dailyKeys = dailyChannelData?.all
        ? Object.keys(dailyChannelData.all)
        : Object.keys(dailyChannelData);
      const dateKeys = dailyKeys.length;
      const keysIndexToChoose = Math.ceil(dateKeys / maxAmountOfTicks);
      const keysToDisplay = dailyKeys
        .map((el, index) => (index % keysIndexToChoose === 0 ? el : null))
        .filter((el) => Boolean(el)) as string[];
      setXAxisTicks(keysToDisplay);
    }
    setXAxisTicksHandler();
    window.addEventListener("resize", setXAxisTicksHandler);
    return () => {
      window.removeEventListener("resize", setXAxisTicksHandler);
    };
  }, [dailyChannelData, dataKeys]);

  const maxYDomain = useMemo(() => getNearestAxisValue(maxValue), [maxValue]);
  if (loading) {
    return (
      <div className="w-full h-full min-h-72 border-black p-6 flex flex-col rounded-lg bg-gray-100 dark:bg-gray-800">
        <div className="w-full h-full animate-pulse">
          <div className="h-7 w-48 rounded-md bg-gray-200 dark:bg-gray-700" />

          <div className="w-full h-60 animate-pulse bg-gray-200 dark:bg-gray-700 rounded-lg mt-4"></div>
        </div>
      </div>
    );
  }
  const customerLabel =
    dashboardMode === "general" ? "conversions" : "customers";
  return (
    <div className="w-full h-full min-h-72 border-black p-6 flex flex-col rounded-lg bg-gray-100 dark:bg-gray-800 ">
      <div className="flex justify-between items-baseline">
        <div>
          <div className="flex items-center">
            <h3 className="h3">{title}</h3>
            {tooltip}
          </div>
          <p className="text-foreground-soft text-xs">
            {newVsReturning === "default"
              ? "All " + customerLabel
              : "New vs returning " + customerLabel}
          </p>
        </div>
        <div className="flex flex-col space-y-2 xl:space-y-0 xl:flex-row xl:space-x-4">
          {newVsReturning === "all" || newVsReturning === "default" ? (
            <div>
              <div className="flex items-center text-foreground-soft">
                <p className=" text-xs">Total</p>
              </div>
              <p className="font-bold text-lg">
                {helperFormatFn
                  ? helperFormatFn(
                      totalNumbers[dataKeys[0]]?.allTotal ?? 0,
                      roundTotalValues ?? 0,
                      true
                    )
                  : `${formatNumber(
                      totalNumbers[dataKeys[0]]?.allTotal ?? 0,
                      roundTotalValues ?? 0
                    )}${unit}`}
              </p>
              {compared ? (
                <p className="text-xs text-foreground-soft">
                  vs{" "}
                  {helperFormatFn
                    ? helperFormatFn(
                        comparedTotalNumbers[dataKeys[0]]?.allTotal ?? 0,
                        roundTotalValues ?? 0,
                        true
                      )
                    : `${formatNumber(
                        comparedTotalNumbers[dataKeys[0]]?.allTotal ?? 0,
                        roundTotalValues ?? 0
                      )}${unit}`}
                </p>
              ) : null}
            </div>
          ) : null}
          {newVsReturning !== "default" ? (
            <>
              <div>
                <div className="flex items-center text-foreground-soft">
                  <div className="h-3 w-3 rounded-sm bg-greenRect mr-2"></div>
                  <p className=" text-xs">New</p>
                </div>
                <p className="font-bold text-lg">
                  {newVsReturning !== "returning"
                    ? helperFormatFn
                      ? helperFormatFn(
                          totalNumbers[dataKeys[0]]?.newTotal,
                          roundTotalValues ?? 0,
                          true
                        )
                      : formatNumber(
                          totalNumbers[dataKeys[0]]?.newTotal,
                          roundTotalValues ?? 0
                        ) + unit
                    : "-"}
                </p>
                {compared ? (
                  <p className="text-xs text-foreground-soft">
                    vs{" "}
                    {newVsReturning !== "returning"
                      ? helperFormatFn
                        ? helperFormatFn(
                            comparedTotalNumbers[dataKeys[0]]?.newTotal,
                            roundTotalValues ?? 0,
                            true
                          )
                        : formatNumber(
                            comparedTotalNumbers[dataKeys[0]]?.newTotal,
                            roundTotalValues ?? 0
                          ) + unit
                      : "-"}
                  </p>
                ) : null}
              </div>
              <div>
                <div className="flex items-center text-foreground-soft">
                  <div className="h-3 w-3 rounded-sm bg-blueRect mr-2"></div>
                  <p className=" text-xs">Returning </p>
                </div>
                <p className="font-bold text-lg">
                  {newVsReturning !== "new"
                    ? helperFormatFn
                      ? helperFormatFn(
                          totalNumbers[dataKeys[0]]?.returningTotal,
                          roundTotalValues ?? 0,
                          true
                        )
                      : formatNumber(
                          totalNumbers[dataKeys[0]]?.returningTotal,
                          roundTotalValues ?? 0
                        ) + unit
                    : "-"}
                </p>
                {compared ? (
                  <p className="text-xs text-foreground-soft">
                    vs{" "}
                    {newVsReturning !== "new"
                      ? helperFormatFn
                        ? helperFormatFn(
                            comparedTotalNumbers[dataKeys[0]]?.returningTotal,
                            roundTotalValues ?? 0,
                            true
                          )
                        : formatNumber(
                            comparedTotalNumbers[dataKeys[0]]?.returningTotal,
                            roundTotalValues ?? 0
                          ) + unit
                      : "-"}
                  </p>
                ) : null}
              </div>
            </>
          ) : null}
        </div>
      </div>

      <div className="w-full h-60 bg-gray-100 dark:bg-gray-800 mt-4">
        <ResponsiveContainer
          width="100%"
          height="100%"
          id={`${dataKeys.join("-")}-chart-container`}
        >
          <ComposedChart
            data={chartData}
            margin={{ top: 30, bottom: 5, right: 24 }}
          >
            <defs>
              {dataKeys.map((key, index) => {
                return (
                  <React.Fragment key={`${key}${index}`}>
                    <linearGradient id={key} x1="0" y1="0" x2="0" y2="1">
                      <stop
                        offset="5%"
                        stopColor={colors[key]}
                        stopOpacity={compared ? 0.25 : 0.4}
                      />
                      <stop
                        offset="95%"
                        stopColor={colors[key]}
                        stopOpacity={0}
                      />
                    </linearGradient>
                    <linearGradient
                      id={"new_" + key}
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop
                        offset="5%"
                        stopColor={colors["new_" + key]}
                        stopOpacity={compared ? 0.25 : 0.4}
                      />
                      <stop
                        offset="95%"
                        stopColor={colors["new_" + key]}
                        stopOpacity={0}
                      />
                    </linearGradient>
                    <linearGradient
                      id={"returning_" + key}
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop
                        offset="5%"
                        stopColor={colors["returning_" + key]}
                        stopOpacity={compared ? 0.25 : 0.4}
                      />
                      <stop
                        offset="95%"
                        stopColor={colors["returning_" + key]}
                        stopOpacity={0}
                      />
                    </linearGradient>
                  </React.Fragment>
                );
              })}
            </defs>
            <XAxis
              dataKey="date"
              tickSize={0}
              tickMargin={20}
              axisLine={false}
              interval={0}
              textAnchor="left"
              tick={(props) => (
                <XAxisTicksWithYear
                  rechartsProps={props}
                  xAxisTicks={xAxisTicks}
                />
              )}
              domain={[
                dayjs(minDateFromDaily * 1000).format("MM-DD"),
                dayjs(maxDateFromDaily * 1000).format("MM-DD"),
              ]}
              ticks={xAxisTicks}
            />
            <CartesianGrid stroke="var(--chart-grid)" vertical={false} />
            <YAxis
              tickFormatter={(value: number) =>
                `${
                  helperFormatFn
                    ? helperFormatFn(value, 0, false).replace(" ", "")
                    : formatNumber(value, 0)
                }`
              }
              dataKey="new"
              // unit={helperFormatFn ? "" : unit}
              tickSize={0}
              width={70}
              tickMargin={10}
              axisLine={false}
              tickCount={
                maxYDomain % 5 === 0
                  ? 6
                  : maxYDomain % 4 === 0
                    ? 5
                    : maxYDomain % 3 === 0
                      ? 4
                      : maxYDomain % 2 === 0
                        ? 3
                        : 2
              }
              domain={[0, maxYDomain]}
            >
              <Label
                value={`${title} in ${
                  helperFormatFn ? globalCurrencySymbol : unit
                }`}
                offset={20}
                angle={-90}
                position={{
                  x: 20,
                  y: 20,
                }}
                textAnchor="middle"
              />
            </YAxis>

            <Tooltip
              contentStyle={chartTooltipStyles}
              formatter={(value: string, name: string) => {
                let prefix = "";
                if (name.includes("compared_")) {
                  name = name.replace("compared_", "");
                  prefix += "Previous ";
                }
                if (name.includes("new_")) {
                  name = name.replace("new_", "");
                  prefix += "New ";
                }
                if (name.includes("returning_")) {
                  name = name.replace("returning_", "");
                  prefix += "Returning ";
                }
                return [
                  unit?.trim() !== ""
                    ? `${
                        helperFormatFn
                          ? helperFormatFn(parseFloat(value), 2, true)
                          : `${formatNumber(parseFloat(value), 2)} ${unit}`
                      }`
                    : formatNumber(parseFloat(value), 1),
                  `${prefix}${metricLabels[name]}`,
                ];
              }}
            />
            {dataKeys.map((key) => {
              return (
                <React.Fragment key={key}>
                  {newVsReturning === "default" ? (
                    <>
                      <Area
                        type="monotone"
                        dataKey={key}
                        key={key}
                        stroke={colors[key]}
                        dot={false}
                        strokeWidth={2}
                        fillOpacity={1}
                        fill={`url(#${key})`}
                      />
                      {compared ? (
                        <Line
                          type="monotone"
                          dataKey={`compared_${key}`}
                          key={`compared_${key}`}
                          stroke={colors[key]}
                          dot={false}
                          strokeWidth={2}
                          strokeDasharray={"5 5"}
                        />
                      ) : null}
                    </>
                  ) : null}
                  {newVsReturning === "new" || newVsReturning === "all" ? (
                    <>
                      <Area
                        type="monotone"
                        dataKey={"new_" + key}
                        key={"new_" + key}
                        stroke={colors["new_" + key]}
                        dot={false}
                        strokeWidth={2}
                        fillOpacity={1}
                        fill={`url(#${"new_" + key})`}
                      />
                      {compared ? (
                        <Line
                          type="monotone"
                          dataKey={"compared_new_" + key}
                          key={"compared_new_" + key}
                          stroke={colors["new_" + key]}
                          dot={false}
                          strokeWidth={2}
                          strokeDasharray={"5 5"}
                        />
                      ) : null}
                    </>
                  ) : null}
                  {newVsReturning === "returning" ||
                  newVsReturning === "all" ? (
                    <>
                      <Area
                        type="monotone"
                        dataKey={"returning_" + key}
                        key={"returning_" + key}
                        stroke={colors["returning_" + key]}
                        dot={false}
                        strokeWidth={2}
                        fillOpacity={1}
                        fill={`url(#${"returning_" + key})`}
                      />
                      {compared ? (
                        <Line
                          type="monotone"
                          dataKey={"compared_returning_" + key}
                          key={"compared_returning_" + key}
                          stroke={colors["returning_" + key]}
                          dot={false}
                          strokeWidth={2}
                          strokeDasharray={"5 5"}
                        />
                      ) : null}
                    </>
                  ) : null}
                </React.Fragment>
              );
            })}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
