import axios, { AxiosError } from "axios";
import {
  SiterefListFetchRequest,
  Siteref,
  SiterefListRequest,
  RequestSiterefCreateRequest,
  APIError,
} from "@api/types/backendTypes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "../useAuth";
import { useMemo } from "react";
import { useAtom } from "jotai";
import { selectedStoreIdsAtom } from "atoms";

async function fetchSiteRefs({
  session,
  masterOrAdminUser,
  storeIds,
}: SiterefListFetchRequest): Promise<Array<Siteref>> {
  if (!session) {
    throw new Error("User Session not available");
  }
  if (!masterOrAdminUser && !storeIds) {
    throw new Error("No stores available to fetch site refs");
  }
  try {
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}/siteref/list`,
      {},
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": session,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(response.data.error);
    }
    // Inferred return type: Promise<MyData>
    return response.data.result.siterefs as Array<Siteref>;
  } catch (err: unknown) {
    if (axios.isAxiosError(err)) {
      if (!err.response) {
        throw err;
      }
      const axiosError = err as AxiosError;
      const errorMessage = axiosError.response?.data as {
        error: string;
        success: boolean;
      };
      throw new Error(errorMessage.error ?? axiosError.message);
    }
    throw new Error("Unknown error occurred!");
  }
}
export type UseSiteRefsOptions = {
  enabled?: boolean;
  onSuccess?: ((data: Array<Siteref>) => void) | undefined;
};
function useSiteRefs(props: SiterefListRequest, options?: UseSiteRefsOptions) {
  const { data } = useAuth();
  const [selectedStoreIds] = useAtom(selectedStoreIdsAtom);

  const enabled = useMemo(() => {
    const defaultEnabled =
      !!data?.token && !!selectedStoreIds && selectedStoreIds?.length > 0;
    if (options?.enabled) {
      return options.enabled && defaultEnabled;
    }
    return defaultEnabled;
  }, [options, data, selectedStoreIds]);

  return useQuery({
    queryKey: ["siterefs"],
    queryFn: () =>
      fetchSiteRefs({
        ...props,
        session: data?.token!,
        storeIds: selectedStoreIds,
      }),
    ...options,
    refetchOnWindowFocus: false,
    staleTime: 60 * 1000, // 60 seconds
    enabled: enabled,
  });
}

const useRequestCreateSiteref = () => {
  const queryClient = useQueryClient();
  const { data } = useAuth();
  return useMutation({
    mutationFn: async ({
      organisationId,
      type,
    }: RequestSiterefCreateRequest) => {
      if (!data?.token) {
        throw new Error("User Session not available");
      }
      if (["tiktok", "fb", "google", "pinterest"].indexOf(type) < 0) {
        throw new Error(
          `This account type (${type}) is currently not supported`
        );
      }
      if (!organisationId) {
        throw new Error(
          "You must select an organisation to connect an ad account."
        );
      }
      // console.log("CONNECTING TIKTOK ACC TO CSID", organisationId);
      // await new Promise(function (resolve) {
      //   setTimeout(resolve, 2000);
      // });
      const formData = new FormData();
      formData.append("organisation_id", organisationId);
      try {
        // for adconnector map 'fb' to 'facebook'
        const response = await axios.post(
          `https://adconnector.tracify.ai/${
            type !== "fb" ? type : "facebook"
          }/connect/`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
              "tracify-token": data?.token ?? "",
            },
            withCredentials: true,
          }
        );
        if (response.status !== 200) {
          throw new Error(response.data.error);
        }

        // TODO validate result and add types
        const redirect_to = response.data.redirect_to;
        window.location.href = redirect_to;
        return response.data.result;
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          if (!err.response) {
            throw err;
          }
          const axiosError = err as AxiosError;
          const apiError = axiosError.response?.data as APIError;
          throw new Error(apiError?.error ?? axiosError.message);
        }
        throw new Error("Unknown error occurred!");
      }
    },
    onSuccess: (data, values) =>
      queryClient.invalidateQueries({ queryKey: ["siterefs"] }),
  });
};

export { useSiteRefs, useRequestCreateSiteref };
