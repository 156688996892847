import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@components/ui/dropdown-menu";
import {
  AdjustmentsHorizontalIcon,
  BookOpenIcon,
  CheckIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/24/outline";
import {
  attributionMenuOpen,
  attributionModelAtom,
  attributionWindowAtom,
  coreSystemAtom,
  reportTimeAtom,
  selectedMarketingChannelsAtom,
} from "atoms";
import {
  ATTRIBUTION_MODEL_OPTIONS,
  ATTRIBUTION_WINDOW_OPTIONS,
  REPORT_TIME_OPTIONS,
  CHANNEL_SELECT_OPTIONS,
  AVAILABLE_MARKETING_CHANNEL_OPTIONS,
  PAID_CHANNELS,
  CONSTANT_LABELS,
} from "constants/constants";
import { useAtom } from "jotai";
import React, { useMemo } from "react";
import { useDebounce } from "usehooks-ts";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from "@components/ui/select";
import { Button } from "@components/ui/button";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui";
import { getLocalStorage } from "@lib/util-functions/getLocalStorage";
import { STORAGE_KEYS } from "@lib/hooks/constants";
import AttributionPresetMenu from "./AttributionPresetMenu";

type Props = {
  requiredChannel?: (typeof AVAILABLE_MARKETING_CHANNEL_OPTIONS)[number]["value"];
};

const AttributionMenu = ({ requiredChannel }: Props) => {
  const [coreSystem] = useAtom(coreSystemAtom);

  const [expandSettings, setExpandSettings] = useAtom(attributionMenuOpen);
  const debouncedExpandSettings = useDebounce(expandSettings, 150);
  const [attributionModel, setAttributionModel] = useAtom(attributionModelAtom);
  const [attributionWindow, setAttributionWindow] = useAtom(
    attributionWindowAtom
  );
  const [reportTime, setReportTime] = useAtom(reportTimeAtom);
  const [selectedMarketingChannels, setSelectedMarketingChannels] = useAtom(
    selectedMarketingChannelsAtom
  );

  const selectedChannelsSelectValue = useMemo(() => {
    if (
      selectedMarketingChannels.length ===
      AVAILABLE_MARKETING_CHANNEL_OPTIONS.length
    ) {
      return "all";
    }
    const hasOnlyPaidChannels =
      selectedMarketingChannels.every((channel) =>
        PAID_CHANNELS.some((paidChannel) => paidChannel.value === channel)
      ) && selectedMarketingChannels.length === PAID_CHANNELS.length;
    if (hasOnlyPaidChannels) {
      return "paid";
    }

    return "custom";
  }, [selectedMarketingChannels]);

  const availableAttributionModels = useMemo(() => {
    if (!requiredChannel) {
      return ATTRIBUTION_MODEL_OPTIONS.filter((el) => el.value !== "isolated");
    }
    return ATTRIBUTION_MODEL_OPTIONS;
  }, [requiredChannel]);

  return coreSystem === "v2" ? (
    <AttributionPresetMenu />
  ) : (
    <div className="flex justify-end">
      <div className="mr-2">
        <DropdownMenu>
          <DropdownMenuTrigger asChild id="attribution-model-select">
            <Button
              variant="secondary"
              className={
                attributionModel === "dda_stcb_purchaseatd_t3"
                  ? "bg-gradient-to-l text-white from-aiPurple to-primary"
                  : "text-foreground bg-gray-200 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-700 hover:border-gray-200 dark:hover:border-gray-700"
              }
            >
              {ATTRIBUTION_MODEL_OPTIONS.find(
                (el) => el.value === attributionModel
              )?.label ?? attributionModel}
              {attributionModel === "dda_stcb_purchaseatd_t3" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  className="w-4 h-4 ml-1 fill-white"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M19 9l1.25-2.75L23 5l-2.75-1.25L19 1l-1.25 2.75L15 5l2.75 1.25L19 9zm-7.5.5L9 4 6.5 9.5 1 12l5.5 2.5L9 20l2.5-5.5L17 12l-5.5-2.5zM19 15l-1.25 2.75L15 19l2.75 1.25L19 23l1.25-2.75L23 19l-2.75-1.25L19 15z" />
                </svg>
              ) : null}
              <ChevronUpDownIcon
                className={`w-5 h-5 ml-2 ${
                  attributionModel === "dda_stcb_purchaseatd_t3"
                    ? ""
                    : "text-primary"
                }`}
              />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-84" align="end">
            <DropdownMenuLabel>Attribution Model</DropdownMenuLabel>
            {availableAttributionModels.map((option) => (
              <DropdownMenuItem
                id={"attribution-model-item-" + option.value}
                key={option.value}
                data-value={option.value}
                className={`group attribution-model-item`}
                data-checked={attributionModel === option.value}
                onClick={() => {
                  if (
                    attributionModel === "isolated" &&
                    option.value !== "isolated"
                  ) {
                    const settings = getLocalStorage()?.getItem(
                      STORAGE_KEYS.SETTINGS_BEFORE_ISOLATED
                    );
                    if (settings) {
                      const parsedSettings = JSON.parse(settings);
                      setSelectedMarketingChannels(parsedSettings.touchpoints);
                    }
                  }
                  if (option.value === "isolated" && requiredChannel) {
                    const settings = {
                      attributionModel: attributionModel,
                      touchpoints: selectedMarketingChannels,
                    };
                    getLocalStorage()?.setItem(
                      STORAGE_KEYS.SETTINGS_BEFORE_ISOLATED,
                      JSON.stringify(settings)
                    );
                    setSelectedMarketingChannels([requiredChannel]);
                  }
                  setAttributionModel(option.value);
                }}
              >
                <div className="flex flex-col items-start flex-1 mr-2">
                  <p
                    className={`font-semibold flex items-center ${
                      option.value === "dda_stcb_purchaseatd_t3"
                        ? "py-1 px-2 rounded-md bg-gradient-to-r from-aiPurple to-primary border group-hover:border-foreground-soft transition-colors"
                        : ""
                    }`}
                  >
                    {option.label}
                    {option.value === "dda_stcb_purchaseatd_t3" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        className="w-4 h-4 ml-1 fill-white"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M19 9l1.25-2.75L23 5l-2.75-1.25L19 1l-1.25 2.75L15 5l2.75 1.25L19 9zm-7.5.5L9 4 6.5 9.5 1 12l5.5 2.5L9 20l2.5-5.5L17 12l-5.5-2.5zM19 15l-1.25 2.75L15 19l2.75 1.25L19 23l1.25-2.75L23 19l-2.75-1.25L19 15z" />
                      </svg>
                    ) : null}
                  </p>
                  {option.description && (
                    <p className="text-xs mt-0.5">
                      {option.description}
                      {option.value === "isolated" ? (
                        requiredChannel ? (
                          <b> {CONSTANT_LABELS[requiredChannel]}</b>
                        ) : (
                          requiredChannel
                        )
                      ) : (
                        ""
                      )}
                    </p>
                  )}
                </div>
                {attributionModel === option.value ? (
                  <CheckIcon className="h-4 w-4" />
                ) : (
                  <div className="h-4 w-4" />
                )}
              </DropdownMenuItem>
            ))}
            <DropdownMenuSeparator />
            <DropdownMenuItem
              className="py-3 -m-1 rounded-t-none bg-accent/50"
              id="attribution-learn-more-link"
            >
              <BookOpenIcon className="h-4 w-4" />
              <a
                href="https://knowledge.tracify.ai/en/articles/88333-what-are-the-attribution-models"
                className="underline ml-1"
                target="_blank"
              >
                Learn more about attribution
              </a>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div
        className={`transform transition-all origin-right duration-200 ease-out ${
          expandSettings ? "w-full opacity-1 mr-2" : "w-0 opacity-0"
        } ${
          debouncedExpandSettings && expandSettings ? "" : "overflow-x-hidden"
        }`}
      >
        <div className="flex space-x-2">
          <Select
            defaultValue={attributionWindow}
            value={attributionWindow}
            onValueChange={(val: typeof attributionWindow) =>
              setAttributionWindow(val)
            }
          >
            <SelectTrigger
              id="attribution-window-select"
              className="bg-gray-200 dark:bg-gray-700"
            >
              <SelectValue placeholder="Attribution Window" />
            </SelectTrigger>
            <SelectContent align="end" className="min-w-56">
              <SelectGroup>
                <SelectLabel>Attribution Window</SelectLabel>
                {ATTRIBUTION_WINDOW_OPTIONS.map((option) => (
                  <SelectItem
                    id={"attribution-window-item-" + option.value}
                    key={option.value}
                    value={option.value}
                    data-value={option.value}
                    className="attribution-window-item"
                  >
                    {option.label}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
          <DropdownMenu>
            <Tooltip open={attributionModel === "isolated" ? undefined : false}>
              <TooltipTrigger asChild>
                <div>
                  <DropdownMenuTrigger
                    asChild
                    id="selected-marketing-channels-select"
                    disabled={attributionModel === "isolated"}
                  >
                    <Button
                      variant="secondary"
                      className="text-foreground bg-gray-200 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-700 hover:border-gray-200 dark:hover:border-gray-700"
                    >
                      {selectedMarketingChannels.length === 1
                        ? selectedMarketingChannels[0] === "influencer"
                          ? CONSTANT_LABELS["influencer_module"]
                          : CONSTANT_LABELS[selectedMarketingChannels[0]]
                        : CHANNEL_SELECT_OPTIONS.find(
                            (el) => el.value === selectedChannelsSelectValue
                          )?.label ?? selectedChannelsSelectValue}
                      <ChevronUpDownIcon
                        className={`w-5 h-5 ml-2 text-primary`}
                      />
                    </Button>
                  </DropdownMenuTrigger>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <div className="w-64">
                  <p>
                    You&apos;ve selected <b>Isolated</b> which gives 100%
                    attribution credit to{" "}
                    <b>
                      {requiredChannel
                        ? CONSTANT_LABELS[requiredChannel!]
                        : requiredChannel}
                    </b>
                  </p>
                  <p className="mt-2">
                    To configure other touchpoints please select a different
                    model
                  </p>
                </div>
              </TooltipContent>
            </Tooltip>
            <DropdownMenuContent className="w-84" align="end">
              <DropdownMenuLabel>Touchpoints</DropdownMenuLabel>
              <DropdownMenuGroup>
                {CHANNEL_SELECT_OPTIONS.map((option) =>
                  option.hasSubMenu ? (
                    <DropdownMenuSub key={option.value}>
                      <DropdownMenuSubTrigger
                        data-value={option.value}
                        className="touchpoint-item"
                        data-checked={
                          selectedChannelsSelectValue === option.value
                        }
                        id={"touchpoint-item-" + option.value}
                      >
                        <div className="flex flex-col items-start flex-1 mr-2">
                          <p className={`font-semibold flex items-center`}>
                            {option.label}
                          </p>
                          {option.description && (
                            <p className="text-xs mt-0.5">
                              {option.description}
                            </p>
                          )}
                        </div>
                      </DropdownMenuSubTrigger>
                      <DropdownMenuPortal>
                        <DropdownMenuSubContent>
                          <DropdownMenuCheckboxItem
                            className="select-touchpoint-item"
                            data-value={"deselect-all"}
                            id={"select-touchpoint-item-deselect-all"}
                            checked={
                              selectedMarketingChannels.length ===
                              AVAILABLE_MARKETING_CHANNEL_OPTIONS.length
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              if (
                                selectedMarketingChannels.length ===
                                AVAILABLE_MARKETING_CHANNEL_OPTIONS.length
                              ) {
                                setSelectedMarketingChannels((curr) => [
                                  requiredChannel ?? curr[0],
                                ]);
                              } else {
                                setSelectedMarketingChannels(
                                  AVAILABLE_MARKETING_CHANNEL_OPTIONS.map(
                                    (el) => el.value
                                  )
                                );
                              }
                            }}
                          >
                            {selectedMarketingChannels.length ===
                            AVAILABLE_MARKETING_CHANNEL_OPTIONS.length
                              ? "Deselect all"
                              : "Select all"}
                          </DropdownMenuCheckboxItem>
                          <DropdownMenuSeparator />
                          {option.channels.map((touchpoint) => (
                            <DropdownMenuCheckboxItem
                              className="select-touchpoint-item"
                              data-value={touchpoint.value}
                              key={touchpoint.value}
                              id={"select-touchpoint-item-" + touchpoint.value}
                              checked={selectedMarketingChannels.includes(
                                touchpoint.value
                              )}
                              disabled={
                                (requiredChannel === touchpoint.value ||
                                  selectedMarketingChannels.length === 1) &&
                                selectedMarketingChannels.includes(
                                  touchpoint.value
                                )
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                setSelectedMarketingChannels((curr) => {
                                  const newState = [...curr];
                                  if (newState.includes(touchpoint.value)) {
                                    newState.splice(
                                      newState.indexOf(touchpoint.value),
                                      1
                                    );
                                  } else {
                                    newState.push(touchpoint.value);
                                  }
                                  return newState;
                                });
                              }}
                            >
                              {touchpoint.label}
                            </DropdownMenuCheckboxItem>
                          ))}
                        </DropdownMenuSubContent>
                      </DropdownMenuPortal>
                    </DropdownMenuSub>
                  ) : (
                    <DropdownMenuItem
                      data-value={option.value}
                      className="touchpoint-item"
                      key={option.value}
                      data-checked={
                        selectedChannelsSelectValue === option.value
                      }
                      id={"touchpoint-item-" + option.value}
                      onClick={() => {
                        const channels = option.channels.map(
                          (channel) => channel.value
                        );
                        setSelectedMarketingChannels(channels);
                      }}
                    >
                      <div className="flex flex-col items-start flex-1 mr-2">
                        <p className={`font-semibold flex items-center`}>
                          {option.label}
                        </p>
                        {option.description && (
                          <p className="text-xs mt-0.5">{option.description}</p>
                        )}
                      </div>
                      {selectedChannelsSelectValue === option.value ? (
                        <CheckIcon className="h-4 w-4" />
                      ) : (
                        <div className="h-4 w-4" />
                      )}
                    </DropdownMenuItem>
                  )
                )}
              </DropdownMenuGroup>
              <DropdownMenuSeparator />
              <DropdownMenuItem className="py-3 -m-1 rounded-t-none bg-accent/50">
                <BookOpenIcon className="h-4 w-4" />

                <a
                  href="https://knowledge.tracify.ai/en/articles/22107-how-do-included-multi-channel-touchpoints-influence-your-attribution-data"
                  className="underline ml-1"
                  target="_blank"
                >
                  Learn more about touchpoints
                </a>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>

          <Select
            defaultValue={reportTime}
            value={reportTime}
            onValueChange={(val: typeof reportTime) => setReportTime(val)}
          >
            <SelectTrigger
              id="report-time-select"
              className="bg-gray-200 dark:bg-gray-700"
            >
              <SelectValue placeholder="Report Time" />
            </SelectTrigger>
            <SelectContent align="end" className="min-w-56">
              <SelectGroup>
                <SelectLabel>Report Time</SelectLabel>
                {REPORT_TIME_OPTIONS.map((option) => (
                  <SelectItem
                    id={"report-time-item-" + option.value}
                    key={option.value}
                    value={option.value}
                    data-value={option.value}
                    className="report-time-item"
                  >
                    {option.label}
                  </SelectItem>
                ))}
              </SelectGroup>
              <SelectGroup>
                <SelectSeparator />
                <SelectLabel className="py-3 -m-1 font-normal rounded-t-none bg-accent/50 flex items-center">
                  <BookOpenIcon className="h-4 w-4" />
                  <a
                    href="https://knowledge.tracify.ai/en/articles/88334-what-is-the-difference-between-click-date-and-conversion-date-attribution"
                    className="underline ml-1"
                    target="_blank"
                  >
                    Learn more about report times
                  </a>
                </SelectLabel>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </div>
      <div>
        <Button
          onClick={() => setExpandSettings((curr) => !curr)}
          variant={expandSettings ? "primary" : "secondary"}
          size="icon-md"
          className={
            expandSettings
              ? ""
              : "text-foreground bg-gray-200 dark:bg-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 hover:border-gray-100 dark:hover:border-gray-600"
          }
          id="attribution-menu-expand-button"
        >
          <AdjustmentsHorizontalIcon className="h-6 w-6" />
        </Button>
      </div>
    </div>
  );
};

export default AttributionMenu;
