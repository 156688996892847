import React, { useEffect, useMemo, useState } from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Label,
  Line,
  ResponsiveContainer,
  Tooltip as ChartTooltip,
  XAxis,
  YAxis,
} from "recharts";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import {
  chartTooltipStyles,
  GENERAL_METRICS_LABELS,
  METRICS_LABELS,
} from "constants/constants";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui";
import { MarketingChannelOverviewInterface } from "interface/MarketingChannelOverviewInterface";
import { kpiCalculations } from "@lib/util-functions";
import { getNearestAxisValue } from "@components/ChannelControlMetrics/ConversionValueChart";
import { EventSumInfo, NewVsReturningType } from "@api/types/backendTypes";
import { useAtom } from "jotai";
import {
  comparedAtom,
  dashboardModeAtom,
  endTimeAtom,
  newVsReturningAtom,
  startTimeAtom,
} from "atoms";
import useCurrency from "../../../lib/hooks/use-currency";
import useFormat from "@lib/hooks/use-format";
import dayjs from "dayjs";
import XAxisTicksWithYear from "@components/Charts/XAxisTicksWithYear";

type Props = {
  dailyChannelData: {
    new: { [key: string]: MarketingChannelOverviewInterface[] };
    returning: { [key: string]: MarketingChannelOverviewInterface[] };
    all: { [key: string]: MarketingChannelOverviewInterface[] };
    compared?: {
      new: { [key: string]: MarketingChannelOverviewInterface[] };
      returning: { [key: string]: MarketingChannelOverviewInterface[] };
      all: { [key: string]: MarketingChannelOverviewInterface[] };
    };
  };
  dailyStoreEventsData?: { [date: string]: EventSumInfo };
  dailyComparedStoreEventsData?: { [date: string]: EventSumInfo };
  loading?: boolean;
};

const getChartData = ({
  dailyChannelData,
  dailyStoreEventsData,
  newVsReturning,
  comparedTimerange,
}: {
  dailyChannelData: Props["dailyChannelData"];
  newVsReturning: NewVsReturningType;
  dailyStoreEventsData: Props["dailyStoreEventsData"];
  comparedTimerange?: number;
}) => {
  const currentChartData: { [key: string]: any }[] = [];
  let currentMaxValue = 0;
  let currentMaxMERValue = 0;
  Object.entries(
    dailyChannelData[newVsReturning === "default" ? "all" : newVsReturning]
  ).forEach(([date, value]) => {
    const valueData: { [key: string]: any } = {};
    if (comparedTimerange) {
      valueData["date"] = dayjs(date)
        .add(comparedTimerange, "day")
        .format("YYYY-MM-DD");
    } else {
      valueData["date"] = date;
    }
    const immutableValue: MarketingChannelOverviewInterface[] = [];
    const storeEventsData = dailyStoreEventsData
      ? dailyStoreEventsData[date]
      : null;

    // we need these values to calculate CAC because we don't track all orders as hive (direct traffic is missing)
    let totalOrders = 0; // we get that from storeEventsData total_count
    let totalTrcOrders = 0; // we get that from dailyChannelData.all purchaseCount
    let newTrcCustomers = 0; // we get that from dailyChannelData.new purchaseCount
    let totalAdSpend = 0; // we get that from the current selected nvr data, because it's the same across new, returning, all

    if (storeEventsData) {
      dailyChannelData.all[date]?.forEach((el) => {
        totalTrcOrders += el.purchaseCount;
      });
      dailyChannelData.new[date]?.forEach((el) => {
        newTrcCustomers += el.purchaseCount;
      });

      // set total orders from store events data
      totalOrders += storeEventsData?.total_count;

      // we adjust the channel values with the events data for easier calculations
      value?.forEach((el, index) => {
        immutableValue[index] = { ...el }; // make it immutable
        // add spend for every element to totalAdSpend
        totalAdSpend += el.spend;
        if (index === 0) {
          immutableValue[index].purchaseAmount = storeEventsData.total_amount;
          immutableValue[index].purchaseCount = storeEventsData?.total_count;
        } else {
          immutableValue[index].purchaseAmount = 0;
          immutableValue[index].purchaseCount = 0;
        }
      });
    }

    const cac =
      newTrcCustomers > 0 && totalOrders > 0 && newVsReturning !== "default"
        ? ((totalTrcOrders / totalOrders) * totalAdSpend) / newTrcCustomers
        : 0;
    const cpo = kpiCalculations.cpo(immutableValue);
    const mer = kpiCalculations.mer(immutableValue);
    valueData["mer"] = mer;
    valueData["cpo"] = cpo;
    newVsReturning !== "default" ? (valueData["cac"] = cac) : null;
    if (cpo > currentMaxValue) currentMaxValue = cpo;
    if (cac > currentMaxValue) currentMaxValue = cac;
    if (mer > currentMaxMERValue) mer < 100 ? (currentMaxMERValue = mer) : null;
    currentChartData.push(valueData);
  });
  return { currentChartData, currentMaxMERValue, currentMaxValue };
};

/**
 * Component representing the charts in store performance sector.
 *
 * @param {CacDevelopmentPropsInterface} props
 * @return {CustomElements}
 */
export function CACDevelopmentChart({
  dailyChannelData,
  loading,
  dailyStoreEventsData,
  dailyComparedStoreEventsData,
}: Props) {
  const [chartData, setChartData] = useState<{ [key: string]: any }[]>([]);
  const [comparedChartData, setComparedChartData] = useState<
    { [key: string]: any }[]
  >([]);
  const colors: { [key: string]: string } = {
    mer: "#DAA702",
    cpo: "#4a3fcc",
    cac: "#27ae60",
  };
  const [newVsReturning] = useAtom(newVsReturningAtom);
  const [dashboardMode] = useAtom(dashboardModeAtom);
  const compared = Boolean(
    Object.keys(dailyChannelData.compared?.all ?? {}).length > 0
  );
  const minDateFromComparedDaily = useMemo(
    () =>
      Math.min(
        ...Object.keys(dailyChannelData.compared?.all ?? {}).map((el) =>
          dayjs(el).unix()
        )
      ),
    [dailyChannelData.compared?.all]
  );
  const minDateFromDaily = useMemo(
    () =>
      Math.min(
        ...Object.keys(dailyChannelData?.all).map((el) => dayjs(el).unix())
      ),
    [dailyChannelData]
  );
  const timerange = useMemo(
    () =>
      dayjs(minDateFromDaily * 1000).diff(
        dayjs(minDateFromComparedDaily * 1000),
        "day"
      ),
    [minDateFromDaily, minDateFromComparedDaily]
  );
  const [maxValue, setMaxValue] = useState(0);
  const [maxMERValue, setMaxMERValue] = useState(0);

  const { calculateCurrencyConvertion, globalCurrencySymbol } = useCurrency();
  const { formatNumber } = useFormat();
  useEffect(() => {
    setChartData([]);
    setComparedChartData([]);
    setMaxValue(0);
    setMaxMERValue(0);
    if (
      Object.values(
        dailyChannelData[newVsReturning === "default" ? "all" : newVsReturning]
      ).length > 0
    ) {
      const calculatedChartData = getChartData({
        dailyChannelData,
        dailyStoreEventsData,
        newVsReturning,
      });
      const { currentChartData, currentMaxMERValue, currentMaxValue } =
        calculatedChartData;
      let comparedChartData: typeof calculatedChartData | undefined;
      if (dailyChannelData.compared && timerange) {
        comparedChartData = getChartData({
          dailyChannelData: dailyChannelData.compared,
          dailyStoreEventsData: dailyComparedStoreEventsData,
          newVsReturning,
          comparedTimerange: timerange,
        });
        for (const value of comparedChartData.currentChartData) {
          const indexInCurrent = currentChartData.findIndex(
            (el) => el.date === value.date
          );
          if (indexInCurrent !== -1) {
            currentChartData[indexInCurrent] = {
              ...currentChartData[indexInCurrent],
              compared_cac: value.cac,
              compared_cpo: value.cpo,
              compared_mer: value.mer,
            };
          }
        }
      }
      setChartData(currentChartData);
      setMaxValue(
        comparedChartData?.currentMaxValue
          ? Math.max(currentMaxValue, comparedChartData.currentMaxValue)
          : currentMaxValue
      );
      setMaxMERValue(
        comparedChartData?.currentMaxMERValue
          ? Math.max(currentMaxMERValue, comparedChartData.currentMaxMERValue)
          : currentMaxMERValue
      );
    }
  }, [
    dailyChannelData,
    dailyComparedStoreEventsData,
    dailyStoreEventsData,
    newVsReturning,
    timerange,
  ]);
  const maxMERDomain = getNearestAxisValue(maxMERValue);

  const maxYDomain = getNearestAxisValue(maxValue);

  const [xAxisTicks, setXAxisTicks] = useState(
    dailyChannelData?.all
      ? Object.keys(dailyChannelData.all)
      : Object.keys(dailyChannelData)
  );

  useEffect(() => {
    function setXAxisTicksHandler() {
      const revenueChart = document.getElementById("cac-chart-container");
      const width = revenueChart?.clientWidth ?? 0;
      const maxAmountOfTicks = Math.floor((width - 300) / (36 + 15)); // the labels are roughly 36px wide + a 15px spacing
      const dailyKeys = dailyChannelData?.all
        ? Object.keys(dailyChannelData.all)
        : Object.keys(dailyChannelData);
      const dateKeys = dailyKeys.length;
      const keysIndexToChoose = Math.ceil(dateKeys / maxAmountOfTicks);
      const keysToDisplay = dailyKeys
        .map((el, index) => (index % keysIndexToChoose === 0 ? el : null))
        .filter((el) => Boolean(el)) as string[];
      setXAxisTicks(keysToDisplay);
    }
    setXAxisTicksHandler();
    window.addEventListener("resize", setXAxisTicksHandler);
    return () => {
      window.removeEventListener("resize", setXAxisTicksHandler);
    };
  }, [dailyChannelData]);

  if (loading) {
    return (
      <div className="relative bg-gray-100 dark:bg-gray-800 p-6 rounded-lg">
        <div className="animate-pulse">
          <div className="h-8 w-64 bg-gray-200 dark:bg-gray-700 rounded-md"></div>
          <div className="mt-8">
            <div className="h-[256px] w-full bg-gray-200 dark:bg-gray-700 rounded-xl"></div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="w-full h-full min-h-72 border-black p-6 flex flex-col rounded-lg bg-gray-100 dark:bg-gray-800">
      <div className="flex justify-between items-baseline">
        <div className="flex items-center">
          <h3 className="h3 mr-3">
            {newVsReturning !== "default" ? "CAC & " : ""}{" "}
            {dashboardMode === "general" ? "CPA" : "CPO"} Development / MER
          </h3>
          <Tooltip>
            <TooltipTrigger>
              <InformationCircleIcon className="text-primary h-5 w-5" />
            </TooltipTrigger>
            <TooltipContent>
              <div className="w-88 text-foreground-soft">
                <p className="text-base text-foreground font-medium">
                  {newVsReturning !== "default" ? "CAC & " : ""}
                  {dashboardMode === "general" ? "CPA" : "CPO"} Development /
                  MER{" "}
                </p>
                <p>Is your customer acquisition overall staying profitable</p>
                <p className="text-base text-foreground font-medium mt-1">
                  Example
                </p>
                <p>
                  If your {dashboardMode === "general" ? "CPA" : "CPO"} is
                  rising that’s at first sight a bad sign. However if your MER
                  increases at the same time that’s usually fine.
                </p>
              </div>
            </TooltipContent>
          </Tooltip>
        </div>
        <div className="flex space-x-2">
          <div className="flex items-center">
            <div className="h-4 w-4 rounded-sm bg-yellowRect mr-2"></div>
            <p className="text-foreground-soft text-xs">MER</p>
          </div>
          {newVsReturning !== "default" ? (
            <div className="flex items-center">
              <div className="h-4 w-4 rounded-sm bg-greenRect mr-2"></div>
              <p className="text-foreground-soft text-xs">CAC</p>
            </div>
          ) : null}
          <div className="flex items-center">
            <div className="h-4 w-4 rounded-sm bg-blueRect mr-2"></div>
            <p className="text-foreground-soft text-xs">
              {dashboardMode === "general" ? "CPA" : "CPO"}
            </p>
          </div>
        </div>
      </div>

      <div className="w-full h-72 bg-gray-100 dark:bg-gray-800">
        <ResponsiveContainer
          width="100%"
          height="100%"
          id={"cac-chart-container"}
        >
          <ComposedChart
            data={chartData}
            margin={{ top: 30, bottom: 5, right: 0, left: 0 }}
          >
            {!compared ? (
              <defs>
                <linearGradient id="cac" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={colors.cac} stopOpacity={0.4} />
                  <stop offset="95%" stopColor={colors.cac} stopOpacity={0} />
                </linearGradient>
                <linearGradient id="cpo" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={colors.cpo} stopOpacity={0.4} />
                  <stop offset="95%" stopColor={colors.cpo} stopOpacity={0} />
                </linearGradient>
              </defs>
            ) : null}
            <XAxis
              dataKey="date"
              tickSize={0}
              tickMargin={20}
              axisLine={false}
              textAnchor="left"
              interval={0}
              tick={(props) => (
                <XAxisTicksWithYear
                  rechartsProps={props}
                  xAxisTicks={xAxisTicks}
                />
              )}
              ticks={xAxisTicks}
            />
            <CartesianGrid stroke="var(--chart-grid)" vertical={false} />
            <YAxis
              tickFormatter={(value: string) =>
                `${calculateCurrencyConvertion(
                  parseFloat(value.toString()),
                  0,
                  false
                )}`
              }
              tickSize={0}
              yAxisId="1"
              tickMargin={10}
              domain={[0, maxYDomain]}
              axisLine={false}
              ticks={[0, 1, 2, 3, 4].map((el) => (maxYDomain / 4) * el)}
              width={80}
            >
              <Label
                value={`${
                  dashboardMode === "general" ? "CPA" : "CPO"
                } / CAC in ${globalCurrencySymbol}`}
                offset={-10}
                angle={-90}
                textAnchor="middle"
                position={{
                  x: 20,
                  y: 60,
                }}
              />
            </YAxis>
            <YAxis
              orientation="right"
              yAxisId="2"
              label={{
                value: "MER",
                angle: -90,
                position: "insideRight",
                offset: 10,
              }}
              tickMargin={10}
              domain={[0, maxMERDomain]}
              tickCount={
                maxMERDomain % 4 === 0 ? 5 : maxMERDomain % 2 === 0 ? 3 : 2
              }
              axisLine={false}
            />
            <ChartTooltip
              contentStyle={chartTooltipStyles}
              formatter={(value: string, name: string) => [
                name !== "mer" && name !== "compared_mer"
                  ? `${calculateCurrencyConvertion(parseFloat(value), 2)}`
                  : formatNumber(parseFloat(value), 2),
                dashboardMode === "general"
                  ? GENERAL_METRICS_LABELS[name]
                  : METRICS_LABELS[name],
              ]}
              itemSorter={(item) => {
                if (item.dataKey === "recovery_rate") return 0; // Yellow
                if (item.dataKey === "deep_sleep_uu") return 1; // Gray
                if (item.dataKey === "latest_recovery_uu") return 2; // Cyan
                if (item.dataKey === "recovery_uu") return 3; // Pastel Cyan
                return 4; // Default
              }}
            />

            <Area
              type="monotone"
              dataKey="cac"
              yAxisId="1"
              stroke={colors.cac}
              dot={false}
              strokeWidth={2}
              fillOpacity={1}
              fill="url(#cac)"
            />
            {compared ? (
              <Line
                type="monotone"
                yAxisId="1"
                dataKey="compared_cac"
                stroke={colors.cac}
                strokeDasharray="5 5"
                opacity={0.75}
                dot={false}
                strokeWidth={2}
              />
            ) : null}
            <Area
              type="monotone"
              dataKey="cpo"
              stroke={colors.cpo}
              yAxisId="1"
              dot={false}
              strokeWidth={2}
              fillOpacity={1}
              fill="url(#cpo)"
            />
            {compared ? (
              <Line
                type="monotone"
                yAxisId="1"
                dataKey="compared_cpo"
                stroke={colors.cpo}
                strokeDasharray="5 5"
                opacity={0.75}
                dot={false}
                strokeWidth={2}
              />
            ) : null}
            <Line
              type="monotone"
              yAxisId="2"
              dataKey="mer"
              stroke={colors.mer}
              dot={false}
              strokeWidth={2}
            />
            {compared ? (
              <Line
                type="monotone"
                yAxisId="2"
                dataKey="compared_mer"
                stroke={colors.mer}
                strokeDasharray="5 5"
                opacity={0.75}
                dot={false}
                strokeWidth={2}
              />
            ) : null}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
