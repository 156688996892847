const WebhooksIcon = ({
  color,
  className,
}: {
  color: string;
  className?: string;
}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.9998 14.15H10.0082C9.0915 14.15 8.38317 14.9333 7.9415 15.7333C7.59188 16.3894 7.033 16.9097 6.35369 17.2116C5.67438 17.5136 4.91364 17.5798 4.1924 17.3997C3.47115 17.2196 2.8308 16.8036 2.37313 16.2178C1.91546 15.632 1.66674 14.9101 1.6665 14.1667C1.67484 13.5833 1.83317 13 2.1415 12.5"
        stroke="currentColor"
        strokeWidth="1.45833"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 14.1667L7.60833 9.35C8.05 8.54166 7.69167 7.53333 7.19167 6.76666C6.95568 6.38925 6.79767 5.96839 6.72697 5.52892C6.65626 5.08945 6.67429 4.64027 6.77999 4.20789C6.88569 3.7755 7.07692 3.36866 7.3424 3.01137C7.60788 2.65409 7.94223 2.35359 8.32573 2.12761C8.70922 1.90164 9.13409 1.75476 9.57526 1.69564C10.0164 1.63652 10.465 1.66636 10.8944 1.7834C11.3239 1.90044 11.7256 2.10231 12.0758 2.3771C12.4259 2.65189 12.7175 2.99402 12.9333 3.38333"
        stroke="currentColor"
        strokeWidth="1.45833"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 5L12.6083 9.775C13.05 10.5833 14.0833 10.8333 15 10.8333C15.8841 10.8333 16.7319 11.1845 17.357 11.8096C17.9821 12.4348 18.3333 13.2826 18.3333 14.1667C18.3333 15.0507 17.9821 15.8986 17.357 16.5237C16.7319 17.1488 15.8841 17.5 15 17.5"
        stroke="currentColor"
        strokeWidth="1.45833"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WebhooksIcon;
